import { SimpleGridProps } from '@chakra-ui/react';

import { ColorGridItemComponent } from '../../components/ColorGridItem';
import { SimpleColorGridComponent } from '../../components/SimpleColorGrid';

export const Tertiaries = ({ ...props }: SimpleGridProps) => {
  return (
    <SimpleColorGridComponent {...props}>
      <ColorGridItemComponent
        color={'context.amber'}
        headline={'Amber'}
        hexCode={'#EC844A'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.pink'}
        headline={'Pink'}
        hexCode={'#D34F8E'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.red'}
        headline={'Red'}
        hexCode={'#E84057'}
        hasBorder={false}
      />
    </SimpleColorGridComponent>
  );
};
