import { Box } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { TextComponent } from 'components/Text';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

import { DownloadFileComponent } from '../../components/DownloadFile';

export const StaffPhotographyPortraits = () => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.STAFF_PHOTOGRAPHY_PORTRAITS.anchor}
      title={SECTIONS.STAFF_PHOTOGRAPHY_PORTRAITS.title}
    >
      <img
        src={process.env.PUBLIC_URL + '/images/StaffPhotographyPortraits01.png'}
        alt="Two photos: A portrait of a woman and a man in ideal composition"
      />

      <Box
        width="50%"
        mt="-11%"
        mb={{ base: space.medium, lg: space.large, '2xl': space.xxLarge }}
        pl={4}
      >
        <TextComponent mb={space.xSmall}>
          Mandatory use of a black background.
        </TextComponent>
      </Box>

      <TextComponent variant="large" mb={space.medium}>
        We have so many exciting stories to tell. We want to take people with us
        on our daily journey and share the DWS experience. That&apos;s why we
        want to communicate that story visually in a more tangible, personal and
        authentic way – showing real people and real life.
      </TextComponent>

      <Box
        mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.small}
      >
        <img
          src={
            process.env.PUBLIC_URL + '/images/StaffPhotographyPortraits02.png'
          }
          alt="Different examples of people photography"
        />
      </Box>

      <DownloadFileComponent
        file={
          'https://download.dws.com/download?elib-assetguid=f07e2be5f2ba4622a5b5e4f9f2e3dd21'
        }
        fileType="Zip"
        fileSize="1.5 MB"
      >
        Staff photography / portraits quickguide
      </DownloadFileComponent>
    </ContentSectionComponent>
  );
};
