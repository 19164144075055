import React from 'react';

import { Box, SimpleGrid } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { SECTIONS } from 'constants/sections';

import { HeadingComponent } from '../../components/Heading';
import { PictureCard } from '../../components/PictureCard';
import {
  TabComponent,
  TabListComponent,
  TabPanelComponent,
  TabPanelsComponent,
  TabsComponent,
} from '../../components/Tabs';
import { TextComponent } from '../../components/Text';
import { FIDUCARY_PETROL } from '../../theme';
import { space, zIndex } from '../../theme/constants';

export const ToneOfVoice = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.TONE_OF_VOICE.anchor}
      title={SECTIONS.TONE_OF_VOICE.title}
      {...props}
    >
      <TextComponent variant={'medium'} mb={space.small}>
        Our Employer Brand has energy and authenticity at its core. It always
        focuses on our audiences, speaking directly to them in an impactful and
        emotive tone of voice. Draw on language that is active, inclusive and
        dynamic – particularly when writing headlines.
      </TextComponent>

      <SimpleGrid columns={[1, 2]} gap={4} mb={space.xxLarge}>
        <PictureCard
          picturePath={process.env.PUBLIC_URL + '/images/tov_human.jpg'}
          title={'Human'}
          altText={'Two Woman having a conversation'}
        >
          Our tone of voice sets out to resonate with our audience. This means
          speaking in a human, accessible and sincere tone of voice. Avoid being
          too corporate and do not use regional language. Our Employer Brand
          speaks to people from all over the world, so use universal language
          where possible.
        </PictureCard>

        <PictureCard
          picturePath={process.env.PUBLIC_URL + '/images/tov_ambitious.jpg'}
          title={'Ambitious'}
          altText={'Two man facing away form the camara in front of a garden'}
        >
          We are a leading global Asset Manager with a strong presence in
          Europe, with some of the industry&apos;s best-known thought leaders.
          We&apos;re innovative, ever-changing and make a huge impact on the
          world. Our tone of voice should reflect who we are by using positive,
          aspirational and compelling language.
        </PictureCard>

        <PictureCard
          picturePath={process.env.PUBLIC_URL + '/images/tov_direct.jpg'}
          title={'Direct'}
          altText={'An audience listening to a presentation'}
        >
          We always speak directly to our audience; our tone of voice is
          confident but not arrogant. Use short, snappy sentences where possible
          to keep your audience engaged. Use ‘we’ instead of writing in the
          third person, and ‘you’ when referring to the employee or candidate.
        </PictureCard>

        <PictureCard
          picturePath={process.env.PUBLIC_URL + '/images/tov_active.jpg'}
          title={'Active'}
          altText={'Somebody typing on a laptop'}
        >
          Inspired by <b>Investors for a new now</b>, our tone of voice is
          dynamic and action-led. Write in an active voice, using progressive
          verb tenses of gerunds (otherwise known as ‘ing words’). Write body
          copy in the present tense with anticipation of the future.
        </PictureCard>
      </SimpleGrid>

      <TabsComponent mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}>
        <TabListComponent>
          <TabComponent
            _selected={{
              borderBottomColor: 'context.clientCommitment',
              color: 'context.clientCommitment',
            }}
            _focus={{
              boxShadow: 'none',
              color: 'context.clientCommitment',
              bgColor: 'background.nearWhite',
            }}
            _hover={{ color: 'context.clientCommitment' }}
          >
            Headlines
          </TabComponent>

          <TabComponent
            _selected={{
              borderBottomColor: 'context.clientCommitment',
              color: 'context.clientCommitment',
            }}
            _focus={{
              boxShadow: 'none',
              color: 'context.clientCommitment',
              bgColor: 'background.nearWhite',
            }}
            _hover={{ color: 'context.clientCommitment' }}
          >
            Body Copy
          </TabComponent>

          <TabComponent
            _selected={{
              borderBottomColor: 'context.clientCommitment',
              color: 'context.clientCommitment',
            }}
            _focus={{
              boxShadow: 'none',
              color: 'context.clientCommitment',
              bgColor: 'background.nearWhite',
            }}
            _hover={{ color: 'context.clientCommitment' }}
          >
            Call to action
          </TabComponent>
        </TabListComponent>

        <TabPanelsComponent>
          <TabPanelComponent>
            <Box
              position="relative"
              _before={{
                content: "''",
                position: 'absolute',
                zIndex: zIndex.default,
                top: '0',
                bottom: '-40px',
                width: '12px',
                bgColor: 'context.clientCommitment',
              }}
            >
              <Box py={space.medium} px={space.large}>
                <HeadingComponent variant={'primary'}>
                  Headlines
                </HeadingComponent>
              </Box>
              <Box mb={space.medium} px={space.large}>
                <TextComponent variant={'medium'}>
                  The headline is the first thing your audience will read, and
                  visually, it is the most prominent. Very often, our headlines
                  begin with an active word – but there are two ways you can
                  write them.
                </TextComponent>
              </Box>

              <SimpleGrid
                columns={[1, 2]}
                px={space.large}
                spacingX={space.large}
                spacingY={space.small}
              >
                <TextComponent
                  color={FIDUCARY_PETROL}
                  variant={'medium'}
                  textAlign={'right'}
                >
                  Option 1:
                  <br />A single statment
                </TextComponent>

                <TextComponent variant={'large'}>
                  Thrive at the forefront of change. Shape our transformation.
                </TextComponent>

                <TextComponent
                  color={FIDUCARY_PETROL}
                  variant={'medium'}
                  textAlign={'right'}
                >
                  Option 2:
                  <br />A two-part headline with themes of repetition
                </TextComponent>

                <TextComponent variant={'large'}>
                  Uncover new ground, discover new skills. Think bigger, think
                  global.
                </TextComponent>
              </SimpleGrid>
            </Box>
          </TabPanelComponent>

          <TabPanelComponent>
            <Box
              position="relative"
              _before={{
                content: "''",
                position: 'absolute',
                zIndex: zIndex.default,
                top: '0',
                bottom: '-40px',
                width: '12px',
                bgColor: 'context.clientCommitment',
              }}
            >
              <Box py={space.medium} px={space.large}>
                <HeadingComponent variant={'primary'}>
                  Body Copy
                </HeadingComponent>
              </Box>

              <Box mb={space.medium} px={space.large}>
                <TextComponent variant={'medium'}>
                  Our tone of voice adopts two approaches when communicating
                  messaging through body copy. Whether it&apos;s for a social
                  media post, brochure, or website – use your judgement to which
                  approach is more effective. Writing body copy is your chance
                  to give the reader more information, so always make sure you
                  are expanding on the themes shown in your headline.
                </TextComponent>
              </Box>

              <SimpleGrid
                columns={[1, 2]}
                px={space.large}
                spacingX={space.large}
                spacingY={space.small}
              >
                <Box>
                  <TextComponent
                    color={FIDUCARY_PETROL}
                    variant={'medium'}
                    textAlign={'right'}
                  >
                    Approach 1:
                    <br />
                    The two-phase approach
                  </TextComponent>
                </Box>

                <Box>
                  <TextComponent
                    color={FIDUCARY_PETROL}
                    variant={'large'}
                    fontFamily={'DWS Sans'}
                  >
                    Phase 1
                  </TextComponent>

                  <TextComponent variant={'large'} mb={space.medium}>
                    Begin the narrative with a proposition focused on DWS using
                    the pronoun &apos;we&apos;.
                  </TextComponent>

                  <TextComponent
                    color={FIDUCARY_PETROL}
                    variant={'large'}
                    fontFamily={'DWS Sans'}
                  >
                    Phase 2
                  </TextComponent>

                  <TextComponent variant={'large'} mb={space.medium}>
                    Speak directly to the audience using the pronoun
                    &apos;you&apos;, create a connection between them and the
                    DWS
                  </TextComponent>
                  <TextComponent
                    color={FIDUCARY_PETROL}
                    variant={'large'}
                    fontFamily={'DWS Sans'}
                  >
                    Example
                  </TextComponent>

                  <TextComponent variant={'large'} mb={space.medium}>
                    At DWS, we&apos;re evolving. And with change comes the
                    opportunity to do responsible, challenging, and innovative
                    work. Join us, and you can be part of the team capturing the
                    opportunities of tomorrow.
                  </TextComponent>

                  <TextComponent variant={'large'} mb={space.medium}>
                    This is your chance to lead an extraordinary career.
                  </TextComponent>
                </Box>

                <Box>
                  <TextComponent
                    color={FIDUCARY_PETROL}
                    variant={'medium'}
                    textAlign={'right'}
                  >
                    Approach 2:
                    <br />
                    The direct approach
                  </TextComponent>
                </Box>

                <Box>
                  <TextComponent variant={'medium'} mb={space.medium}>
                    Begin body copy by speaking directly to the audience using
                    the pronoun &apos;you&apos;within the first sentence. Finish
                    the copy with a collaborative statement that creates a sense
                    of partnership between the audience and DWS.
                  </TextComponent>

                  <TextComponent
                    color={FIDUCARY_PETROL}
                    variant={'large'}
                    fontFamily={'DWS Sans'}
                  >
                    Example
                  </TextComponent>

                  <TextComponent variant={'large'} mb={space.medium}>
                    Whether you want to specialise or experience different
                    roles, at DWS you can achieve your goals. Join us on our
                    transformation, and you can make an impact on the future.
                  </TextComponent>
                </Box>
              </SimpleGrid>
            </Box>
          </TabPanelComponent>

          <TabPanelComponent>
            <Box
              position="relative"
              _before={{
                content: "''",
                position: 'absolute',
                zIndex: zIndex.default,
                top: '0',
                bottom: '-40px',
                width: '12px',
                bgColor: 'context.clientCommitment',
              }}
            >
              <Box py={space.medium} px={space.large}>
                <HeadingComponent variant={'primary'}>
                  Call to action
                </HeadingComponent>
              </Box>
              <Box mb={space.medium} px={space.large}>
                <TextComponent variant={'medium'} mb={space.medium}>
                  All communications should end with a call to action; this is
                  an instruction for the audience, telling them what to do next.
                  <br />
                  <br />
                  <b>Invest in your future</b> is our Employer Brand call to
                  action. This can be used on assets and to sign off body copy.
                  When writing body copy, you can also use a second call to
                  action to make it clear to your audience what comes next.
                </TextComponent>

                <TextComponent
                  color={FIDUCARY_PETROL}
                  variant={'large'}
                  fontFamily={'DWS Sans'}
                >
                  For example
                </TextComponent>

                <TextComponent variant={'large'} mb={space.medium}>
                  Invest in your future. Find out more.
                  <br /> Invest in your future. Click to apply.
                </TextComponent>

                <TextComponent variant={'medium'} mb={space.medium}>
                  In the rest of this Employer Brand section, you will be guided
                  through best-practice communications with our two main
                  audiences: professional hires and early careers.
                </TextComponent>
              </Box>
            </Box>
          </TabPanelComponent>
        </TabPanelsComponent>
      </TabsComponent>
    </ContentSectionComponent>
  );
};
