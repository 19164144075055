import { Box } from '@chakra-ui/react';

import { AnchorComponent } from 'components/Anchor';
import { ButtonComponent } from 'components/Button';
import { HeadingComponent } from 'components/Heading';
import { space, zIndex } from 'theme/constants';

type Props = {
  anchor: string;
  children: React.ReactNode;
  isIndented?: boolean;
  isInverted?: boolean;
  layeredHeadline?: boolean;
  moreDetailsUrl?: string;
  title: string;
};

export const ContentSectionComponent = ({
  anchor,
  children,
  isIndented,
  isInverted,
  layeredHeadline,
  moreDetailsUrl,
  title,
  ...props
}: Props) => {
  return (
    <>
      <Box
        as="section"
        id={anchor}
        position="relative"
        paddingTop={'40px'}
        marginTop={'-40px'}
        {...(isInverted && {
          bgColor: 'background.black',
        })}
        {...(isIndented && {
          mx: { lg: `-${space.medium}`, xl: `-${space.xLarge}` },
        })}
        {...props}
      >
        <HeadingComponent
          as="h2"
          mb={space.xSmall}
          sx={{
            ':hover a': {
              visibility: 'visible',
            },
          }}
          {...(isInverted && {
            color: 'text.primaryLight',
          })}
          {...(isIndented && {
            mx: { base: space.xSmall, lg: space.medium, xl: space.xLarge },
          })}
          {...(layeredHeadline && {
            position: 'absolute',
            zIndex: zIndex.default,
            top: '15%',
            left: '0',
            width: '100%',
            px: { base: space.xSmall, lg: '0' },
          })}
        >
          <AnchorComponent
            aria-hidden="true"
            float="left"
            href={`#${anchor}`}
            id={anchor}
            ml="-24px"
            pr="8px"
            visibility="hidden"
          />
          {title}
        </HeadingComponent>

        {children}

        {moreDetailsUrl && (
          <Box as="footer" pt={8}>
            <ButtonComponent as="a" href={moreDetailsUrl}>
              More details
            </ButtonComponent>
          </Box>
        )}
      </Box>
    </>
  );
};
