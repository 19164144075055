import React from 'react';

import { Global } from '@emotion/react';

export const Fonts = () => {
  return (
    <Global
      styles={`
        @font-face {
          font-family: 'DWS Slab';
          src: url('/fonts/DWSSlabWeb-Light.woff2') format('woff2');
          font-style: normal;
          font-weight: 300;
          font-display: swap;
        }

        @font-face {
          font-family: 'DWS Slab';
          src: url('/fonts/DWSSlabWeb-Regular.woff2') format('woff2');
          font-style: normal;
          font-weight: 400;
          font-display: swap;
        }

        @font-face {
          font-family: 'DWS Slab';
          src: url('/fonts/DWSSlabWeb-Bold.woff2') format('woff2');
          font-style: normal;
          font-weight: 700;
          font-display: swap;
        }

        @font-face {
          font-family: 'DWS Sans';
          src: url('/fonts/DWSSansWeb-Light.woff2') format('woff2');
          font-style: normal;
          font-weight: 300;
          font-display: swap;
        }

        @font-face {
          font-family: 'DWS Sans';
          src: url('/fonts/DWSSansWeb-Regular.woff2') format('woff2');
          font-style: normal;
          font-weight: 400;
          font-display: swap;
        }

        @font-face {
          font-family: 'DWS Sans';
          src: url('/fonts/DWSSansWeb-Bold.woff2') format('woff2');
          font-style: normal;
          font-weight: 700;
          font-display: swap;
        }
      `}
    />
  );
};
