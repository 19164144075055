import { Box } from '@chakra-ui/react';

import { CarouselComponent } from 'components/Carousel';
import { ContentSectionComponent } from 'components/ContentSection';
import { DownloadFileComponent } from 'components/DownloadFile';
import { HeadingComponent } from 'components/Heading';
import { TextComponent } from 'components/Text';
import { PAGES } from 'constants/pages';
import { SECTIONS } from 'constants/sections';
import { space, zIndex } from 'theme/constants';

export const LogoSimplification = () => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.LOGO_SIMPLIFICATION.anchor}
      moreDetailsUrl={`${PAGES.BRAND_ESSENTIALS_AND_GUIDES.path}#${SECTIONS.THE_LOGO.anchor}`}
      title={SECTIONS.LOGO_SIMPLIFICATION.title}
    >
      <Box
        position="relative"
        mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.xLarge}
      >
        <Box
          position="relative"
          zIndex={zIndex.above}
          padding="11.4% 5.495% 0 26.2%"
          fontSize="0"
        >
          <HeadingComponent as="h3" variant="hidden">
            Remaining Logo versions
          </HeadingComponent>

          <CarouselComponent>
            <Box>
              <img
                src={
                  process.env.PUBLIC_URL + '/images/LogoSimplification01.svg'
                }
                alt="DWS Logo – gray on white background"
              />
            </Box>
            <Box>
              <img
                src={
                  process.env.PUBLIC_URL + '/images/LogoSimplification02.svg'
                }
                alt="DWS Logo – white on black background"
              />
            </Box>
            <Box>
              <img
                src={
                  process.env.PUBLIC_URL + '/images/LogoSimplification03.svg'
                }
                alt="DWS Logo – black on gray background"
              />
            </Box>
          </CarouselComponent>
        </Box>

        <Box
          position="absolute"
          zIndex={zIndex.default}
          top="0"
          right="0"
          bottom="0"
          left="0"
        >
          <img
            src={
              process.env.PUBLIC_URL +
              '/images/LogoSimplificationBackground.png'
            }
            alt=""
          />
        </Box>
      </Box>

      <TextComponent mb={space.small}>
        Everything is simplified. We discarded the two versions of black-white
        background gradient logos to reduce the quantity of options, simplify
        applications, become more contemporary and comply with cross-channel
        requirements.
      </TextComponent>

      <DownloadFileComponent
        file="https://download.dws.com/download?elib-assetguid=33d3266348bf46c0beb78ef95d7f2004"
        fileType="Zip"
        fileSize="1.5 MB"
      >
        DWS logo files
      </DownloadFileComponent>
    </ContentSectionComponent>
  );
};
