import { SimpleGridProps } from '@chakra-ui/react';

import { ColorGridItemComponent } from '../../components/ColorGridItem';
import { SimpleColorGridComponent } from '../../components/SimpleColorGrid';

export const BlackWhiteTones = ({ ...props }: SimpleGridProps) => {
  return (
    <SimpleColorGridComponent {...props}>
      <ColorGridItemComponent
        color={'context.black'}
        headline={'Black'}
        hexCode={'#000000'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.darkGray'}
        headline={'Dark Gray'}
        hexCode={'#2B2D2C'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.gray'}
        headline={'Gray'}
        hexCode={'#939494'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.mediumGray'}
        headline={'Medium Gray'}
        hexCode={'#C7C7C5'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.lightGray'}
        headline={'Light Gray'}
        hexCode={'#F0F0F0'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.white'}
        headline={'White'}
        hexCode={'#FFFFFF'}
        hasBorder={true}
      />
    </SimpleColorGridComponent>
  );
};
