import { extendTheme, ThemeConfig } from '@chakra-ui/react';

import {
  elementId as elementIds,
  size as sizes,
  space as spaces,
  transition as transitions,
  zIndex as zIndices,
} from './constants';
import globalStyles from './globalStyles';

// Coroporate Design Color Palette
// Don’t use these colors directly inside your components,
// use the referenced theme colors below instead!

// Primary Colors
// --------------

// Context Related
export const FIDUCARY_PETROL = '#56aeb1';
export const SUSTAINED_ACTION = '#abd654';
export const PROGRESSIVE_PURPLE = '#755bba';

// Black & White Tones – Backgrounds, Contrasts, Typography
export const BLACK = '#000';
export const DARK_GRAY = '#2b2d2c';
export const GRAY = '#939494';
export const MEDIUM_GRAY = '#c7c7c5';
export const LIGHT_GRAY = '#f0f0f0';
export const WHITE = '#fff';

// Secondary Colors
// ----------------

// Secondaries
export const GRAY_BLUE = '#5a6d78';
export const EARTH_GREIGE = '#aaa299';
export const GRASS_GREEN = '#60b465';
export const SKY_BLUE = '#5db4dc';
export const OCEAN_BLUE = '#2b5192';

// Tertiaries
export const AMBER = '#ec844a';
export const PINK = '#d34f8e';
export const RED = '#e84057';

//Tint & Shades
// ------------

//Tint & Shades
export const DARK_PETROL = '#49878a';
export const LIGHT_PETROL = '#7ac0c0';
export const DARK_GREEN = '#96bd59';
export const LIGHT_GREEN = '#b5d671';
export const DARK_PURPLE = '#574194';
export const LIGHT_PURPLE = '#9070e5';

export const DARK_GRAY_BLUE = '#44535a';
export const LIGHT_GRAY_BLUE = '#708894';
export const DARK_EARTH_GREIGE = '#92887c';
export const LIGHT_EARTH_GREIGE = '#c0bbb5';
export const DARK_GRASS_GREEN = '#59995d';
export const LIGHT_GRASS_GREEN = '#82c486';
export const DARK_SKY_BLUE = '#3da4d4';
export const LIGHT_SKY_BLUE = '#86c7e5';
export const DARK_OCEAN_BLUE = '#253f6b';
export const LIGHT_OCEAN_BLUE = '#436fba';

// Alert & Signals
export const POSITIVE = '#83c460';
export const NEGATIVE = '#e84057';
export const WARNING = '#e8c64d';

// Extensions
export const DARK_PETROL_02 = '#346e70';
export const DARK_GREIGE_02 = '#786e64';
export const AUTUMN_GREEN = '#7da442';
export const DARK_GRASS_GREEN_02 = '#38763b';

// Color Mode Config
const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

// Theme
const theme = extendTheme({
  config,

  colors: {
    background: {
      black: BLACK,
      highlight: FIDUCARY_PETROL,
      highlightHover: DARK_PETROL,
      nearBlack: DARK_GRAY,
      nearWhite: LIGHT_GRAY,
      neutral: MEDIUM_GRAY,
      translucent: 'rgba(0, 0, 0, 0.5)',
      white: WHITE,
    },
    border: {
      black: BLACK,
      highlight: FIDUCARY_PETROL,
      highlightHover: DARK_PETROL,
      nearWhite: LIGHT_GRAY,
      white: WHITE,
    },
    context: {
      clientCommitment: FIDUCARY_PETROL,
      sustainableAction: SUSTAINED_ACTION,
      entrepreneurialSpirit: PROGRESSIVE_PURPLE,

      black: BLACK,
      darkGray: DARK_GRAY,
      gray: GRAY,
      mediumGray: MEDIUM_GRAY,
      lightGray: LIGHT_GRAY,
      white: WHITE,

      grayBlue: GRAY_BLUE,
      earthGreige: EARTH_GREIGE,
      grassGreen: GRASS_GREEN,
      skyBlue: SKY_BLUE,
      oceanBlue: OCEAN_BLUE,

      amber: AMBER,
      pink: PINK,
      red: RED,

      darkPetrol: DARK_PETROL,
      lightPetrol: LIGHT_PETROL,
      darkGreen: DARK_GREEN,
      lightGreen: LIGHT_GREEN,
      darkPurple: DARK_PURPLE,
      lightPurple: LIGHT_PURPLE,

      darkGrayBlue: DARK_GRAY_BLUE,
      lightGrayBlue: LIGHT_GRAY_BLUE,
      darkEarthGreige: DARK_EARTH_GREIGE,
      lightEarthGreige: LIGHT_EARTH_GREIGE,
      darkGrassGreen: DARK_GRASS_GREEN,
      lightGrassGreen: LIGHT_GRASS_GREEN,
      darkSkyBlue: DARK_SKY_BLUE,
      lightSkyBlue: LIGHT_SKY_BLUE,
      darkOceanBlue: DARK_OCEAN_BLUE,
      lightOceanBlue: LIGHT_OCEAN_BLUE,

      positive: POSITIVE,
      negative: NEGATIVE,
      warning: WARNING,

      darkPetrol02: DARK_PETROL_02,
      darkGreige02: DARK_GREIGE_02,
      autumnGreen: AUTUMN_GREEN,
      darkGrassGreen02: DARK_GRASS_GREEN_02,
    },
    text: {
      highlight: FIDUCARY_PETROL,
      primaryDark: BLACK,
      primaryLight: WHITE,
      secondaryDark: GRAY,
      secondaryLight: MEDIUM_GRAY,
    },
  },

  styles: {
    global: {
      ...globalStyles,
    },
  },

  elementIds,
  sizes,
  spaces,
  transitions,
  zIndices,
});

export default theme;
