import { Box, VStack } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { DownloadFileComponent } from 'components/DownloadFile';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

export const SocialMedia = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.SOCIAL_MEDIA.anchor}
      title={SECTIONS.SOCIAL_MEDIA.title}
      {...props}
    >
      <Box
        mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.small}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/SocialMedia.jpg'}
          alt="A smartphone showing the facebook app"
        />
      </Box>

      <VStack align="stretch" spacing={2}>
        <DownloadFileComponent
          file="https://download.dws.com/download?elib-assetguid=0b1945d998cd49e7a923caf938842964"
          fileType="Zip"
          fileSize="10.5 MB"
        >
          Social media templates (PPT)
        </DownloadFileComponent>

        <DownloadFileComponent
          file={
            'https://download.dws.com/download?elib-assetguid=be422a9801364f8e916d21b0ebcd728f'
          }
          fileType="Zip"
          fileSize="260.3 MB"
        >
          Social media quickguide
        </DownloadFileComponent>
      </VStack>
    </ContentSectionComponent>
  );
};
