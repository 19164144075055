export const clientCommitment = [
  {
    original:
      'images/ImageGallery/ClientCommitment/01-photo-1464746133101-a2c3f88e0dd9_2680x1510.jpg',
    originalAlt:
      'Man from behind standing on top of a mountain looking into a sunset',
    thumbnail:
      'images/ImageGallery/ClientCommitment/01-photo-1464746133101-a2c3f88e0dd9_400x400.jpg',
    thumbnailAlt:
      'Man from behind standing on top of a mountain looking into a sunset',
  },
  {
    original:
      'images/ImageGallery/ClientCommitment/02-kelly-sikkema-G-VkNckeUSY-unsplash_1_2680x1510px.jpg',
    originalAlt:
      'A man shown from behind carrying a child in his arms walking on the pedestrian',
    thumbnail:
      'images/ImageGallery/ClientCommitment/02-kelly-sikkema-G-VkNckeUSY-unsplash_1_400x400px.jpg',
    thumbnailAlt:
      'A man shown from behind carrying a child in his arms walking on the pedestrian',
  },
  {
    original:
      'images/ImageGallery/ClientCommitment/03-sabine-van-straaten-HF4Hy8jFhEY-unsplash_2680x1510px.jpg',
    originalAlt:
      'A man shown from behind walking with a child who reaches out to his right hand (black & white image)',
    thumbnail:
      'images/ImageGallery/ClientCommitment/03-sabine-van-straaten-HF4Hy8jFhEY-unsplash_400x400px.jpg',
    thumbnailAlt:
      'A man shown from behind walking with a child who reaches out to his right hand (black & white image)',
  },
  {
    original:
      'images/ImageGallery/ClientCommitment/04-GettyImages-1163775797_2680x1510px.jpg',
    originalAlt:
      'A woman shown from behind sitting in front of water and a big city skyline',
    thumbnail:
      'images/ImageGallery/ClientCommitment/04-GettyImages-1163775797_400x400px.jpg',
    thumbnailAlt:
      'A woman shown from behind sitting in front of water and a big city skyline',
  },
  {
    original:
      'images/ImageGallery/ClientCommitment/05-GettyImages-1197440932_2680x1510px.jpg',
    originalAlt:
      'A deserted way going straight to the horizon (black & white image)',
    thumbnail:
      'images/ImageGallery/ClientCommitment/05-GettyImages-1197440932_400x400px.jpg',
    thumbnailAlt:
      'A deserted way going straight to the horizon (black & white image)',
  },
  {
    original: 'images/ImageGallery/ClientCommitment/06-FGHFGH_2680x1510px.jpg',
    originalAlt:
      'Two young men on a rooftop, one laying, one sitting next to the other',
    thumbnail: 'images/ImageGallery/ClientCommitment/06-FGHFGH_400x400px.jpg',
    thumbnailAlt:
      'Two young men on a rooftop, one laying, one sitting next to the other',
  },
  {
    original:
      'images/ImageGallery/ClientCommitment/08-alexander-lemann-qjdx0DytoYs-unsplash_2680x1510px.jpg',
    originalAlt:
      'A man shown in profile standing on a wall looking upwards (black & white image)',
    thumbnail:
      'images/ImageGallery/ClientCommitment/08-alexander-lemann-qjdx0DytoYs-unsplash_400x400px.jpg',
    thumbnailAlt:
      'A man shown in profile standing on a wall looking upwards (black & white image)',
  },
  {
    original:
      'images/ImageGallery/ClientCommitment/09-GettyImages-1267708576_2680x1510px.jpg',
    originalAlt: 'A woman shown from behind waiving a rainbow flag',
    thumbnail:
      'images/ImageGallery/ClientCommitment/09-GettyImages-1267708576_400x400px.jpg',
    thumbnailAlt: 'A woman shown from behind waiving a rainbow flag',
  },
  {
    original:
      'images/ImageGallery/ClientCommitment/10-sven-brandsma-tWQzKK9vros-unsplash_2680x1510px.jpg',
    originalAlt:
      'A young boy shown in backlight in front of a formwork (black & white image)',
    thumbnail:
      'images/ImageGallery/ClientCommitment/10-sven-brandsma-tWQzKK9vros-unsplash_400x400px.jpg',
    thumbnailAlt:
      'A young boy shown in backlight in front of a formwork (black & white image)',
  },
  {
    original:
      'images/ImageGallery/ClientCommitment/11-mikail-duran-YVdvUIMfXWE-unsplash_2680x1510px.jpg',
    originalAlt:
      'A smiling woman sitting inside a cafe holding a cup of coffee and looking out of the window',
    thumbnail:
      'images/ImageGallery/ClientCommitment/11-mikail-duran-YVdvUIMfXWE-unsplash_400x400px.jpg',
    thumbnailAlt:
      'A smiling woman sitting inside a cafe holding a cup of coffee and looking out of the window',
  },
  {
    original:
      'images/ImageGallery/ClientCommitment/12-cory-woodward-1Pra1fHfFfw-unsplash_2680x1510px.jpg',
    originalAlt:
      'A woman shown from behind walking on a rainbow colored street',
    thumbnail:
      'images/ImageGallery/ClientCommitment/12-cory-woodward-1Pra1fHfFfw-unsplash_400x400px.jpg',
    thumbnailAlt:
      'A woman shown from behind walking on a rainbow colored street',
  },
  {
    original:
      'images/ImageGallery/ClientCommitment/13-jeffery-erhunse-BQMZ5ligqps-unsplash_2680x1510px.jpg',
    originalAlt:
      'A black woman with closed eyes shown in profile in front of a blurred skyline in the afternoon hours',
    thumbnail:
      'images/ImageGallery/ClientCommitment/13-jeffery-erhunse-BQMZ5ligqps-unsplash_400x400px.jpg',
    thumbnailAlt:
      'A black woman with closed eyes shown in profile in front of a blurred skyline in the afternoon hours',
  },
  {
    original:
      'images/ImageGallery/ClientCommitment/14-daiga-ellaby-7edWO30e32k-unsplash_2680x1510px.jpg',
    originalAlt:
      'Two yellow adult and two black child shoes standing on a brown ground',
    thumbnail:
      'images/ImageGallery/ClientCommitment/14-daiga-ellaby-7edWO30e32k-unsplash_400x400px.jpg',
    thumbnailAlt:
      'Two yellow adult and two black child shoes standing on a brown ground',
  },
];

export const entrepreneurialSpirit = [
  {
    original:
      'images/ImageGallery/EntrepreneurialSpirit/01-graeme-worsfold-324nknuhxDg-unsplash_2680x1510px.jpg',
    originalAlt:
      'A man shown from behind walking inside an architectural modern hallway (black & white image)',
    thumbnail:
      'images/ImageGallery/EntrepreneurialSpirit/01-graeme-worsfold-324nknuhxDg-unsplash_400x400px.jpg',
    thumbnailAlt:
      'A man shown from behind walking inside an architectural modern hallway (black & white image)',
  },
  {
    original:
      'images/ImageGallery/EntrepreneurialSpirit/02-shutterstock_1036081342_2680x1510px.jpg',
    originalAlt: 'Blurred rainbow colors on a blurred black background',
    thumbnail:
      'images/ImageGallery/EntrepreneurialSpirit/02-shutterstock_1036081342_400x400px.jpg',
    thumbnailAlt: 'Blurred rainbow colors on a blurred black background',
  },
  {
    original:
      'images/ImageGallery/EntrepreneurialSpirit/04-GettyImages-1142007543_2680x1510px.jpg',
    originalAlt:
      'A woman sitting on a couch interacting with her tablet (blueish colorized image)',
    thumbnail:
      'images/ImageGallery/EntrepreneurialSpirit/04-GettyImages-1142007543_400x400px.jpg',
    thumbnailAlt:
      'A woman sitting on a couch interacting with her tablet (blueish colorized image)',
  },
  {
    original:
      'images/ImageGallery/EntrepreneurialSpirit/05-ross-findon-mG28olYFgHI-unsplashdfg_2680x1510px.jpg',
    originalAlt: 'The word Change written in voilet colored neon tubes',
    thumbnail:
      'images/ImageGallery/EntrepreneurialSpirit/05-ross-findon-mG28olYFgHI-unsplashdfg_400x400px.jpg',
    thumbnailAlt: 'The word Change written in voilet colored neon tubes',
  },
  {
    original:
      'images/ImageGallery/EntrepreneurialSpirit/06-mahdis-mousavi-I0QTg9ctFDY-unsplash_2680x1510px.jpg',
    originalAlt:
      'A person standing inside an architectural modern hallway made of glas looking outside (red/violet colorized image)',
    thumbnail:
      'images/ImageGallery/EntrepreneurialSpirit/06-mahdis-mousavi-I0QTg9ctFDY-unsplash_400x400px.jpg',
    thumbnailAlt:
      'A person standing inside an architectural modern hallway made of glas looking outside (red/violet colorized image)',
  },
  {
    original:
      'images/ImageGallery/EntrepreneurialSpirit/07-jakob-owens-CTflmHHVrBM-unsplash_2680x1510px.jpg',
    originalAlt:
      'Two people in front of a lightspot cutting the image in blue/white and pink/blue',
    thumbnail:
      'images/ImageGallery/EntrepreneurialSpirit/07-jakob-owens-CTflmHHVrBM-unsplash_400x400px.jpg',
    thumbnailAlt:
      'Two people in front of a lightspot cutting the image in blue/white and pink/blue',
  },
  {
    original:
      'images/ImageGallery/EntrepreneurialSpirit/08-erebus-CltCT1oAD30-unsplash_2680x1510px.jpg',
    originalAlt:
      'A quarter of a globe covered in oil colors in front of a black background',
    thumbnail:
      'images/ImageGallery/EntrepreneurialSpirit/08-erebus-CltCT1oAD30-unsplash_400x400px.jpg',
    thumbnailAlt:
      'A quarter of a globe covered in oil colors in front of a black background',
  },
  {
    original:
      'images/ImageGallery/EntrepreneurialSpirit/09-GettyImages-930209358_2680x1510px.jpg',
    originalAlt:
      'A man looking to the left photographed from a frog’s perspective in front of a wall of hundreds of lights',
    thumbnail:
      'images/ImageGallery/EntrepreneurialSpirit/09-GettyImages-930209358_400x400px.jpg',
    thumbnailAlt:
      'A man looking to the left photographed from a frog’s perspective in front of a wall of hundreds of lights',
  },
  {
    original:
      'images/ImageGallery/EntrepreneurialSpirit/10-GettyImages-1202250586_2680x1510px.jpg',
    originalAlt: 'Multiple code editor windows (blueish image)',
    thumbnail:
      'images/ImageGallery/EntrepreneurialSpirit/10-GettyImages-1202250586_400x400px.jpg',
    thumbnailAlt: 'Multiple code editor windows (blueish image)',
  },
  {
    original:
      'images/ImageGallery/EntrepreneurialSpirit/11-avi-richards-Z3ownETsdNQ-unsplash_2680x1510px.jpg',
    originalAlt:
      'A man sitting on a rooftop with his notebook on his knees at sunset',
    thumbnail:
      'images/ImageGallery/EntrepreneurialSpirit/11-avi-richards-Z3ownETsdNQ-unsplash_400x400px.jpg',
    thumbnailAlt:
      'A man sitting on a rooftop with his notebook on his knees at sunset',
  },
  {
    original:
      'images/ImageGallery/EntrepreneurialSpirit/12-GettyImages-932096974_2680x1510px.jpg',
    originalAlt:
      'An asian woman looking upwards in front of a blurred city background',
    thumbnail:
      'images/ImageGallery/EntrepreneurialSpirit/12-GettyImages-932096974_400x400px.jpg',
    thumbnailAlt:
      'An asian woman looking upwards in front of a blurred city background',
  },
  {
    original:
      'images/ImageGallery/EntrepreneurialSpirit/13-samuel-regan-asante-JjlkGAc4OUM-unsplash_2680x1510px.jpg',
    originalAlt:
      'The sentence »don’t just take, give.« written in pink/red neon letters',
    thumbnail:
      'images/ImageGallery/EntrepreneurialSpirit/13-samuel-regan-asante-JjlkGAc4OUM-unsplash_400x400px.jpg',
    thumbnailAlt:
      'The sentence »don’t just take, give.« written in pink/red neon letters',
  },
  {
    original:
      'images/ImageGallery/EntrepreneurialSpirit/14-thisisengineering-raeng-sbFmoKBK7jU-unsplash_2680x1510px.jpg',
    originalAlt:
      'A woman in profile standing in front of a pink and blue color gradient background',
    thumbnail:
      'images/ImageGallery/EntrepreneurialSpirit/14-thisisengineering-raeng-sbFmoKBK7jU-unsplash_400x400px.jpg',
    thumbnailAlt:
      'A woman in profile standing in front of a pink and blue color gradient background',
  },
  {
    original:
      'images/ImageGallery/EntrepreneurialSpirit/15-nazrin-b-va-6jaeAHB4ioU-unsplash_2680x1510px.jpg',
    originalAlt:
      'The sentence »The journey is on« written in capital white neon letters',
    thumbnail:
      'images/ImageGallery/EntrepreneurialSpirit/15-nazrin-b-va-6jaeAHB4ioU-unsplash_400x400px.jpg',
    thumbnailAlt:
      'The sentence »The journey is on« written in capital white neon letters',
  },
];

export const sustainableAction = [
  {
    original:
      'images/ImageGallery/SustainableAction/01-GettyImages-1023146992_2680x1510px.jpg',
    originalAlt:
      'Two persons in the backlight walking through a meadow of tall grass',
    thumbnail:
      'images/ImageGallery/SustainableAction/01-GettyImages-1023146992_400x400px.jpg',
    thumbnailAlt:
      'Two persons in the backlight walking through a meadow of tall grass',
  },
  {
    original:
      'images/ImageGallery/SustainableAction/02-silvan-schuppisser-bZYyc58rlT8-unsplash_2680x1510px.jpg',
    originalAlt: 'A green meadow with a lonely green tree in the background',
    thumbnail:
      'images/ImageGallery/SustainableAction/02-silvan-schuppisser-bZYyc58rlT8-unsplash_400x400px.jpg',
    thumbnailAlt: 'A green meadow with a lonely green tree in the background',
  },
  {
    original:
      'images/ImageGallery/SustainableAction/03-lance-anderson-G2SDLsJp3rg-unsplash_2680x1510px.jpg',
    originalAlt: 'A school of fish',
    thumbnail:
      'images/ImageGallery/SustainableAction/03-lance-anderson-G2SDLsJp3rg-unsplash_400x400px.jpg',
    thumbnailAlt: 'A school of fish',
  },
  {
    original:
      'images/ImageGallery/SustainableAction/04-juan-cruz-mountford-cQ6xF8hcuzU-unsplash_2680x1510px.jpg',
    originalAlt: 'A sea covered by ice floes in front of an iceberg',
    thumbnail:
      'images/ImageGallery/SustainableAction/04-juan-cruz-mountford-cQ6xF8hcuzU-unsplash_400x400px.jpg',
    thumbnailAlt: 'A sea covered by ice floes in front of an iceberg',
  },
  {
    original:
      'images/ImageGallery/SustainableAction/05-elion-jashari-6vJrC65e6rM-unsplash_2680x1510px.jpg',
    originalAlt: 'A diver photographed from beneath',
    thumbnail:
      'images/ImageGallery/SustainableAction/05-elion-jashari-6vJrC65e6rM-unsplash_400x400px.jpg',
    thumbnailAlt: 'A diver photographed from beneath',
  },
  {
    original:
      'images/ImageGallery/SustainableAction/06-olivia-hutcherson-EMP0_-qfva8-unsplash_2680x1510px.jpg',
    originalAlt: 'A leaf in close-up showing its veins',
    thumbnail:
      'images/ImageGallery/SustainableAction/06-olivia-hutcherson-EMP0_-qfva8-unsplash_400x400px.jpg',
    thumbnailAlt: 'A leaf in close-up showing its veins',
  },
  {
    original:
      'images/ImageGallery/SustainableAction/07-pauline-loroy-86rvSHXOjt4-unsplash_2680x1510px.jpg',
    originalAlt:
      'Two women holding banners where »Sick of pollution« is written on while being on a demonstration',
    thumbnail:
      'images/ImageGallery/SustainableAction/07-pauline-loroy-86rvSHXOjt4-unsplash_400x400px.jpg',
    thumbnailAlt:
      'Two women holding banners where »Sick of pollution« is written on while being on a demonstration',
  },
];
