import { Box } from '@chakra-ui/react';

import { AnchorNavigationLinkComponent } from 'components/AnchorNavigationLink';
import { PAGES } from 'constants/pages';
import { SECTIONS } from 'constants/sections';

type Props = {
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
};

export const BrandEssentialsAndBasics = ({ setOpen }: Props) => {
  return (
    <Box as="ul" listStyleType="none">
      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.INTRODUCTION.anchor}
          href={`${PAGES.BRAND_ESSENTIALS_AND_GUIDES.path}#${SECTIONS.INTRODUCTION.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.INTRODUCTION.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.THE_LOGO.anchor}
          href={`${PAGES.BRAND_ESSENTIALS_AND_GUIDES.path}#${SECTIONS.THE_LOGO.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.THE_LOGO.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.DESIGN_SYSTEM.anchor}
          href={`${PAGES.BRAND_ESSENTIALS_AND_GUIDES.path}#${SECTIONS.DESIGN_SYSTEM.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.DESIGN_SYSTEM.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.COLORS.anchor}
          href={`${PAGES.BRAND_ESSENTIALS_AND_GUIDES.path}#${SECTIONS.COLORS.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.COLORS.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.TYPOGRAPHY.anchor}
          href={`${PAGES.BRAND_ESSENTIALS_AND_GUIDES.path}#${SECTIONS.TYPOGRAPHY.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.TYPOGRAPHY.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.ICONOGRAPHY.anchor}
          href={`${PAGES.BRAND_ESSENTIALS_AND_GUIDES.path}#${SECTIONS.ICONOGRAPHY.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.ICONOGRAPHY.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.CHARTS.anchor}
          href={`${PAGES.BRAND_ESSENTIALS_AND_GUIDES.path}#${SECTIONS.CHARTS.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.CHARTS.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.IMAGERY.anchor}
          href={`${PAGES.BRAND_ESSENTIALS_AND_GUIDES.path}#${SECTIONS.IMAGERY.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.IMAGERY.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.STAFF_PHOTOGRAPHY_PORTRAITS.anchor}
          href={`${PAGES.BRAND_ESSENTIALS_AND_GUIDES.path}#${SECTIONS.STAFF_PHOTOGRAPHY_PORTRAITS.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.STAFF_PHOTOGRAPHY_PORTRAITS.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.DIGITAL_BRAND.anchor}
          href={`${PAGES.BRAND_ESSENTIALS_AND_GUIDES.path}#${SECTIONS.DIGITAL_BRAND.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.DIGITAL_BRAND.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.VIDEOGRAPHY.anchor}
          href={`${PAGES.BRAND_ESSENTIALS_AND_GUIDES.path}#${SECTIONS.VIDEOGRAPHY.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.VIDEOGRAPHY.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.XTRACKERS_SPECIFICS.anchor}
          href={`${PAGES.BRAND_ESSENTIALS_AND_GUIDES.path}#${SECTIONS.XTRACKERS_SPECIFICS.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.XTRACKERS_SPECIFICS.title}
        </AnchorNavigationLinkComponent>
      </Box>
    </Box>
  );
};
