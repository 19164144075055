import { Box } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { SECTIONS } from 'constants/sections';

import { TextComponent } from '../../components/Text';
import { space } from '../../theme/constants';

export const DifferenceEVPAndEmployerBrand = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.DIFFERENCE_EVP_EMPLOYER_BRAND.anchor}
      title={SECTIONS.DIFFERENCE_EVP_EMPLOYER_BRAND.title}
      {...props}
    >
      <Box
        mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.xSmall}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/evp_what.jpg'}
          alt="A highway at night"
        />
      </Box>

      <TextComponent variant={'medium'}>
        If our EVP provides the ‘logical’ basis for why our audience should join
        or stay at DWS, the Employer Brand offers the emotional, visceral
        expression of that argument. This is how we will connect with our
        audience and get them excited about the opportunities we offer. It’s the
        unifying idea that determines how we look, how we sound, and how we make
        our audience feel when we engage with them.
      </TextComponent>
    </ContentSectionComponent>
  );
};
