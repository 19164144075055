import { Box, Flex, FlexProps, useBreakpointValue } from '@chakra-ui/react';

import { LogoComponent } from 'components/Logo';
import { NavigationComponent } from 'components/Navigation';
import { SupportButton } from 'components/SupportButton';
import { size, space, transition, zIndex } from 'theme/constants';

type Props = {
  id: string;
  open: boolean;
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
} & FlexProps;

export const SidebarComponent = ({ id, open, setOpen }: Props) => {
  const ariaHidden = useBreakpointValue({
    base: !open,
    lg: false,
  });

  const handleBackdropClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <Box
        display={{ base: open ? 'block' : 'none', lg: 'none' }}
        position="fixed"
        zIndex={{ base: zIndex.mobileMenu.backdrop, lg: zIndex.below }}
        top="0"
        right="0"
        left="0"
        height="100vh"
        bgColor="background.translucent"
        onClick={handleBackdropClick}
        tabIndex={-1}
      />
      <Flex
        id={id}
        aria-hidden={ariaHidden}
        direction="column"
        position={{ base: 'fixed', lg: 'static' }}
        zIndex={{ base: zIndex.mobileMenu.panel, lg: 'auto' }}
        top={{ base: '0', lg: 'auto' }}
        bottom={{ base: '0', lg: 'auto' }}
        left={{ base: '0', lg: 'auto' }}
        width={{ base: size.sidebarWidthSmall, lg: 'auto' }}
        height="100%"
        transform={{
          base: open ? 'translateX(0)' : 'translateX(-100%)',
          lg: 'none',
        }}
        pt={space.large}
        bgColor="background.black"
        transition={{
          base: open ? `transform ${transition.default}` : 'none',
          lg: 'none',
        }}
      >
        <LogoComponent />
        <NavigationComponent setOpen={setOpen} />
        <Box
          height={'50px'}
          w={'100%'}
          borderTop={'1px solid'}
          borderTopColor={'text.secondaryDark'}
        >
          <SupportButton />
        </Box>
      </Flex>
    </>
  );
};
