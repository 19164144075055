import React from 'react';

import { Flex } from '@chakra-ui/react';

type Props = {
  children: React.ReactNode;
};

export const LoginLayout = ({ children }: Props) => {
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      width="100%"
      maxWidth="100vw"
      minHeight="100vh"
      p="36px 24px"
      backgroundSize={'cover'}
      height={window.innerHeight}
      backgroundImage={process.env.PUBLIC_URL + '/images/LoginBackground.jpg'}
      sx={{
        '@supports (-webkit-touch-callout: none)': {
          minHeight: '-webkit-fill-available',
        },
      }}
    >
      {children}
    </Flex>
  );
};
