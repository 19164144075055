export const ProfessionalHiresImages = [
  {
    original:
      'images/ImageGallery/ProfessionalHires/83066254_dws_grading_2680x1510px.jpg',
    originalAlt: 'Woman sitting in a chair talking to a man',
    thumbnail:
      'images/ImageGallery/ProfessionalHires/83066254_dws_grading_400x400px.jpg',
    thumbnailAlt: 'Woman sitting in a chair talking to a man',
  },
  {
    original:
      'images/ImageGallery/ProfessionalHires/83267273_dws_grading_2680x1510px.jpg',
    originalAlt: 'A man shown from behind carrying a suitcase',
    thumbnail:
      'images/ImageGallery/ProfessionalHires/83267273_dws_grading_400x400px.jpg',
    thumbnailAlt: 'A man shown from behind carrying a suitcase',
  },
  {
    original:
      'images/ImageGallery/ProfessionalHires/83313301_dws_grading_2680x1510px.jpg',
    originalAlt: 'A man shown from behind talking to two other man',
    thumbnail:
      'images/ImageGallery/ProfessionalHires/83313301_dws_grading_400x400px.jpg',
    thumbnailAlt: 'A man shown from behind talking to two other man',
  },
  {
    original:
      'images/ImageGallery/ProfessionalHires/84751240_dws_grading_2680x1510px.jpg',
    originalAlt: 'A man and a woman going up two escalators',
    thumbnail:
      'images/ImageGallery/ProfessionalHires/84751240_dws_grading_400x400px.jpg',
    thumbnailAlt: 'A man and a woman going up two escalators',
  },
  {
    original:
      'images/ImageGallery/ProfessionalHires/162835121_dws_grading_2680x1510px.jpg',
    originalAlt: 'A man shown from behind going up an escalator',
    thumbnail:
      'images/ImageGallery/ProfessionalHires/162835121_dws_grading_400x400px.jpg',
    thumbnailAlt: 'A man shown from behind going up an escalator',
  },
  {
    original:
      'images/ImageGallery/ProfessionalHires/bldjw_042411_093_dws_grading_2680x1510px.jpg',
    originalAlt: 'A crosswalk from above showing many people with umbrellas',
    thumbnail:
      'images/ImageGallery/ProfessionalHires/bldjw_042411_093_dws_grading_400x400px.jpg',
    thumbnailAlt: 'A crosswalk from above showing many people with umbrellas',
  },
  {
    original:
      'images/ImageGallery/ProfessionalHires/DWS-Invest-ESG-Women-for-Women---Product-Visual-ONLY-not-for-further-usage--98164015_dws_grading_2680x1510px.jpg',
    originalAlt: 'A woman smiling',
    thumbnail:
      'images/ImageGallery/ProfessionalHires/DWS-Invest-ESG-Women-for-Women---Product-Visual-ONLY-not-for-further-usage--98164015_dws_grading_400x400px.jpg',
    thumbnailAlt: 'A woman smiling',
  },
  {
    original:
      'images/ImageGallery/ProfessionalHires/GettyImages-694983373_dws_grading_2680x1510px.jpg',
    originalAlt: 'A meeting room where three people having a meeting',
    thumbnail:
      'images/ImageGallery/ProfessionalHires/GettyImages-694983373_dws_grading_400x400px.jpg',
    thumbnailAlt: 'A meeting room where three people having a meeting',
  },
  {
    original:
      'images/ImageGallery/ProfessionalHires/GettyImages-961044152_dws_grading_2680x1510px.jpg',
    originalAlt:
      'Five people sitting on a long table talking about some excel sheets',
    thumbnail:
      'images/ImageGallery/ProfessionalHires/GettyImages-961044152_dws_grading_400x400px.jpg',
    thumbnailAlt:
      'Five people sitting on a long table talking about some excel sheets',
  },
  {
    original:
      'images/ImageGallery/ProfessionalHires/GettyImages-1063759712_2680x1510px.jpg',
    originalAlt: 'A man looking on a screen',
    thumbnail:
      'images/ImageGallery/ProfessionalHires/GettyImages-1063759712_400x400px.jpg',
    thumbnailAlt: 'A man looking on a screen',
  },
  {
    original:
      'images/ImageGallery/ProfessionalHires/GettyImages-1210536686_dws_grading_2680x1510px.jpg',
    originalAlt: 'Two woman sitting in front of a laptop',
    thumbnail:
      'images/ImageGallery/ProfessionalHires/GettyImages-1210536686_dws_grading_400x400px.jpg',
    thumbnailAlt: 'Two woman sitting in front of a laptop',
  },
  {
    original:
      'images/ImageGallery/ProfessionalHires/GettyImages-1223377232_dws_grading_2680x1510px.jpg',
    originalAlt: 'A man sitting in a chair with a laptop and headphones',
    thumbnail:
      'images/ImageGallery/ProfessionalHires/GettyImages-1223377232_dws_grading_400x400px.jpg',
    thumbnailAlt: 'A man sitting in a chair with a laptop and headphones',
  },
];
