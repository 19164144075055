import { SimpleGridProps } from '@chakra-ui/react';

import { ColorGridItemComponent } from '../../components/ColorGridItem';
import { SimpleColorGridComponent } from '../../components/SimpleColorGrid';

export const ContextRelated = ({ ...props }: SimpleGridProps) => {
  return (
    <SimpleColorGridComponent {...props}>
      <ColorGridItemComponent
        color={'context.clientCommitment'}
        headline={'Fiduciary Petrol'}
        hexCode={'#56AEB1'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.sustainableAction'}
        headline={'Sustainable Green'}
        hexCode={'#ABD654'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.entrepreneurialSpirit'}
        headline={'Progressive Purple'}
        hexCode={'#755BBA'}
        hasBorder={false}
      />
    </SimpleColorGridComponent>
  );
};
