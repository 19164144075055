import { Box, Grid, GridItem } from '@chakra-ui/react';

import { CardComponent } from 'components/Card';
import { ContentSectionComponent } from 'components/ContentSection';
import { HeadingComponent } from 'components/Heading';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

export const Presentations = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.PRESENTATIONS.anchor}
      title={SECTIONS.PRESENTATIONS.title}
      {...props}
    >
      <Box
        mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.small}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/Presentations.jpg'}
          alt="A laptop showing a presentation template"
        />
      </Box>

      <HeadingComponent as="h3" variant="secondary" mb={space.small}>
        Available versions
      </HeadingComponent>

      <Grid
        templateColumns={{ base: '1fr', md: '1fr 1fr' }}
        gap={space.xSmall}
        mb={space.medium}
      >
        <GridItem display="flex" flexDirection="column">
          <CardComponent
            file="https://download.dws.com/download?elib-assetguid=1b87bc41a74b486c8308f41064ca3229"
            fileType="Zip"
            fileSize="10.5 MB"
            headingLevel="h4"
            imgAlt="DWS fonts version"
            imgSrc="AvailableVersions.png"
            isCompact
            title="DWS fonts version"
          />
        </GridItem>

        <GridItem display="flex" flexDirection="column">
          <CardComponent
            file="https://download.dws.com/download?elib-assetguid=e651e8b0cace49bc8d18990b270a1e44"
            fileType="Zip"
            fileSize="10.5 MB"
            headingLevel="h4"
            imgAlt="MS Office system fonts version"
            imgSrc="AvailableVersions.png"
            isCompact
            title="MS Office system fonts version"
          />
        </GridItem>
      </Grid>
    </ContentSectionComponent>
  );
};
