import React from 'react';

import { Box, VStack } from '@chakra-ui/react';

import { space } from '../../theme/constants';
import { HeadingComponent } from '../Heading';
import { TextComponent } from '../Text';

interface PictureCardProps {
  picturePath: string;
  title: string;
  children: React.ReactNode;
  altText: string;
}

export const PictureCard = ({
  picturePath,
  altText,
  title,
  children,
  ...props
}: PictureCardProps) => {
  return (
    <Box width={'100%'} {...props}>
      <VStack spacing={0}>
        <Box height={'auto'} width={'100%'}>
          <img src={picturePath} alt={altText} />
        </Box>
        <Box height={'300px'} width={'100%'} bg={'background.nearWhite'}>
          <Box
            width={'100%'}
            height={'100%'}
            paddingX={'20px'}
            paddingY={'30px'}
          >
            <HeadingComponent variant={'secondary'} mb={space.xxSmall}>
              {title}
            </HeadingComponent>
            <TextComponent variant={'small'}>{children}</TextComponent>
          </Box>
        </Box>
      </VStack>
    </Box>
  );
};
