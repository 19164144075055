import { Icon, IconProps } from '@chakra-ui/react';

const CrossBoxIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 60 46" aria-hidden="true" fill="none" {...props}>
      <path
        fillRule="evenodd"
        d="M59 1H1v44h58V1zm1-1v46H0V0h60z"
        clipRule="evenodd"
      />
      <path d="M37.6759 11.8789l-15.6476 18.439v3.8049l15.6476-18.439v-3.8049z" />
      <path d="M22.0283 11.8789l15.6476 18.439v3.8049l-15.6476-18.439v-3.8049z" />
    </Icon>
  );
};

export default CrossBoxIcon;
