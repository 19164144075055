export const elementId = {
  sidebar: 'a11y-sidebar',
};

export const size = {
  contentMaxWidth: '1464px',
  sidebarWidthSmall: '320px',
  sidebarWidthMedium: '392px',
  sidebarWidthLarge: '456px',
  textLimitedWidth: '496px',
  textMaxWidth: '904px',
};

export const space = {
  xxSmall: '12px',
  xSmall: '24px',
  small: '36px',
  medium: '48px',
  large: '72px',
  xLarge: '80px',
  xxLarge: '160px',
};

export const transition = {
  default: '150ms ease-out',
};

export const zIndex = {
  mobileMenu: {
    button: 12,
    panel: 11,
    backdrop: 10,
  },
  above: 2,
  default: 1,
  below: -1,
};
