import * as React from 'react';

import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { Md5 } from 'ts-md5';
import Cookies from 'universal-cookie';

import { ButtonComponent } from 'components/Button';
import { HeadingComponent } from 'components/Heading';
import { LogoComponent } from 'components/Logo';
import { space } from 'theme/constants';

export const LoginComponent = () => {
  const history = useHistory();
  const [error, setError] = React.useState(false);

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();

    const target: any = event.target; // eslint-disable-line @typescript-eslint/no-explicit-any

    const md5 = new Md5();
    const password = md5.appendStr(target.password.value).end();

    if (password === process.env.REACT_APP_PASSWORD_AUTH) {
      const cookies = new Cookies();
      cookies.set('passwordAuth', true, { path: '/' });
      setError(false);
      history.push('/brand-new');
    } else {
      setError(true);
    }
  };

  return (
    <>
      <Box ml="-40px">
        <LogoComponent />
      </Box>

      <Flex
        direction="column"
        align="flex-start"
        justify="flex-start"
        width="420px"
        maxWidth="100%"
        p="32px 36px 36px"
        bgColor="background.nearBlack"
      >
        <HeadingComponent as="h1" mb={space.small} color="text.primaryLight">
          Login
        </HeadingComponent>
        <Box
          as="form"
          display="flex"
          flexDirection="column"
          width="100%"
          onSubmit={handleSubmit}
        >
          <FormControl id="password" mb={space.small} isInvalid={error}>
            <FormLabel mb={4} color="text.primaryLight">
              Password
            </FormLabel>
            <Input
              type="password"
              h="60px"
              px={4}
              borderRadius="0"
              bgColor="background.white"
              name="password"
            />
            <FormErrorMessage>Invalid Password</FormErrorMessage>
          </FormControl>
          <ButtonComponent type="submit">Login</ButtonComponent>
        </Box>
      </Flex>
    </>
  );
};
