import { Box } from '@chakra-ui/react';

import { MobileMenuButtonComponent } from 'components/MobileMenuButton';
import { elementId, space } from 'theme/constants';

type Props = {
  ariaControlsId: string;
  open: boolean;
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
};

export const MobileHeaderComponent = ({
  ariaControlsId,
  open,
  setOpen,
}: Props) => {
  return (
    <Box
      display={{ base: 'block', lg: 'none' }}
      height="72px"
      px={space.xSmall}
      color="text.primaryLight"
      bgColor="background.black"
    >
      <MobileMenuButtonComponent
        target={elementId.sidebar}
        open={open}
        setOpen={setOpen}
        aria-controls={ariaControlsId}
      />
    </Box>
  );
};
