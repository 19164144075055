import { Box, Grid, GridItem, VStack } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { DownloadFileComponent } from 'components/DownloadFile';
import { TextComponent } from 'components/Text';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

import { DividerComponent } from '../../components/Divider';
import { HeadingComponent } from '../../components/Heading';
import { TableComponent } from '../../components/Table';
import { headerCols, tbodyRows } from '../LogoSizeRatios/TableData';

export const TheLogo = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.THE_LOGO.anchor}
      title={SECTIONS.THE_LOGO.title}
      {...props}
    >
      <Box
        mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.xSmall}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/TheLogo.jpg'}
          alt="The DWS logo in white on a black cylinder"
        />
      </Box>

      <TextComponent limitedWidth mb={space.large}>
        Creating an entire new DWS brand was the logical result of the IPO in
        2018. DWS became an independent global asset manager and brand. The new
        logo symbolizes our heritage and our future ambition. It showcases our
        embrace of change and drive to become better every day in service to our
        clients and the creation of sustainable value.
      </TextComponent>

      <Box mb={space.small}>
        <img
          src={process.env.PUBLIC_URL + '/images/LogoVersions.png'}
          alt="Three different logo types – white on black, gray on white and black on gray – each in two different sizes"
        />
      </Box>

      <VStack align="stretch" spacing={2} mb={space.large}>
        <DownloadFileComponent
          file={
            'https://download.dws.com/download?elib-assetguid=33d3266348bf46c0beb78ef95d7f2004'
          }
          fileType="Zip"
          fileSize="1.1 MB"
        >
          DWS logo files
        </DownloadFileComponent>

        <DownloadFileComponent
          file={
            'https://download.dws.com/download?elib-assetguid=ce01be21b82c43c88618aefdf2420c04'
          } // ToDo: use correct file download
          fileType="Zip"
          fileSize="1.1 MB"
        >
          DWS logo quickguide
        </DownloadFileComponent>
      </VStack>

      <Box
        backgroundColor={'background.black'}
        mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        py={{ base: space.small, lg: space.large }}
        mb={space.large}
      >
        <HeadingComponent
          as={'h2'}
          variant={'secondary'}
          sx={{
            color: 'text.primaryLight',
            mx: { base: space.xSmall, lg: space.medium, xl: space.xLarge },
            mb: space.xSmall,
          }}
        >
          Logo size ratios
        </HeadingComponent>
        <Box
          mx={{ base: space.xSmall, lg: space.medium, xl: space.xLarge }}
          color="text.primaryLight"
          sx={{
            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale',
          }}
        >
          <TextComponent mb={space.xSmall}>
            In general the logo sizes are slightly scaled down to appear more
            humble, grounded and less &quot;we-centric&quot;, aligned with the
            brand core values and characteristcs.
          </TextComponent>

          <TextComponent mb={space.xSmall}>
            All sizes are defined in a range to meet the requirements of common
            formats and ensure appropriate readability. Therefore logos always
            need to be sized based on these guidelines. In case of different
            requirements, the closest default has to be applied (by rounding
            down).
          </TextComponent>

          <TableComponent
            headerCols={headerCols}
            isInverted
            tbodyRows={tbodyRows}
          />
        </Box>
      </Box>

      <HeadingComponent as={'h2'} variant={'secondary'} mb={space.xSmall}>
        Logo & claim
      </HeadingComponent>

      <Box
        mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.large}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/LogoClaim.png'}
          alt="How to use the logo an claim together on four different backgrounds"
        />
      </Box>

      <DownloadFileComponent
        file={
          'https://download.dws.com/download?elib-assetguid=de59c532938f47b8ae00d65464e100e2'
        }
        fileType="Zip"
        fileSize="730 KB"
      >
        DWS logo & claim
      </DownloadFileComponent>

      <DividerComponent />

      <HeadingComponent as={'h2'} variant={'secondary'} mb={space.xSmall}>
        Specific logotypes
      </HeadingComponent>

      <Box>
        <HeadingComponent as="h3" variant="secondary">
          DWS Xtrackers logo
        </HeadingComponent>

        <TextComponent mb={space.xxSmall}>
          For Xtrackers product promotions only.
        </TextComponent>

        <Box mb={space.xxSmall}>
          <img
            src={process.env.PUBLIC_URL + '/images/LogosXtrackers.png'}
            alt="Three different logo types – white on black, gray on white and black on gray, each with the addendum Xtrackers written in orange – in two different sizes"
          />
        </Box>

        <Box mb={space.small}>
          <img
            src={
              process.env.PUBLIC_URL + '/images/LogosXtrackersSideBySide.png'
            }
            alt="Three different logo types – white on black, gray on white and black on gray, each with the addendum Xtrackers written in orange – in two different sizes"
          />
        </Box>

        <DownloadFileComponent
          mb={space.large}
          file={
            'https://download.dws.com/download?elib-assetguid=8030990456f543feb171bd60321b3cfa'
          }
          fileType="Zip"
          fileSize="7.1 MB"
        >
          DWS Xtrackers logo files
        </DownloadFileComponent>

        <HeadingComponent as="h3" variant="secondary" mb={space.xxSmall}>
          DWS Chinese logo
        </HeadingComponent>

        <Box
          mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
          mb={space.small}
        >
          <img
            src={process.env.PUBLIC_URL + '/images/LogosChinese.png'}
            alt="Three different logo types – white on black, gray on white and black on gray – first as a main chinese version, second as a simplified chinese version"
          />
        </Box>

        <DownloadFileComponent
          mb={space.xLarge}
          file={
            'https://download.dws.com/download?elib-assetguid=846a8269af3949ca8712f22cc8ca9699'
          }
          fileType="Zip"
          fileSize="1.1 MB"
        >
          DWS Chinese logo files
        </DownloadFileComponent>

        <HeadingComponent as="h3" variant={'secondary'} mb={space.xxSmall}>
          DWS Pride logo
        </HeadingComponent>

        <Box mb={space.small}>
          <img
            src={process.env.PUBLIC_URL + '/images/LogosPride.png'}
            alt="Three different logo types – white on black, gray on white and black on gray – pride Logos"
          />
        </Box>

        <DownloadFileComponent
          mb={space.xLarge}
          file={
            'https://download.dws.com/download?elib-assetguid=928b18ea57134d52ab93d9fd6b72d17e'
          }
          fileType="Zip"
          fileSize="1.1 MB"
        >
          DWS Pride logo files
        </DownloadFileComponent>

        <HeadingComponent as="h3" variant="secondary" mb={space.xxSmall}>
          Co-branding specifics
        </HeadingComponent>

        <Grid
          templateColumns={{ base: '1fr', md: '1fr 1fr' }}
          templateAreas={{ base: "'a' 'b' 'c'", md: "'a .' 'b c'" }}
          rowGap={space.xSmall}
          columnGap={space.medium}
          mb={space.small}
        >
          <GridItem gridArea="a">
            <TextComponent>
              The DWS logo has to be placed on the right side, with the partner
              logo to appear on the left.
            </TextComponent>
          </GridItem>
          <GridItem gridArea="b">
            <TextComponent>
              The distance between both logos is &quot;DWS&quot; letters out of
              the DWS logo. Both logos are visually separated by a vertical
              medium grey line, which is slightly larger than both logos.
            </TextComponent>
          </GridItem>
          <GridItem gridArea="c">
            <TextComponent textAlign={{ base: 'left', lg: 'right' }}>
              The line thickness is 0.25pt on an A4 format and below. It becomes
              proportionally thicker on bigger formats, but should remain as
              thin as possible.
            </TextComponent>
          </GridItem>
        </Grid>

        <Box
          mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
          mb={space.small}
        >
          <img
            src={
              process.env.PUBLIC_URL + '/images/LogosCoBrandingSpecifics.png'
            }
            alt="How to use the DWS logo for example together with the Deutsche Bank logo"
          />
        </Box>
      </Box>

      <Box mr={{ lg: '13.5%' }} mb={space.small} ml={{ lg: '28.5%' }}>
        <TextComponent>
          Both logos should appear well balanced and have the same visual
          importance. If possible both logos should be a single color / fulltone
          version.
        </TextComponent>
      </Box>
    </ContentSectionComponent>
  );
};
