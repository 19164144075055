import * as React from 'react';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionProps,
  Box,
  Flex,
} from '@chakra-ui/react';

import { space } from 'theme/constants';

// Accordion
type AccordionComponentProps = {
  children: React.ReactNode;
} & AccordionProps;

export const AccordionComponent = ({
  children,
  ...props
}: AccordionComponentProps) => {
  return <Accordion {...props}>{children}</Accordion>;
};

// Accordion Item
type AcordionItemProps = {
  color?: string;
  hasBorder?: boolean;
  headingLevel: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  isPadded?: boolean;
  isUppercase?: boolean;
  panelBgColor?: string;
  title: string;
  children: React.ReactNode;
};

export const AccordionItemComponent = ({
  color,
  hasBorder,
  headingLevel,
  isPadded,
  isUppercase,
  panelBgColor,
  title,
  children,
  ...props
}: AcordionItemProps) => {
  return (
    <AccordionItem
      {...(hasBorder
        ? { borderBottom: '1px solid', borderBottomColor: 'border.nearWhite' }
        : { border: '0' })}
      {...props}
    >
      <Flex alignItems="flex-start">
        <Box flex="1 0 auto">
          <Box as={headingLevel}>
            <AccordionButton
              py={space.xSmall}
              _focus={{
                boxShadow: 'none',
                ...(color ? { color: color } : { color: 'text.highlight' }),
              }}
              _hover={{
                ...(color ? { color: color } : { color: 'text.highlight' }),
              }}
              {...(isPadded
                ? { px: { base: space.xSmall, lg: space.small } }
                : { px: '0' })}
              {...(isUppercase && {
                fontFamily: "'DWS Slab', Arial, sans-serif",
                fontSize: '12px',
                lineHeight: '1.25',
                textTransform: 'uppercase',
              })}
            >
              <Box as="span" flex="1" fontWeight="700" textAlign="left">
                {title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Box>
        </Box>
      </Flex>

      <AccordionPanel
        bgColor={panelBgColor}
        {...(isPadded
          ? {
              pt: space.xxSmall,
              px: { base: space.xSmall, lg: space.small },
              pb: space.xSmall,
            }
          : { pt: '0', px: '0', pb: '5' })}
      >
        {children}
      </AccordionPanel>
    </AccordionItem>
  );
};
