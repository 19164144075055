import { Box } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { SECTIONS } from 'constants/sections';

import { TextComponent } from '../../components/Text';
import { space } from '../../theme/constants';

export const IntroductionEmployerBrandSection = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.INTRODUCTION_EMPLOYER_BRAND.anchor}
      title={SECTIONS.INTRODUCTION_EMPLOYER_BRAND.title}
      {...props}
    >
      <Box
        mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.xSmall}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/employerbrand_intro.jpg'}
          alt="People walking across a street while it's raining"
          height={'auto'}
        />
      </Box>

      <TextComponent variant={'medium'}>
        The Employer Brand is inspired by our corporate brand,{' '}
        <b>Investors for a new now;</b> this is the golden thread that runs
        through all communications. With transformation and forward-thinking at
        the forefront, the Employer Brand is led by themes of action, movement,
        and dynamism.
      </TextComponent>
    </ContentSectionComponent>
  );
};
