import { Box, VStack } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { DownloadFileComponent } from 'components/DownloadFile';
import { HeadingComponent } from 'components/Heading';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

export const EmailHeaderAndTemplates = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.EMAIL_HEADERS_AND_TEMPLATES.anchor}
      title={SECTIONS.EMAIL_HEADERS_AND_TEMPLATES.title}
      {...props}
    >
      <HeadingComponent as="h3" variant="secondary" mb={space.xxSmall}>
        Email headers
      </HeadingComponent>

      <Box
        mb={space.small}
        mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/EmailHeaders.jpg'}
          alt="A tablet showing three different email headers"
        />
      </Box>

      <VStack align="stretch" spacing={2} mb={space.large}>
        <DownloadFileComponent
          file={
            'https://download.dws.com/download?elib-assetguid=4a0ca7e97ee84124adcb3cd57c008db7'
          }
          fileType="Zip"
          fileSize="662.7 MB"
        >
          Email-header templates (PSD)
        </DownloadFileComponent>

        <DownloadFileComponent
          file={
            'https://download.dws.com/download?elib-assetguid=eb2fa3a674a94b7094ef046322811ae1'
          }
          fileType="Zip"
          fileSize="662.7 MB"
        >
          Email-header templates (PPT)
        </DownloadFileComponent>
      </VStack>

      <HeadingComponent as="h3" variant="secondary" mb={space.small}>
        Email templates
      </HeadingComponent>

      <Box
        mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.small}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/NewsletterTemplates.jpg'}
          alt=""
        />
      </Box>

      <VStack align="stretch" spacing={2} mb={space.large}>
        <DownloadFileComponent
          file={
            'https://download.dws.com/download?elib-assetguid=90c0298786614e2d8ba7665edc298fe5'
          }
          fileType="Zip"
          fileSize="313.8 MB"
        >
          Announcement template
        </DownloadFileComponent>

        <DownloadFileComponent
          mb={space.large}
          file={
            'https://download.dws.com/download?elib-assetguid=bd5a191bd7e64847bfde64a4f8e93a89'
          }
          fileType="Zip"
          fileSize="313.8 MB"
        >
          Announcement & invite template
        </DownloadFileComponent>

        <DownloadFileComponent
          mb={space.large}
          file={
            'https://download.dws.com/download?elib-assetguid=c7d87eae2c4c43cdb8b07699faa09f93'
          }
          fileType="Zip"
          fileSize="313.8 MB"
        >
          E-Newsletter template
        </DownloadFileComponent>
      </VStack>
    </ContentSectionComponent>
  );
};
