import { SimpleGridProps } from '@chakra-ui/react';

import { ColorGridItemComponent } from '../../components/ColorGridItem';
import { SimpleColorGridComponent } from '../../components/SimpleColorGrid';

export const Secondaries = ({ ...props }: SimpleGridProps) => {
  return (
    <SimpleColorGridComponent {...props}>
      <ColorGridItemComponent
        color={'context.grayBlue'}
        headline={'Gray Blue'}
        hexCode={'#5A6D78'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.earthGreige'}
        headline={'Earth Greige'}
        hexCode={'#AAA299'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.grassGreen'}
        headline={'Grass Green'}
        hexCode={'#60B465'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.skyBlue'}
        headline={'Sky Blue'}
        hexCode={'#5DB4DC'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.oceanBlue'}
        headline={'Ocean Blue'}
        hexCode={'#2B5192'}
        hasBorder={false}
      />
    </SimpleColorGridComponent>
  );
};
