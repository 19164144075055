import { Icon, IconProps } from '@chakra-ui/react';

const DownloadCircleIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 64 64" aria-hidden="true" fill="none" {...props}>
      <path stroke="#000" strokeWidth="2" d="M23 32v7h18v-7" />
      <path stroke="#000" strokeWidth="2" d="M25 25l7 8 7-8" />
      <circle cx="32" cy="32" r="31" stroke="#000" strokeWidth="2" />
    </Icon>
  );
};

export default DownloadCircleIcon;
