import { Flex } from '@chakra-ui/react';

import { DARK_PETROL } from 'theme';

import { space } from '../../theme/constants';

interface NavigationLinkProps {
  title: string;
  path: string;
}
export const NavigationLink = ({ title, path }: NavigationLinkProps) => {
  return (
    <Flex
      as={'a'}
      textDecoration={'none'}
      paddingY={space.xSmall}
      paddingX={{ base: space.xSmall, lg: space.small }}
      width={'100%'}
      height={'100%'}
      color={'white'}
      backgroundColor={'transparent'}
      _hover={{ color: 'text.highlight' }}
      _focus={{ color: 'text.highlight' }}
      _active={{ color: DARK_PETROL }}
      href={path}
    >
      {title}
    </Flex>
  );
};
