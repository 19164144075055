import { Box } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

export const Introduction = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.INTRODUCTION.anchor}
      title={SECTIONS.INTRODUCTION.title}
      {...props}
    >
      <Box mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}>
        <img
          src={process.env.PUBLIC_URL + '/images/Introduction.jpg'}
          alt="A woman shown from behind sitting in front of water and a big city skyline with text on it that reads: Essential brand asset are those that truly distinguishes our brand. Unique characteristics that can only belong to us and all that form the foundation our further brand experience is built on."
        />
      </Box>
    </ContentSectionComponent>
  );
};
