import React from 'react';

import { Box } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { TextComponent } from 'components/Text';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

import { FIDUCARY_PETROL } from '../../theme';

export const EmployerBrandClaim = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.EMPLOYER_BRAND_CLIM.anchor}
      isIndented
      isInverted
      title={SECTIONS.EMPLOYER_BRAND_CLIM.title}
      {...props}
    >
      <Box
        mx={{ base: space.xSmall, lg: space.medium, xl: space.xLarge }}
        color="text.primaryLight"
        sx={{
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
        }}
      >
        <TextComponent mb={space.xSmall}>
          Our Employer Brand claim was built from our corporate brand claim,
          with the golden thread of <b>Investors for a new now</b> embedded
          throughout. This is our promise to our people and those considering a
          career with DWS. It echoes the messages of our EVP and sets the
          Employer Brand.
        </TextComponent>

        <TextComponent mb={space.xSmall} color={FIDUCARY_PETROL}>
          As the world changes, so do markets. We have seen it time and time
          again. The gold standard fell; the oil crisis hit; Reaganomics shapes
          an era; the Berlin Wall fell and with it a bipolar Europe came
          together and created a new fiat currency; we witnessed acts of terror
          and conflict; markets rose to incredible highs on the promise of tech
          and real estate; markets crashed to unthinkable lows after broken
          promises of tech and real estate; countries buckled under the pressure
          of sovereign debt.
        </TextComponent>

        <TextComponent mb={space.xSmall} color={FIDUCARY_PETROL}>
          Today, markets still face a whole new set of pressures, but also a
          whole lot of opportunity too. Opportunity to innovate differently, to
          invest responsibly, to make change. Join us at DWS, and you can be
          part of an industry-leading firm with a global presence. You can lead
          ambitious opportunities and shape the future of investing. You can
          support our clients, local communities, and the environment.
        </TextComponent>

        <TextComponent mb={space.xSmall} color={FIDUCARY_PETROL}>
          We&apos;re looking for creative thinkers and innovators to join us as
          as the world continues to transform. As whole markets change, one
          remains clear; our people always work together to capture the
          opportunities of tomorrow. That&apos;s why we are{' '}
          <b>Investors for a new now.</b>
        </TextComponent>

        <TextComponent mb={space.xSmall} color={FIDUCARY_PETROL}>
          As investors on behalf of our clients, it is our role to find
          investment solutions. Ensuring the best possible foundation for our
          clients’ financial future. And in return, we’ll give you the support
          and platform to develop new skills, make an impact and work alongside
          some of the industry’s greatest thought leaders. This is your chance
          to achieve your goals and lead an extraordinary career.
        </TextComponent>
        <TextComponent mb={space.xSmall} color={FIDUCARY_PETROL}>
          This is your chance to invest in your future.
        </TextComponent>
      </Box>
    </ContentSectionComponent>
  );
};
