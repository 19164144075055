import React, { Reducer, useContext, useEffect, useReducer } from 'react';

import { useLocation } from 'react-router-dom';

type NavigationProviderProps = {
  children: React.ReactNode;
};

export const ScrollSpyContext = React.createContext<ScrollSpyState | undefined>(
  undefined,
);

enum ScrollSpyActionType {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}

interface ScrollSpyAction {
  type: ScrollSpyActionType;
  payload: string | null;
}

interface ScrollSpyState {
  activeItems: Array<string | null>;
}

function navSpyReducer(state: ScrollSpyState, action: ScrollSpyAction) {
  switch (action.type) {
    case ScrollSpyActionType.ADD: {
      return { activeItems: [...state.activeItems, action.payload] };
    }
    case ScrollSpyActionType.REMOVE: {
      const index = state.activeItems.indexOf(action.payload);
      if (index !== -1) {
        return {
          activeItems: [
            ...state.activeItems.slice(0, index),
            ...state.activeItems.slice(index + 1),
          ],
        };
      } else {
        return state;
      }
    }
    default:
      throw new Error(`Unknown action ${action.type}`);
  }
}

export const ScrollSpyProvider = ({ children }: NavigationProviderProps) => {
  const location = useLocation();
  const [state, dispatch] = useReducer<
    Reducer<ScrollSpyState, ScrollSpyAction>
  >(navSpyReducer, {
    activeItems: [],
  });

  const observer = React.useRef<IntersectionObserver>(
    new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const id = entry.target.getAttribute('id');
        if (entry.intersectionRatio > 0) {
          dispatch({ type: ScrollSpyActionType.ADD, payload: id });
        } else {
          dispatch({ type: ScrollSpyActionType.REMOVE, payload: id });
        }
      });
    }),
  );

  useEffect(() => {
    const { current: ourObserver } = observer;
    ourObserver.disconnect();

    const elements = document.querySelectorAll('section[id]');
    elements.forEach((element) => ourObserver.observe(element));

    return () => ourObserver.disconnect();
  }, [location.pathname]);

  return (
    <ScrollSpyContext.Provider value={state}>
      {children}
    </ScrollSpyContext.Provider>
  );
};

export function useScrollSpy() {
  const context = useContext(ScrollSpyContext);
  if (context === undefined) {
    throw new Error('useScrollSpy must be used within a ScrollSpyProvider');
  }

  return context;
}
