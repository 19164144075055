import { Box } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { TextComponent } from 'components/Text';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

export const Characteristics = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.CHARACTERISTICS.anchor}
      isInverted
      title={SECTIONS.CHARACTERISTICS.title}
      {...props}
    >
      <TextComponent limitedWidth color="text.primaryLight">
        Our core values are based on the following characteristics that define
        our culture as a firm and our brand identity.
      </TextComponent>

      <Box mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }} mt={'-5%'}>
        <img
          src={process.env.PUBLIC_URL + '/images/Characteristics.png'}
          alt="Three circles – Client Commitment, Sustainable Action and Entrepreneurial Spirit – with full of buzzwords intersectioning each other"
        />
      </Box>
    </ContentSectionComponent>
  );
};
