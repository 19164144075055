import { Box, VStack } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { DownloadFileComponent } from 'components/DownloadFile';
import { TextComponent } from 'components/Text';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

export const Typography = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.TYPOGRAPHY.anchor}
      isIndented
      isInverted
      title={SECTIONS.TYPOGRAPHY.title}
      {...props}
    >
      <Box
        mx={{ base: space.xSmall, lg: space.medium, xl: space.xLarge }}
        color="text.primaryLight"
        sx={{
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
        }}
      >
        <TextComponent mb={space.small}>
          Six styles to further write the DWS story.
        </TextComponent>

        <Box
          mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
          mb={space.small}
        >
          <img
            src={process.env.PUBLIC_URL + '/images/Typography.png'}
            alt="Both corporate fonts DWS Sans and DWS Slab shown in big letters next to each other"
          />
        </Box>

        <VStack align="stretch" spacing={2} mb={space.large}>
          <DownloadFileComponent
            file="https://download.dws.com/download?elib-assetguid=92e30d536f024fbbae2e5557d24c7328"
            fileType="Zip"
            fileSize="1.5 MB"
          >
            DWS fonts
          </DownloadFileComponent>

          <DownloadFileComponent
            file="https://download.dws.com/download?elib-assetguid=6be74ac18c97409cb6ebe6c63dee9387"
            fileType="Zip"
            fileSize="1.5 MB"
          >
            Typography quickguide
          </DownloadFileComponent>
        </VStack>
      </Box>
    </ContentSectionComponent>
  );
};
