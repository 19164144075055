import { Box, Flex } from '@chakra-ui/react';

import { space, transition } from 'theme/constants';

import { HeadingComponent } from '../Heading';
import DownloadCircleIcon from '../Icon/DownloadCircleIcon';
import { TextComponent } from '../Text';

type Props = {
  file: string;
  fileType: string;
  fileSize: string;
  headingLevel: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  imgAlt: string;
  imgSrc: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  isCompact?: boolean;
  title: string;
  children?: string;
};

export const CardComponent = ({
  isCompact,
  file,
  fileType,
  fileSize,
  headingLevel,
  imgAlt,
  imgSrc,
  title,
  children,
}: Props) => {
  return (
    <Flex
      as="a"
      download
      href={file}
      direction="column"
      flex="1"
      width="100%"
      textDecoration="none"
      color="inherit"
      bgColor="background.nearWhite"
      _focusVisible={{
        outline: '2px solid',
        outlineColor: 'border.highlight',
        outlineOffset: '4px',
      }}
      sx={{
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        ':hover svg': {
          transform: 'scale(1.1)',
        },
      }}
      _active={{
        transform: 'translateY(2px)',
      }}
    >
      <Box>
        <img src={process.env.PUBLIC_URL + '/images/' + imgSrc} alt={imgAlt} />
      </Box>

      {isCompact ? (
        <Flex direction="column" flex="1" pr={4} py={10} pl={8}>
          <TextComponent
            variant="tiny"
            as="span"
            mb={0.5}
            textTransform="uppercase"
            color="text.secondaryDark"
          >
            {fileType}, {fileSize}
          </TextComponent>
          <Flex>
            <Box flex="1" pr={space.xxSmall}>
              <HeadingComponent as={headingLevel} variant="secondary" mb={1}>
                {title}
              </HeadingComponent>

              <TextComponent>{children}</TextComponent>
            </Box>

            <DownloadCircleIcon
              boxSize={16}
              transition={`transform ${transition.default}`}
            />
          </Flex>
        </Flex>
      ) : (
        <Flex direction="column" flex="1" px={8} py={10}>
          <Box flex="1" mb={space.small}>
            <HeadingComponent as={headingLevel} variant="secondary" mb={2}>
              {title}
            </HeadingComponent>

            <TextComponent>{children}</TextComponent>
          </Box>

          <Flex as="footer" align="flex-end" justify="space-between">
            <DownloadCircleIcon
              boxSize={16}
              transition={`transform ${transition.default}`}
            />
            <TextComponent
              variant="tiny"
              as="span"
              textTransform="uppercase"
              color="text.secondaryDark"
            >
              {fileType}, {fileSize}
            </TextComponent>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
