import * as React from 'react';

import { ListItem, ListProps, UnorderedList } from '@chakra-ui/react';

import { space } from 'theme/constants';

type UnorderedListProps = {
  children: React.ReactNode;
} & ListProps;

export const UnorderedListComponent = ({
  children,
  ...props
}: UnorderedListProps) => {
  return (
    <UnorderedList
      position="relative"
      spacing={space.xSmall}
      styleType="none"
      {...props}
    >
      {children}
    </UnorderedList>
  );
};

type ListItemProps = {
  children: React.ReactNode;
};

export const ListItemComponent = ({ children, ...props }: ListItemProps) => {
  return (
    <ListItem
      _before={{
        content: "'–'",
        position: 'absolute',
        left: '-16px',
      }}
      {...props}
    >
      {children}
    </ListItem>
  );
};
