import { Box, useBreakpointValue } from '@chakra-ui/react';

import { zIndex } from 'theme/constants';

type Props = {
  open: boolean;
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  target: string;
};

export const MobileMenuButtonComponent = ({ open, setOpen, target }: Props) => {
  const tabIndex = useBreakpointValue({
    base: 0,
    lg: -1,
  });

  const handleButtonClick = () => {
    setOpen(!open);
  };

  return (
    <Box
      as="button"
      type="button"
      aria-label={open ? 'Close Mobile Navigation' : 'Open Mobile Navigation'}
      aria-controls={target}
      aria-expanded={open}
      onClick={handleButtonClick}
      tabIndex={tabIndex}
      position="fixed"
      zIndex={zIndex.mobileMenu.button}
      top="16px"
      left="16px"
      width="40px"
      height="40px"
      px="8px"
      sx={{
        WebkitTapHighlightColor: 'transparent',
      }}
    >
      <Box
        as="span"
        role="presentation"
        display="block"
        position="absolute"
        top="50%"
        width="24px"
        height="2px"
        mt="-1px"
        transform={open ? 'rotate(45deg)' : 'none'}
        bgColor="background.white"
        transition={
          open
            ? 'transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 120ms'
            : 'transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19)'
        }
        _before={{
          content: "''",
          opacity: open ? '0' : '1',
          display: 'block',
          position: 'absolute',
          top: open ? '0' : '-8px',
          width: '24px',
          height: '2px',
          bgColor: 'background.white',
          transition: open
            ? 'opacity 75ms ease 120ms, top 75ms ease'
            : 'opacity 75ms ease, top 75ms ease 120ms',
        }}
        _after={{
          content: "''",
          display: 'block',
          position: 'absolute',
          bottom: open ? '0' : '-8px',
          width: '24px',
          height: '2px',
          transform: open ? 'rotate(-90deg)' : 'none',
          bgColor: 'background.white',
          transition: open
            ? 'bottom 75ms ease, transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 120ms'
            : 'bottom 75ms ease 120ms, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19)',
        }}
      />
    </Box>
  );
};
