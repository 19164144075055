import React from 'react';

import { Box } from '@chakra-ui/react';

import { HeadingComponent } from '../components/Heading';
import { PageFooterComponent } from '../components/PageFooter';
import { PAGES } from '../constants/pages';
import { EarlyCareers } from '../sections/EarlyCareers';
import { ProfessionalHires } from '../sections/ProfessionalHires';
import { size, space } from '../theme/constants';

const EmployerValueProposition: React.FC = () => {
  document.title = `${PAGES.AUDIENCE_SEGMENTATION.title} | DWS Brand Portal`;

  return (
    <>
      <Box
        as={'main'}
        maxWidth={size.contentMaxWidth}
        px={{ base: space.xSmall, lg: space.medium, xl: space.xLarge }}
      >
        <Box pt={{ base: space.small, lg: space.xLarge }}>
          <HeadingComponent as="h1" mb={1} variant="tertiary">
            {PAGES.AUDIENCE_SEGMENTATION.title}
          </HeadingComponent>

          <ProfessionalHires />

          <EarlyCareers />
        </Box>
      </Box>

      <PageFooterComponent
        nextPageHref={PAGES.BRAND_NEW.path}
        nextPageName={PAGES.BRAND_NEW.title}
      />
    </>
  );
};

export default EmployerValueProposition;
