import { Box } from '@chakra-ui/react';

import { AnchorNavigationLinkComponent } from 'components/AnchorNavigationLink';
import { PAGES } from 'constants/pages';
import { SECTIONS } from 'constants/sections';

type Props = {
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
};

export const EmployerValueProposition = ({ setOpen }: Props) => {
  return (
    <Box as="ul" listStyleType="none">
      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.OVERVIEW.anchor}
          href={`${PAGES.EMPLOYER_VALUE_PROPOSITION.path}#${SECTIONS.OVERVIEW.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.OVERVIEW.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.THREE_CORE_PILLARS.anchor}
          href={`${PAGES.EMPLOYER_VALUE_PROPOSITION.path}#${SECTIONS.THREE_CORE_PILLARS.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.THREE_CORE_PILLARS.title}
        </AnchorNavigationLinkComponent>
      </Box>
    </Box>
  );
};
