import * as React from 'react';

import { Box } from '@chakra-ui/react';

import { HeadingComponent } from 'components/Heading';
import { PageFooterComponent } from 'components/PageFooter';
import { PAGES } from 'constants/pages';
import { Characteristics } from 'sections/Characteristics';
import { Claim } from 'sections/Claim';
import { CoreValues } from 'sections/CoreValues';
import { KeyMessages } from 'sections/KeyMessages';
import { OurBrandExperiece } from 'sections/OurBrandExperience';
import { StrategicFramework } from 'sections/StrategicFramework';
import { size, space } from 'theme/constants';

const BrandIdentityPage: React.FC = () => {
  document.title = `${PAGES.BRAND_IDENTITY.title} | DWS Brand Portal`;

  return (
    <>
      <Box
        as="main"
        maxWidth={size.contentMaxWidth}
        px={{ base: space.xSmall, lg: space.medium, xl: space.xLarge }}
      >
        <Box pt={{ base: space.small, lg: space.xLarge }}>
          <HeadingComponent as="h1" mb={1} variant="tertiary">
            {PAGES.BRAND_IDENTITY.title}
          </HeadingComponent>

          <OurBrandExperiece mb={space.large} />
          <CoreValues />

          <Box
            mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
            px={{ lg: space.medium, xl: space.xLarge }}
            bgColor="background.black"
          >
            <Characteristics mb={space.large} />
          </Box>

          <KeyMessages mb={space.xLarge} />
          <Claim
            mb={space.large}
            py={{ base: space.small, lg: space.large }}
            bgImage={{
              lg: `url('images/ClaimBackground.png')`,
            }}
            bgPosition={{
              lg: '125% -15%',
              xl: '125% -125%',
            }}
            bgRepeat={{
              lg: 'no-repeat',
            }}
            bgSize={{
              lg: '50%',
            }}
          />
          <StrategicFramework mb={space.large} />
        </Box>
      </Box>

      <PageFooterComponent
        nextPageHref={PAGES.BRAND_ESSENTIALS_AND_GUIDES.path}
        nextPageName={PAGES.BRAND_ESSENTIALS_AND_GUIDES.title}
      />
    </>
  );
};

export default BrandIdentityPage;
