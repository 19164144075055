import { Icon, IconProps } from '@chakra-ui/react';

const CheckmarkBoxIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 60 46" aria-hidden="true" fill="none" {...props}>
      <path d="M41.5739 12.2192c-5.0717 6.2591-10.1434 12.5182-14.9168 18.7773-2.0883-2.3844-3.8783-5.0669-5.9667-7.4513l-.5966-.5961V27.7179l6.265 7.7494.2983.298.2984-.298 15.215-19.0754V11.623l-.5966.5962z" />
      <path
        fillRule="evenodd"
        d="M59 1H1v44h58V1zm1-1v46H0V0h60z"
        clipRule="evenodd"
      />
    </Icon>
  );
};

export default CheckmarkBoxIcon;
