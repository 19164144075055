import { Box, Flex } from '@chakra-ui/react';

import { TextComponent } from 'components/Text';
import { space } from 'theme/constants';

type Props = {
  date: string;
  dateTime: string;
  imgAlt: string;
  imgSrc: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  children: React.ReactNode;
};

export const NewsComponent = ({
  date,
  dateTime,
  imgAlt,
  imgSrc,
  children,
}: Props) => {
  return (
    <Box>
      <Flex
        align="center"
        mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.small}
        _after={{
          content: "''",
          flex: '1',
          width: '100%',
          height: '1px',
          bgColor: 'background.highlight',
        }}
      >
        <Box
          as="time"
          dateTime={dateTime}
          px={4}
          py={2}
          color="text.primaryLight"
          bgColor="background.highlight"
        >
          <TextComponent as="span" variant="tiny" display="block">
            {date}
          </TextComponent>
        </Box>
      </Flex>

      <Box
        mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.xSmall}
      >
        <img src={imgSrc} alt={imgAlt} />
      </Box>

      <TextComponent variant="medium">{children}</TextComponent>
    </Box>
  );
};
