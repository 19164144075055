import { Box } from '@chakra-ui/react';

import { AnchorNavigationLinkComponent } from 'components/AnchorNavigationLink';
import { PAGES } from 'constants/pages';
import { SECTIONS } from 'constants/sections';

type Props = {
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
};

export const EmployerBrand = ({ setOpen }: Props) => {
  return (
    <Box as="ul" listStyleType="none">
      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.INTRODUCTION_EMPLOYER_BRAND.anchor}
          href={`${PAGES.EMPLOYER_BRAND.path}#${SECTIONS.INTRODUCTION_EMPLOYER_BRAND.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.INTRODUCTION_EMPLOYER_BRAND.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.TONE_OF_VOICE.anchor}
          href={`${PAGES.EMPLOYER_BRAND.path}#${SECTIONS.TONE_OF_VOICE.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.TONE_OF_VOICE.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.EMPLOYER_BRAND_CLIM.anchor}
          href={`${PAGES.EMPLOYER_BRAND.path}#${SECTIONS.EMPLOYER_BRAND_CLIM.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.EMPLOYER_BRAND_CLIM.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.COMPANY_PROFILES.anchor}
          href={`${PAGES.EMPLOYER_BRAND.path}#${SECTIONS.COMPANY_PROFILES.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.COMPANY_PROFILES.title}
        </AnchorNavigationLinkComponent>
      </Box>
    </Box>
  );
};
