import * as React from 'react';

import { Box, Grid, GridItem, SimpleGrid } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { SECTIONS } from 'constants/sections';

import { CardComponent } from '../../components/Card';
import { HeadingComponent } from '../../components/Heading';
import { ImageGalleryComponent } from '../../components/ImageGallery';
import { TextComponent } from '../../components/Text';
import { FIDUCARY_PETROL } from '../../theme';
import { space } from '../../theme/constants';
import { ProfessionalHiresImages } from './ImageGalleryItems';

export const ProfessionalHires = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.PROFESSIONAL_HIRES.anchor}
      title={SECTIONS.PROFESSIONAL_HIRES.title}
      {...props}
    >
      <Box
        px={{ base: space.xSmall, lg: space.medium, xl: space.xLarge }}
        mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.xLarge}
      >
        <Box>
          <TextComponent variant={'medium'} mb={space.medium}>
            This audience consists of people who are already working within the
            financial, investment and asset management industries. They engage
            with messaging focused on transformation, social responsibility,
            collaboration, and growth.
          </TextComponent>

          <Box
            mb={space.medium}
            mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
          >
            <ImageGalleryComponent items={ProfessionalHiresImages} />
          </Box>

          <TextComponent variant={'medium'} mb={space.medium}>
            For professional hires, use bold imagery as the background of
            communcations and leverage the solid slash to hold copy and create a
            sense of depth. When placing an image in the solid slash, try to use
            white as the background colour. This gives communications a light
            and open feel.
          </TextComponent>

          <SimpleGrid
            columns={[1, 2]}
            pl={{ lg: space.medium, xl: space.xLarge }}
            pr={{ lg: space.medium, xl: 0 }}
            mb={space.medium}
            spacing={0}
            marginRight={{ sm: '20%', xl: '40%' }}
          >
            <Box textAlign={['center', 'right']} pr={[0, space.small]}>
              <HeadingComponent variant={'primary'} color={FIDUCARY_PETROL}>
                Headlines
              </HeadingComponent>
            </Box>

            <Box minW={'300px'}>
              <TextComponent variant={'medium'}>
                – Shape our transformation.
                <br />
                <br />
                – Drive our journey of innovation.
                <br />
                <br />
                – Work at the heart of sustainable investing.
                <br />
                <br />
                – Strive for a better world.
                <br />
                <br />
                – Think bigger, think global.
                <br />
                <br />– Thrive at the forefront of change.
              </TextComponent>
            </Box>
          </SimpleGrid>

          <Grid
            templateColumns={{ base: '1fr', md: '1fr 1fr' }}
            gap={space.xSmall}
            mb={space.medium}
            mr={{ lg: `${space.medium}`, xl: `${space.xLarge}` }}
          >
            <GridItem display="flex" flexDirection="column">
              <HeadingComponent variant={'secondary'} mb={space.xSmall}>
                Offline assets
              </HeadingComponent>
              <CardComponent
                file="https://download.dws.com/download?elib-assetguid=522393f6e5654d5fa833f2e7c496d8df"
                fileType="Zip"
                fileSize="10.5 MB"
                headingLevel="h4"
                imgAlt="DWS fonts version"
                imgSrc="professional_hires_offline_mockup_2.jpg"
                isCompact
                title="Download offline assets"
              />
            </GridItem>

            <GridItem display="flex" flexDirection="column">
              <HeadingComponent variant={'secondary'} mb={space.xSmall}>
                Digital assets
              </HeadingComponent>
              <CardComponent
                file="https://download.dws.com/download?elib-assetguid=f615fc8fd94449a49415cc7f1a5c8e8b"
                fileType="Zip"
                fileSize="10.5 MB"
                headingLevel="h4"
                imgAlt="MS Office system fonts version"
                imgSrc="professional_hires_digital_mockup_2.jpg"
                isCompact
                title="Download digital assets"
              />
            </GridItem>
          </Grid>
        </Box>
      </Box>
    </ContentSectionComponent>
  );
};
