import { Box } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { SECTIONS } from 'constants/sections';

import {
  TabComponent,
  TabListComponent,
  TabPanelComponent,
  TabPanelsComponent,
  TabsComponent,
} from '../../components/Tabs';
import { TextComponent } from '../../components/Text';
import { space, zIndex } from '../../theme/constants';

export const ThreeCorePillars = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.THREE_CORE_PILLARS.anchor}
      title={SECTIONS.THREE_CORE_PILLARS.title}
      {...props}
    >
      <TextComponent variant={'medium'} mb={space.medium}>
        Each of our three pillars have been created by our colleagues at DWS.
        They are a compelling offer to the people we know will succeed with us.
        But they also set out our expectations. Not every pillar will appeal to
        every candidate equally and you should use your own knowledge and
        experience to dial up the ones you think will be most effective for your
        message and audience. Every interaction we have with our audience should
        speak to at least one of these pillars.
        <br />
        <br />
        The key to this messaging being successfully communicated is
        consistency. If we are consistently drawing on the framework in every
        interaction with people and candidates – we can be confident they will
        understand what it means to continue to work here, or to pursue
        whichever opportunity is relevant.
      </TextComponent>

      <TabsComponent mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}>
        <TabListComponent>
          <TabComponent
            _selected={{
              borderBottomColor: 'context.clientCommitment',
              color: 'context.clientCommitment',
            }}
            _focus={{
              boxShadow: 'none',
              color: 'context.clientCommitment',
              bgColor: 'background.nearWhite',
            }}
            _hover={{ color: 'context.clientCommitment' }}
          >
            Client Commitment
          </TabComponent>

          <TabComponent
            _selected={{
              borderBottomColor: 'context.entrepreneurialSpirit',
              color: 'context.entrepreneurialSpirit',
            }}
            _focus={{
              boxShadow: 'none',
              color: 'context.entrepreneurialSpirit',
              bgColor: 'background.nearWhite',
            }}
            _hover={{ color: 'context.entrepreneurialSpirit' }}
          >
            Entrepreneurial Spirit
          </TabComponent>

          <TabComponent
            _selected={{
              borderBottomColor: 'context.sustainableAction',
              color: 'context.sustainableAction',
            }}
            _focus={{
              boxShadow: 'none',
              color: 'context.sustainableAction',
              bgColor: 'background.nearWhite',
            }}
            _hover={{ color: 'context.sustainableAction' }}
          >
            Sustainable Action
          </TabComponent>
        </TabListComponent>

        <TabPanelsComponent>
          <TabPanelComponent>
            <Box
              position="relative"
              _before={{
                content: "''",
                position: 'absolute',
                zIndex: zIndex.default,
                top: '0',
                bottom: '-40px',
                width: '12px',
                bgColor: 'context.clientCommitment',
              }}
            >
              <Box mb={space.large}>
                <img
                  src={process.env.PUBLIC_URL + '/images/client_comm.jpg'}
                  alt="A man who is looking at a sunset"
                />
              </Box>

              <Box mb={space.medium} px={space.large}>
                <TextComponent variant={'medium'}>
                  We are a leading, client-focused, global Asset Manager with a
                  strong presence in Europe. We provide deep market research,
                  expert portfolio management, and excellent quality of service.
                  We’re one of the few Asset Management firms that can offer a
                  range of industry-leading products across a globally unified
                  investment platform delivering the right solutions whatever
                  challenges the markets poses. This combination of breadth and
                  quality gives you the flexibility to develop your skills and
                  build new capabilities. Working side by side with some of the
                  industry’s best-known thought leaders, who are always
                  approachable and always willing to share ideas and listen to
                  suggestions, you can grow your career with us.
                </TextComponent>
              </Box>
            </Box>
          </TabPanelComponent>

          <TabPanelComponent>
            <Box
              position="relative"
              _before={{
                content: "''",
                position: 'absolute',
                zIndex: zIndex.default,
                top: '0',
                bottom: '-40px',
                width: '12px',
                bgColor: 'context.entrepreneurialSpirit',
              }}
            >
              <Box mb={space.large}>
                <img
                  src={process.env.PUBLIC_URL + '/images/entre_spirit.jpg'}
                  alt="A woman in front of some buildings."
                />
              </Box>

              <Box mb={space.medium} px={space.large}>
                <TextComponent variant={'medium'}>
                  We’re evolving and leading ambitious programmes of change
                  allowing us to capture the opportunities of tomorrow. With
                  change comes variety, responsibility, and challenging,
                  satisfying work. At DWS, you’ll be a part of this journey and
                  have the opportunity to share in and shape our transformation.
                  <br />
                  <br />
                  We’re committed team players, working together wherever we
                  can, trusting and supporting one another across divisions and
                  regions. We’ll encourage your input and value your creativity
                  to develop innovative solutions. Our structure enables you to
                  explore different roles, develop new skills, and achieve your
                  goals, whether you want to focus on a particular specialism or
                  manage others.
                </TextComponent>
              </Box>
            </Box>
          </TabPanelComponent>

          <TabPanelComponent>
            <Box
              position="relative"
              _before={{
                content: "''",
                position: 'absolute',
                zIndex: zIndex.default,
                top: '0',
                bottom: '-40px',
                width: '12px',
                bgColor: 'context.sustainableAction',
              }}
            >
              <Box mb={space.large}>
                <img
                  src={
                    process.env.PUBLIC_URL + '/images/sustainable_action.jpg'
                  }
                  alt="A girl standing in front of an aquarium."
                />
              </Box>

              <Box mb={space.medium} px={space.large}>
                <TextComponent variant={'medium'}>
                  Environmentally sustainable and socially responsible investing
                  is a key component to our mission. It means we’re committed to
                  serving our clients with empathy and integrity, working with
                  them to build the best, most sustainable foundation for their
                  future plans. We’re dedicated to supporting local communities
                  and the environment through our Corporate Social
                  Responsibility efforts. And we’re serious about diversity,
                  equity, and creating an inclusive culture where colleagues can
                  be themselves. Our advice and expertise can direct vast sums,
                  influencing markets and behaviours for the better. The things
                  we do can have a huge impact on individuals, communities, and
                  the world.
                </TextComponent>
              </Box>
            </Box>
          </TabPanelComponent>
        </TabPanelsComponent>
      </TabsComponent>
    </ContentSectionComponent>
  );
};
