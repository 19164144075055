import React from 'react';

import { Box } from '@chakra-ui/react';

import { HeadingComponent } from '../components/Heading';
import { PageFooterComponent } from '../components/PageFooter';
import { PAGES } from '../constants/pages';
import { EmployerIntroduction } from '../sections/EmployerIntroduction';
import { size, space } from '../theme/constants';

const Introduction: React.FC = () => {
  document.title = `${PAGES.INTRODUCTION.title} | DWS Brand Portal`;

  return (
    <>
      <Box
        as={'main'}
        maxWidth={size.contentMaxWidth}
        px={{ base: space.xSmall, lg: space.medium, xl: space.xLarge }}
      >
        <Box pt={{ base: space.small, lg: space.xLarge }}>
          <HeadingComponent as="h1" mb={1} variant="tertiary">
            {PAGES.INTRODUCTION.title}
          </HeadingComponent>

          <EmployerIntroduction
            date={'February 2023'}
            dateTime={'February 2023'}
          />
        </Box>
      </Box>

      <PageFooterComponent
        nextPageHref={PAGES.EMPLOYER_VALUE_PROPOSITION.path}
        nextPageName={PAGES.EMPLOYER_VALUE_PROPOSITION.title}
      />
    </>
  );
};

export default Introduction;
