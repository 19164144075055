import { Icon, IconProps } from '@chakra-ui/react';

const ArrowRightIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path d="M8.6585 19.7526l-1.31701-1.5052L14.4814 12 7.34149 5.75256 8.6585 4.24741 17.5186 12l-8.8601 7.7526z" />
    </Icon>
  );
};

export default ArrowRightIcon;
