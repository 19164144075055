import { BoxProps, Flex } from '@chakra-ui/react';
import { BsLink45Deg } from 'react-icons/bs';

const LinkIcon = (props: BoxProps) => {
  return (
    <Flex
      w={'64px'}
      h={'64px'}
      borderWidth={2}
      borderRadius={'100px'}
      borderStyle={'solid'}
      justifyContent={'center'}
      alignItems={'center'}
      {...props}
    >
      <BsLink45Deg size={'32px'} />
    </Flex>
  );
};

export default LinkIcon;
