import { Box, VStack } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { DownloadFileComponent } from 'components/DownloadFile';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

export const Stationery = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.STATIONERY.anchor}
      title={SECTIONS.STATIONERY.title}
      {...props}
    >
      <Box
        mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.small}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/Stationery.jpg'}
          alt="Different stationay items (letter, business card etc.)"
        />
      </Box>

      <VStack align="stretch" spacing={2}>
        <DownloadFileComponent
          file="https://download.dws.com/download?elib-assetguid=9d8f2a45cdf94f4f9c2d5cccd42200a0"
          fileType="Zip"
          fileSize="10.5 MB"
        >
          Stationery Germany
        </DownloadFileComponent>

        <DownloadFileComponent
          file="https://download.dws.com/download?elib-assetguid=7130404a1a0241c99bee7fe24b984c41"
          fileType="Zip"
          fileSize="10.5 MB"
        >
          Stationery USA
        </DownloadFileComponent>

        <DownloadFileComponent
          file="https://download.dws.com/download?elib-assetguid=d3a0068b30d04911a30f76887eed5a26"
          fileType="Zip"
          fileSize="10.5 MB"
        >
          Stationery UK
        </DownloadFileComponent>

        <DownloadFileComponent
          file="https://download.dws.com/download?elib-assetguid=fa8302d267744e3e92f039851af7b8c9"
          fileType="Zip"
          fileSize="10.5 MB"
        >
          Stationery Luxembourg
        </DownloadFileComponent>
        <DownloadFileComponent
          file="https://download.dws.com/download?elib-assetguid=adfe3722096845719e2b553eb4b90e38"
          fileType="Zip"
          fileSize="10.5 MB"
        >
          Stationery Switzerland
        </DownloadFileComponent>
        <DownloadFileComponent
          file="https://download.dws.com/download?elib-assetguid=3e986a2eece94388809b90686db7f182"
          fileType="Zip"
          fileSize="10.5 MB"
        >
          Stationery India
        </DownloadFileComponent>

        <DownloadFileComponent
          file="https://download.dws.com/download?elib-assetguid=be03b5478d1b4368b6bf29c7743e84d5"
          fileType="Zip"
          fileSize="10.5 MB"
        >
          Stationery quickguide
        </DownloadFileComponent>
      </VStack>
    </ContentSectionComponent>
  );
};
