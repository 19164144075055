import { Icon, IconProps } from '@chakra-ui/react';

const ArrowLeftIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" aria-hidden="true" fill="currentColor" {...props}>
      <path d="M15.3415 4.24744l1.317 1.50515L9.51859 12l7.13991 6.2474-1.317 1.5052L6.48141 12l8.86009-7.75256z" />
    </Icon>
  );
};

export default ArrowLeftIcon;
