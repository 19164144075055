import { Box, VStack } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { DownloadFileComponent } from 'components/DownloadFile';
import { HeadingComponent } from 'components/Heading';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

export const Sponsoreship = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.SPONSORSHIP.anchor}
      title={SECTIONS.SPONSORSHIP.title}
      {...props}
    >
      <HeadingComponent as="h3" variant="secondary" mb={space.xxSmall}>
        The Los Angeles Lakers
      </HeadingComponent>

      <Box
        mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.small}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/DWSPartnerLakers.jpg'}
          alt="A Basketball Stadium which shows the cooperation between the DWS and the Los Angeles Lakers "
        />
      </Box>

      <VStack align="stretch" spacing={2} mb={space.large}>
        <DownloadFileComponent
          file="https://download.dws.com/download?elib-assetguid=76e77c09df864f47a56c204400428ab6"
          fileType="Zip"
          fileSize="1.3 MB"
        >
          DWS x Lakers logo files
        </DownloadFileComponent>

        <DownloadFileComponent
          file="https://download.dws.com/download?elib-assetguid=1e80f0c7001f404bbe5454b09b4bacf8"
          fileType="Zip"
          fileSize="1.3 MB"
        >
          DWS x Lakers materials
        </DownloadFileComponent>

        <DownloadFileComponent
          file="https://download.dws.com/download?elib-assetguid=574ea8a7503a4f3c9372a8eefdced910"
          fileType="Zip"
          fileSize="10.5 MB"
        >
          DWS x Lakers quickguide
        </DownloadFileComponent>
      </VStack>
    </ContentSectionComponent>
  );
};
