import { Box, SimpleGrid, VStack } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { DownloadFileComponent } from 'components/DownloadFile';
import { HeadingComponent } from 'components/Heading';
import { TextComponent } from 'components/Text';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

export const DesignSystem = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.DESIGN_SYSTEM.anchor}
      title={SECTIONS.DESIGN_SYSTEM.title}
      {...props}
    >
      <TextComponent limitedWidth mb={space.small}>
        Once again, the concept originates from the DWS logo slashes, which can
        now be combined with an extended, defined framework. It allows three
        basic application options which can be used modularly.
      </TextComponent>

      <VStack align="stretch" spacing={2} mb={space.large}>
        <DownloadFileComponent
          file={
            'https://download.dws.com/download?elib-assetguid=bd1b9ca450ea4a26ad23d2e1239c2850'
          }
          fileType="Zip"
          fileSize="543 KB"
        >
          Slash elements
        </DownloadFileComponent>

        <DownloadFileComponent
          file={
            'https://download.dws.com/download?elib-assetguid=ce385d7a89764210b4dabab2bf84721a'
          }
          fileType="Zip"
          fileSize="543 KB"
        >
          DWS design system quickguide
        </DownloadFileComponent>
      </VStack>

      <Box
        mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.small}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/DesignSystemElements.png'}
          alt="How to use the corporate slash: 01 Solid Slash, 02 Diagonal Line Slash, 03 Outline Slash"
        />
      </Box>

      <HeadingComponent as="h3" variant="secondary" mb={2}>
        Application examples
      </HeadingComponent>

      <HeadingComponent as="h4" variant="tertiary" mb={space.xSmall}>
        Basic application
      </HeadingComponent>

      <Box mb={space.large}>
        <img
          src={process.env.PUBLIC_URL + '/images/BasicApplication.png'}
          alt="How to use the corporate slash in three steps"
        />
        <SimpleGrid columns={3} spacing={3} mt={space.xSmall}>
          <Box>
            <strong>01</strong>
            <br />
            Solid slashes are limited to a maximum of four, …
          </Box>
          <Box>
            <strong>02</strong>
            <br />… have to be placed on the document margins
          </Box>
          <Box>
            <strong>03</strong>
            <br />… and have to touch at least one other solid slash.
          </Box>
        </SimpleGrid>
      </Box>

      <HeadingComponent as="h4" variant="tertiary" mb={space.xSmall}>
        Optional application
      </HeadingComponent>

      <Box mb={space.large}>
        <img
          src={process.env.PUBLIC_URL + '/images/OptionalApplication.png'}
          alt="The corporate slash combined with visuals, color highlights and gradients"
        />
        <SimpleGrid columns={3} spacing={3} mt={space.xSmall}>
          <Box>
            <strong>04</strong>
            <br />
            Visuals can be added according to the imagery principles.
          </Box>
          <Box>
            <strong>05</strong>
            <br />
            Color highlights can be applied according to the color principles.
          </Box>
          <Box>
            <strong>06</strong>
            <br />
            Gradient feather can be applied gently to add depth.
          </Box>
        </SimpleGrid>
      </Box>

      <HeadingComponent as="h4" variant="tertiary" mb={space.xSmall}>
        Advanced application
      </HeadingComponent>

      <Box>
        <img
          src={process.env.PUBLIC_URL + '/images/AdvancedApplication.png'}
          alt="The corporate slash combined with line patterns and as the outline variant"
        />
        <SimpleGrid columns={3} spacing={3} mt={space.xSmall}>
          <Box>
            <strong>07</strong>
            <br />
            Line patterns can be added.
          </Box>
          <Box>
            <strong>08</strong>
            <br />
            The outline slash can be used.
          </Box>
        </SimpleGrid>
      </Box>
    </ContentSectionComponent>
  );
};
