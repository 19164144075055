import { useEffect, useState } from 'react';

import { Link, LinkProps } from '@chakra-ui/react';

import { useScrollSpy } from '../../hooks/useScrollSpyHook';

type Props = {
  anchor?: string;
  children: string;
  href: string;
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
};

const baseStyle: LinkProps = {
  display: 'block',
  py: '12px',

  _focus: {
    textDecoration: 'none',
    color: 'text.highlight',
  },

  _hover: {
    textDecoration: 'none',
    color: 'text.highlight',
  },

  _active: {
    transform: 'translateX(5px)',
  },
};

export const AnchorNavigationLinkComponent = ({
  anchor,
  children,
  href,
  setOpen,
  ...props
}: Props) => {
  const navigationContext = useScrollSpy();
  const [isActive, setActive] = useState(false);

  const handleLinkClick = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (anchor) {
      const active = navigationContext.activeItems.indexOf(anchor) !== -1;
      setActive(active);
    }
  }, [navigationContext.activeItems]);

  return (
    <Link
      href={href}
      onClick={handleLinkClick}
      sx={{
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
      }}
      {...baseStyle}
      {...(isActive && { fontWeight: '700' })}
      {...props}
    >
      {children}
    </Link>
  );
};
