import * as React from 'react';

import { Button, ButtonProps } from '@chakra-ui/react';

import { transition } from 'theme/constants';

type VariantType = 'primary' | 'secondary' | 'link' | 'imageWrapper';

type Props = {
  children: React.ReactNode;
  href?: string;
  variant?: VariantType;
} & Pick<ButtonProps, 'as' | 'onClick' | 'type'>;

const baseStyle: ButtonProps = {
  position: 'relative',
  minWidth: '0',
  height: 'auto',
  borderRadius: '0',
  fontSize: '18px',
  fontWeight: '700',
  lineHeight: '1.33333333',
  textDecoration: 'none',
  transition: `transform ${transition.default}, background-color ${transition.default}`,
};

const usePrimary = (): ButtonProps => {
  return {
    mr: '51px',
    py: '18px',
    pr: '16px',
    pl: '30px',
    color: 'text.primaryLight',
    bgColor: 'background.highlight',

    _after: {
      content: "''",
      position: 'absolute',
      top: '0',
      right: '-51px',
      width: '0',
      height: '0',
      borderTop: '60px solid',
      borderTopColor: 'border.highlight',
      borderRight: '51px solid transparent',
      transition: `border-top-color ${transition.default}`,
    },

    _focus: {
      bgColor: 'background.highlightHover',

      _after: {
        borderTopColor: 'border.highlightHover',
      },
    },

    _hover: {
      bgColor: 'background.highlightHover',

      _after: {
        borderTopColor: 'border.highlightHover',
      },
    },

    _active: {
      transform: 'translateX(2px)',
    },

    _disabled: {
      opacity: '1',
      color: 'text.secondaryLight',
      bgColor: 'background.nearWhite',
      cursor: 'auto',

      _after: {
        borderTopColor: 'border.nearWhite',
      },

      _hover: {
        bgColor: 'background.nearWhite',

        _after: {
          borderTopColor: 'border.nearWhite',
        },

        _active: {
          transform: 'none',
        },
      },
    },
  };
};

const useSecondary = (): ButtonProps => {
  return {
    py: '18px',
    px: '30px',
    color: 'text.primaryDark',
    bgColor: 'background.nearWhite',

    _focus: {
      bgColor: 'background.neutral',
    },

    _hover: {
      bgColor: 'background.neutral',
    },

    _active: {
      transform: 'translateY(2px)',
    },

    _disabled: {
      opacity: '1',
      color: 'text.secondaryLight',
      bgColor: 'background.nearWhite',
      cursor: 'auto',

      _hover: {
        bgColor: 'background.nearWhite',

        _active: {
          transform: 'none',
        },
      },
    },
  };
};

const useLink = (): ButtonProps => {
  return {
    px: '0',
    py: '0',
    color: 'currentColor',
    bgColor: 'transparent',

    _focus: {
      textDecoration: 'underline',
    },

    _hover: {
      textDecoration: 'underline',
    },

    _active: {
      textDecoration: 'none',
    },

    _disabled: {
      color: 'inherit',

      _hover: {
        textDecoration: 'inherit',
        color: 'inherit',
      },
    },
  };
};

const useImageWrapper = (): ButtonProps => {
  return {
    px: '0',
    py: '0',
    color: 'currentColor',
    bgColor: 'transparent',

    _focus: {
      outline: '4px solid',
      outlineColor: 'border.highlight',
      outlineOffset: '4px',
    },

    _hover: {
      outline: '4px solid',
      outlineColor: 'border.highlight',
      outlineOffset: '4px',
    },
  };
};

const useButtonVariants = (): Record<VariantType, ButtonProps> => {
  return {
    primary: usePrimary(),
    secondary: useSecondary(),
    link: useLink(),
    imageWrapper: useImageWrapper(),
  };
};

export const ButtonComponent = ({
  children,
  href,
  variant = 'primary',
  ...props
}: Props) => {
  const variantStyles = useButtonVariants()[variant];

  const styles = {
    ...baseStyle,
    ...variantStyles,
    ...props,
  };

  return (
    <Button
      href={href}
      sx={{
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
      }}
      {...styles}
    >
      {children}
    </Button>
  );
};
