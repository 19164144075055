import { Box } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { HeadingComponent } from 'components/Heading';
import { TextComponent } from 'components/Text';
import { PAGES } from 'constants/pages';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

export const ColorSystem = () => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.COLOR_SYSTEM.anchor}
      moreDetailsUrl={`${PAGES.BRAND_ESSENTIALS_AND_GUIDES.path}#${SECTIONS.COLORS.anchor}`}
      title={SECTIONS.COLOR_SYSTEM.title}
    >
      <TextComponent mb={space.large}>
        We refined our entire color system, where every single shade tells a
        vivid and vibrant story that corresponds to our brand values, increases
        diversity and, most importantly, ensures viewability on dark and bright
        backgrounds.
      </TextComponent>

      <HeadingComponent as="h3" variant="secondary" mb={space.small}>
        Primary colors
      </HeadingComponent>

      <Box mb={space.large}>
        <img
          src={process.env.PUBLIC_URL + '/images/PrimaryColors.png'}
          alt="Primaries: three context related and six black & white color swatches"
        />
      </Box>

      <HeadingComponent as="h3" variant="secondary" mb={space.small}>
        Secondary colors
      </HeadingComponent>

      <Box mb={space.large}>
        <img
          src={process.env.PUBLIC_URL + '/images/SecondaryColors.png'}
          alt="Secondaries: five color swatches, Tertiares: three color swatches"
        />
      </Box>

      <HeadingComponent as="h3" variant="secondary" mb={space.small}>
        Tints & shades
      </HeadingComponent>

      <Box>
        <img
          src={process.env.PUBLIC_URL + '/images/TintShades.png'}
          alt="Six plus ten tint & shades color swatches, three alert & signals color swatches, four extensions color swatches"
        />
      </Box>
    </ContentSectionComponent>
  );
};
