import { Divider } from '@chakra-ui/react';

import { space } from 'theme/constants';

export const DividerComponent = () => {
  return (
    <Divider
      opacity="1"
      borderColor="border.nearWhite"
      my={{ base: space.medium, md: space.xLarge }}
    />
  );
};
