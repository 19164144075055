import * as React from 'react';

import { Text, TextProps } from '@chakra-ui/react';

import { size } from 'theme/constants';

export type VariantType = 'tiny' | 'small' | 'medium' | 'large' | 'claim';

type Props = {
  children: React.ReactNode;
  limitedWidth?: boolean;
  variant?: VariantType;
} & Pick<
  TextProps,
  | 'as'
  | 'color'
  | 'display'
  | 'fontWeight'
  | 'mb'
  | 'sx'
  | 'textAlign'
  | 'textTransform'
  | 'maxWidth'
  | 'w'
  | 'fontFamily'
>;

const tiny: TextProps = {
  fontFamily: "'DWS Slab', Arial, sans-serif",
  fontSize: '12px',
  fontWeight: '700',
  lineHeight: '1.5',
};

const small: TextProps = {
  fontSize: '16px',
  lineHeight: '1.5',
};

const medium: TextProps = {
  fontSize: '22px',
  fontWeight: '300',
  lineHeight: '1.63636363',
};

const large: TextProps = {
  fontFamily: "'DWS Slab', Arial, sans-serif",
  fontSize: '27px',
  lineHeight: '1.22222222',
};

const claim: TextProps = {
  fontFamily: "'DWS Slab', Arial, sans-serif",
  fontSize: '60px',
  lineHeight: '1.22222222',
};

const textVariants: Record<VariantType, TextProps> = {
  tiny,
  small,
  medium,
  large,
  claim,
};

export const TextComponent = ({
  children,
  limitedWidth,
  variant = 'small',
  ...props
}: Props) => {
  const variantStyles = textVariants[variant];

  return (
    <Text
      maxWidth={size.textMaxWidth}
      {...(limitedWidth && {
        maxWidth: { base: size.textLimitedWidth, xl: size.textMaxWidth },
      })}
      {...variantStyles}
      {...props}
    >
      {children}
    </Text>
  );
};
