import { ContentSectionComponent } from 'components/ContentSection';
import { ImageGalleryComponent } from 'components/ImageGallery';
import { LinkBar } from 'components/LinkBar';
import {
  TabComponent,
  TabListComponent,
  TabPanelComponent,
  TabPanelsComponent,
  TabsComponent,
} from 'components/Tabs';
import { TextComponent } from 'components/Text';
import { PAGES } from 'constants/pages';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

import {
  entrepreneurialSpirit,
  clientCommitment,
  sustainableAction,
} from './ImageGalleryItems';

export const ImageryVisualExperience = () => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.IMAGERY_VISUAL_EXPERIENCE.anchor}
      moreDetailsUrl={`${PAGES.BRAND_ESSENTIALS_AND_GUIDES.path}#${SECTIONS.IMAGERY.anchor}`}
      title={SECTIONS.IMAGERY_VISUAL_EXPERIENCE.title}
    >
      <TabsComponent
        mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.small}
      >
        <TabListComponent>
          <TabComponent
            _selected={{
              borderBottomColor: 'context.clientCommitment',
              color: 'context.clientCommitment',
            }}
            _focus={{
              boxShadow: 'none',
              color: 'context.clientCommitment',
              bgColor: 'background.nearWhite',
            }}
            _hover={{ color: 'context.clientCommitment' }}
          >
            Client Commitment
          </TabComponent>

          <TabComponent
            _selected={{
              borderBottomColor: 'context.entrepreneurialSpirit',
              color: 'context.entrepreneurialSpirit',
            }}
            _focus={{
              boxShadow: 'none',
              color: 'context.entrepreneurialSpirit',
              bgColor: 'background.nearWhite',
            }}
            _hover={{ color: 'context.entrepreneurialSpirit' }}
          >
            Entrepreneurial Spirit
          </TabComponent>

          <TabComponent
            _selected={{
              borderBottomColor: 'context.sustainableAction',
              color: 'context.sustainableAction',
            }}
            _focus={{
              boxShadow: 'none',
              color: 'context.sustainableAction',
              bgColor: 'background.nearWhite',
            }}
            _hover={{ color: 'context.sustainableAction' }}
          >
            Sustainable Action
          </TabComponent>
        </TabListComponent>

        <TabPanelsComponent>
          <TabPanelComponent>
            <ImageGalleryComponent items={clientCommitment} />
          </TabPanelComponent>
          <TabPanelComponent>
            <ImageGalleryComponent items={entrepreneurialSpirit} />
          </TabPanelComponent>
          <TabPanelComponent>
            <ImageGalleryComponent items={sustainableAction} />
          </TabPanelComponent>
        </TabPanelsComponent>
      </TabsComponent>

      <TextComponent mb={space.small}>
        The new DWS visual experience opens up more possibilities and creative
        freedom. Our imagery is no longer static, dry/factual, or complex.
        Restrictions such as division, strict image categories, convoluted
        compositions and logo masks are no longer in play.
      </TextComponent>

      <LinkBar link={'https://mediamanager.dws.com/dws'}>
        DWS Media Manager (Library)
      </LinkBar>
    </ContentSectionComponent>
  );
};
