import * as React from 'react';

import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { ScrollSpyProvider } from 'hooks/useScrollSpyHook';
import { DefaultLayout } from 'layouts/default';

type ProtectedRouteProps = {
  children: React.ReactNode;
};

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const cookies = new Cookies();
  const authCookie = cookies.get('passwordAuth');

  if (authCookie) {
    return (
      <ScrollSpyProvider>
        <DefaultLayout>{children}</DefaultLayout>
      </ScrollSpyProvider>
    );
  }

  return <Redirect to={'/'} />;
};
