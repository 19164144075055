import { Box, VStack } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { DownloadFileComponent } from 'components/DownloadFile';
import { HeadingComponent } from 'components/Heading';
import { TextComponent } from 'components/Text';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

import { AlertSignals } from './AlertSignals';
import { BlackWhiteTones } from './BlackWhiteTones';
import { ContextRelated } from './ContextRelated';
import { Extensions } from './Extensions';
import { Secondaries } from './Secondaries';
import { Tertiaries } from './Tertiaries';
import { TintShades } from './TintShades';

export const Colors = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.COLORS.anchor}
      title={SECTIONS.COLORS.title}
      {...props}
    >
      <Box
        mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.xSmall}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/Colors.jpg'}
          alt="A man looking to the left photographed from a frog’s perspective in front of a wall of hundreds of lights"
        />
      </Box>

      <TextComponent maxWidth={undefined} mb={space.xSmall}>
        We’ve adjusted the color palette to fit the brand values, foster
        creativity and ensure the viewability on black and white colored
        backgrounds.
      </TextComponent>

      <VStack align="stretch" spacing={2} mb={space.large}>
        <DownloadFileComponent
          file={
            'https://download.dws.com/download?elib-assetguid=6957efe7f23040efb07a3bb78ca12b1e'
          }
          fileType="PDF"
          fileSize="1.5 MB"
        >
          Color library (Adobe)
        </DownloadFileComponent>

        <DownloadFileComponent
          file={
            'https://download.dws.com/download?elib-assetguid=5324e243651c448f9ce7eb0fc9cb70fb'
          }
          fileType="PDF"
          fileSize="1.5 MB"
        >
          Color quickguide
        </DownloadFileComponent>
      </VStack>

      <HeadingComponent as="h3" variant="secondary" mb={2}>
        Primary colors
      </HeadingComponent>

      <HeadingComponent as="h4" variant="tertiary" mb={space.xxSmall}>
        Context related
      </HeadingComponent>

      <ContextRelated mb={space.small} />

      <HeadingComponent as="h4" variant="tertiary" mb={space.xxSmall}>
        Black & white tones – Backgrounds, contrasts, typography […]
      </HeadingComponent>

      <BlackWhiteTones mb={space.large} />

      <HeadingComponent as="h3" variant="secondary" mb={2}>
        Secondary colors
      </HeadingComponent>

      <HeadingComponent as="h4" variant="tertiary" mb={space.xxSmall}>
        Secondaries
      </HeadingComponent>

      <Secondaries mb={space.small} />

      <HeadingComponent as="h4" variant="tertiary" mb={space.xxSmall}>
        Tertiaries
      </HeadingComponent>

      <Tertiaries mb={space.large} />

      <HeadingComponent as="h3" variant="secondary" mb={2}>
        Tint & shades
      </HeadingComponent>

      <HeadingComponent as="h4" variant="tertiary" mb={space.xxSmall}>
        Tint & shades
      </HeadingComponent>

      <TintShades mb={space.small} />

      <HeadingComponent as="h4" variant="tertiary" mb={space.xxSmall}>
        Alert & signals
      </HeadingComponent>

      <AlertSignals mb={space.small} />

      <HeadingComponent as="h4" variant="tertiary" mb={space.xxSmall}>
        Extensions
      </HeadingComponent>

      <Extensions mb={space.large} />

      <HeadingComponent as="h3" variant="secondary" mb={2}>
        Application examples
      </HeadingComponent>

      <HeadingComponent as="h4" variant="tertiary" mb={space.xSmall}>
        Banner examples
      </HeadingComponent>

      <Box mb={space.large}>
        <img
          src={process.env.PUBLIC_URL + '/images/BannerExamples.png'}
          alt="Banners in three different color variants each on a white and black background"
        />
      </Box>

      <HeadingComponent as="h4" variant="tertiary" mb={space.xSmall}>
        Ad / Poster examples
      </HeadingComponent>

      <Box
        mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.large}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/AdPosterExamples.png'}
          alt="Posters in three different color variants"
        />
      </Box>

      <HeadingComponent as="h3" variant="secondary" mb={2}>
        Presentation & infographic specifics
      </HeadingComponent>

      <HeadingComponent as="h4" variant="tertiary">
        Colors
      </HeadingComponent>

      <Box
        mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.large}
      >
        <img
          src={
            process.env.PUBLIC_URL +
            '/images/PresentationInfographicSpecifics.png'
          }
          alt="All corporated colors are arranged as a periodic table"
        />
      </Box>

      <HeadingComponent as="h3" variant="secondary" mb={space.small}>
        Application examples
      </HeadingComponent>

      <Box mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}>
        <img
          src={process.env.PUBLIC_URL + '/images/ApplicationExamples.png'}
          alt="Three different info graphics each shown on a white and black background where the graphs use the DWS corporate colors"
        />
      </Box>
    </ContentSectionComponent>
  );
};
