import { Box } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { TextComponent } from 'components/Text';
import { PAGES } from 'constants/pages';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

export const DesignSystemElements = () => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.DESIGN_SYSTEM_ELEMENTS.anchor}
      moreDetailsUrl={`${PAGES.BRAND_ESSENTIALS_AND_GUIDES.path}#${SECTIONS.DESIGN_SYSTEM.anchor}`}
      title={SECTIONS.DESIGN_SYSTEM_ELEMENTS.title}
    >
      <TextComponent limitedWidth mb={space.small}>
        We have moved from static to fluid by unveiling the potential for our
        design system to create a modular, versatile — but still distinctive —
        visual brand experience. Once again, the concept originates from the DWS
        logo slashes, which can now be combined with an extended, defined
        framework. It allows three basic application options which can be used
        modularly.
      </TextComponent>

      <Box mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}>
        <img
          src={process.env.PUBLIC_URL + '/images/DesignSystemElements.png'}
          alt="How to use the corporate slash: 01 Solid Slash, 02 Diagonal Line Slash, 03 Outline Slash"
        />
      </Box>
    </ContentSectionComponent>
  );
};
