import { Box, Flex } from '@chakra-ui/react';

import ArrowRightCircleIcon from 'components/Icon/ArrowRightCircleIcon';
import { LinkComponent } from 'components/Link';
import { TextComponent } from 'components/Text';
import { size, space, transition } from 'theme/constants';

type Props = {
  nextPageHref: string;
  nextPageName: string;
};

export const PageFooterComponent = ({ nextPageHref, nextPageName }: Props) => {
  const today = new Date();
  const year = today.getFullYear();

  return (
    <Box as="footer">
      <Box
        as="a"
        href={nextPageHref}
        display="block"
        textDecoration="none"
        color="inherit"
        bgColor="background.nearWhite"
        transition={`background-color ${transition.default}`}
        _focus={{
          bgColor: 'background.neutral',
        }}
        _hover={{
          bgColor: 'background.neutral',
        }}
      >
        <Flex
          as="span"
          align="center"
          justify="space-between"
          maxWidth={size.contentMaxWidth}
          py={8}
          px={{ base: space.xSmall, lg: space.medium, xl: space.xLarge }}
        >
          <Flex as="span" direction="column" flex="1" mr={space.xSmall}>
            <TextComponent
              variant="tiny"
              as="span"
              mb={0.5}
              color="text.secondaryDark"
              sx={{
                WebkitFontSmoothing: 'antialiased',
                MozOsxFontSmoothing: 'grayscale',
              }}
            >
              Read next
            </TextComponent>
            <TextComponent variant="large" as="span">
              {nextPageName}
            </TextComponent>
          </Flex>

          <ArrowRightCircleIcon boxSize={16} />
        </Flex>
      </Box>

      <Box
        as="a"
        href="mailto:sammi.ho@dws.com; marusa.arian@dws.com"
        display="block"
        textDecoration="none"
        color="inherit"
        bgColor="background.nearBlack"
        transition={`background-color ${transition.default}`}
        _focus={{
          bgColor: 'background.black',
        }}
        _hover={{
          bgColor: 'background.black',
        }}
      >
        <Flex
          align="center"
          justify="space-between"
          maxWidth={size.contentMaxWidth}
          py={8}
          px={{ base: space.xSmall, lg: space.medium, xl: space.xLarge }}
          sx={{
            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale',
          }}
        >
          <Flex direction="column" flex="1" mr={space.xSmall}>
            <TextComponent
              variant="tiny"
              as="span"
              mb={0.5}
              color="text.secondaryDark"
            >
              May we help you?
            </TextComponent>
            <TextComponent variant="large" as="span" color="text.primaryLight">
              Your brand support
            </TextComponent>
          </Flex>

          <Box
            py="18px"
            px="30px"
            fontSize="18px"
            fontWeight="700"
            lineHeight="1.33333333"
            color="text.primaryLight"
            bgColor="background.highlight"
          >
            Contact us
          </Box>
        </Flex>
      </Box>

      <Flex
        justify="flex-end"
        maxWidth={size.contentMaxWidth}
        py={8}
        pr={{ base: space.xSmall, lg: space.xLarge }}
        pl={{ base: space.xSmall, lg: space.large }}
        color="text.secondaryDark"
      >
        <Box as="ul" display="flex" listStyleType="none" mr={space.small}>
          <Box as="li" mr={space.small}>
            <LinkComponent
              variant="secondary"
              href="https://www.dws.com/footer/Legal-Resources/privacy-notice/"
            >
              Privacy notice
            </LinkComponent>
          </Box>

          <Box as="li" mr={space.small}>
            <LinkComponent
              variant="secondary"
              href="https://www.dws.com/footer/Legal-Resources/"
            >
              Legal resources
            </LinkComponent>
          </Box>

          <Box as="li">
            <LinkComponent
              variant="secondary"
              href="https://www.dws.com/footer/imprint/"
            >
              Imprint
            </LinkComponent>
          </Box>
        </Box>

        <Box>Copyright © {year} DWS</Box>
      </Flex>
    </Box>
  );
};
