export const headerCols = [
  {
    id: 1,
    content: 'Format',
    isNumeric: false,
  },
  {
    id: 2,
    content: 'mm (w × h)',
    isNumeric: true,
  },
  {
    id: 3,
    content: 'inch (w × h)',
    isNumeric: true,
  },
  {
    id: 4,
    content: 'Logo in %',
    isNumeric: true,
  },
  {
    id: 5,
    content: 'Logo width mm',
    isNumeric: true,
  },
  {
    id: 6,
    content: 'Logo width inch',
    isNumeric: true,
  },
];

export const tbodyRows = [
  {
    id: 1,
    cols: [
      {
        id: 1,
        content: 'DIN A6',
        isNumeric: false,
      },
      {
        id: 2,
        content: '105 × 148',
        isNumeric: true,
      },
      {
        id: 3,
        content: '4.13 × 5.83',
        isNumeric: true,
      },
      {
        id: 4,
        content: '36',
        isNumeric: true,
      },
      {
        id: 5,
        content: '27.5',
        isNumeric: true,
      },
      {
        id: 6,
        content: '1.08',
        isNumeric: true,
      },
    ],
  },
  {
    id: 2,
    cols: [
      {
        id: 1,
        content: '1/3 DIN A4',
        isNumeric: false,
      },
      {
        id: 2,
        content: '210 × 99',
        isNumeric: true,
      },
      {
        id: 3,
        content: '8.27 × 3.90',
        isNumeric: true,
      },
      {
        id: 4,
        content: '36',
        isNumeric: true,
      },
      {
        id: 5,
        content: '27.5',
        isNumeric: true,
      },
      {
        id: 6,
        content: '1.08',
        isNumeric: true,
      },
    ],
  },
  {
    id: 3,
    cols: [
      {
        id: 1,
        content: 'DIN A5',
        isNumeric: false,
      },
      {
        id: 2,
        content: '148 × 210',
        isNumeric: true,
      },
      {
        id: 3,
        content: '5.83 × 8.27',
        isNumeric: true,
      },
      {
        id: 4,
        content: '44',
        isNumeric: true,
      },
      {
        id: 5,
        content: '33.5',
        isNumeric: true,
      },
      {
        id: 6,
        content: '1.32',
        isNumeric: true,
      },
    ],
  },
  {
    id: 4,
    cols: [
      {
        id: 1,
        content: 'DIN A4',
        isNumeric: false,
      },
      {
        id: 2,
        content: '210 × 297',
        isNumeric: true,
      },
      {
        id: 3,
        content: '8.27 × 11.69',
        isNumeric: true,
      },
      {
        id: 4,
        content: '56',
        isNumeric: true,
      },
      {
        id: 5,
        content: '42.5',
        isNumeric: true,
      },
      {
        id: 6,
        content: '1.67',
        isNumeric: true,
      },
    ],
  },
  {
    id: 5,
    cols: [
      {
        id: 1,
        content: 'US letter',
        isNumeric: false,
      },
      {
        id: 2,
        content: '215,9 × 274,4',
        isNumeric: true,
      },
      {
        id: 3,
        content: '8.50 × 11.00',
        isNumeric: true,
      },
      {
        id: 4,
        content: '56',
        isNumeric: true,
      },
      {
        id: 5,
        content: '42.5',
        isNumeric: true,
      },
      {
        id: 6,
        content: '1.67',
        isNumeric: true,
      },
    ],
  },
  {
    id: 6,
    cols: [
      {
        id: 1,
        content: 'DIN A3',
        isNumeric: false,
      },
      {
        id: 2,
        content: '297 × 420',
        isNumeric: true,
      },
      {
        id: 3,
        content: '11.69 × 16.54',
        isNumeric: true,
      },
      {
        id: 4,
        content: '70',
        isNumeric: true,
      },
      {
        id: 5,
        content: '53.5',
        isNumeric: true,
      },
      {
        id: 6,
        content: '2.11',
        isNumeric: true,
      },
    ],
  },
  {
    id: 7,
    cols: [
      {
        id: 1,
        content: 'DIN A2',
        isNumeric: false,
      },
      {
        id: 2,
        content: '420 × 594',
        isNumeric: true,
      },
      {
        id: 3,
        content: '16.54 × 23.39',
        isNumeric: true,
      },
      {
        id: 4,
        content: '100',
        isNumeric: true,
      },
      {
        id: 5,
        content: '76.0',
        isNumeric: true,
      },
      {
        id: 6,
        content: '3.00',
        isNumeric: true,
      },
    ],
  },
  {
    id: 8,
    cols: [
      {
        id: 1,
        content: 'DIN A1',
        isNumeric: false,
      },
      {
        id: 2,
        content: '594 × 841',
        isNumeric: true,
      },
      {
        id: 3,
        content: '23.39 × 33.11',
        isNumeric: true,
      },
      {
        id: 4,
        content: '140',
        isNumeric: true,
      },
      {
        id: 5,
        content: '106.5',
        isNumeric: true,
      },
      {
        id: 6,
        content: '4.20',
        isNumeric: true,
      },
    ],
  },
  {
    id: 9,
    cols: [
      {
        id: 1,
        content: 'DIN A0',
        isNumeric: false,
      },
      {
        id: 2,
        content: '841 × 1189',
        isNumeric: true,
      },
      {
        id: 3,
        content: '33.11 × 46.81',
        isNumeric: true,
      },
      {
        id: 4,
        content: '200',
        isNumeric: true,
      },
      {
        id: 5,
        content: '152.0',
        isNumeric: true,
      },
      {
        id: 6,
        content: '6.00',
        isNumeric: true,
      },
    ],
  },
  {
    id: 10,
    cols: [
      {
        id: 1,
        content: 'DIN 2A0',
        isNumeric: false,
      },
      {
        id: 2,
        content: '1189 × 1682',
        isNumeric: true,
      },
      {
        id: 3,
        content: '46.81 × 66.22',
        isNumeric: true,
      },
      {
        id: 4,
        content: '280',
        isNumeric: true,
      },
      {
        id: 5,
        content: '213.5',
        isNumeric: true,
      },
      {
        id: 6,
        content: '8.40',
        isNumeric: true,
      },
    ],
  },
  {
    id: 11,
    cols: [
      {
        id: 1,
        content: 'DIN 4A0',
        isNumeric: false,
      },
      {
        id: 2,
        content: '1682 × 2378',
        isNumeric: true,
      },
      {
        id: 3,
        content: '66.22 × 93.62',
        isNumeric: true,
      },
      {
        id: 4,
        content: '380',
        isNumeric: true,
      },
      {
        id: 5,
        content: '290.0',
        isNumeric: true,
      },
      {
        id: 6,
        content: '11.41',
        isNumeric: true,
      },
    ],
  },
];
