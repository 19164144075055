import { Box, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';

type Props = {
  headerCols: Array<{
    id: number;
    content: string;
    isNumeric: boolean;
  }>;
  isInverted?: boolean;
  tbodyRows: Array<{
    id: number;
    cols: Array<{
      id: number;
      content: string;
      isNumeric: boolean;
    }>;
  }>;
};

export const TableComponent = ({
  headerCols,
  isInverted,
  tbodyRows,
}: Props) => {
  return (
    <Box
      overflowX="auto"
      overflowY="hidden"
      maxWidth="100%"
      sx={{ WebkitOverflowScrolling: 'touch' }}
    >
      <Table variant="simple">
        <Thead>
          <Tr>
            {headerCols.map((header) => (
              <TableHeadItem
                key={header.id}
                colTitle={header.content}
                isInverted={isInverted}
                isNumeric={header.isNumeric}
              />
            ))}
          </Tr>
        </Thead>

        <Tbody>
          {tbodyRows.map((row) => (
            <TableBodyRow
              key={row.id}
              data={row.cols}
              isInverted={isInverted}
            />
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

type TableHeadItemProps = {
  colTitle: string;
  isInverted?: boolean;
  isNumeric?: boolean;
};

export const TableHeadItem = ({
  colTitle,
  isInverted,
  isNumeric,
}: TableHeadItemProps) => {
  return (
    <Th
      p={4}
      borderColor={isInverted ? 'border.white' : 'border.black'}
      borderBottomWidth="2px"
      fontFamily="inherit"
      fontSize={{ base: '12px', xl: '16px' }}
      fontWeight="700"
      letterSpacing="inherit"
      lineHeight="1.25"
      textTransform="inherit"
      color={isInverted ? 'text.primaryLight' : 'text.primaryDark'}
      isNumeric={isNumeric}
      _first={{ pl: '0' }}
      _last={{ pr: '0' }}
    >
      {colTitle}
    </Th>
  );
};

type TableBodyRowProps = {
  data: Array<{
    id: number;
    content: string;
    isNumeric: boolean;
  }>;
  isInverted?: boolean;
};

export const TableBodyRow = ({ data, isInverted }: TableBodyRowProps) => {
  return (
    <Tr>
      {data.map((cell) => (
        <Td
          key={cell.id}
          p={4}
          borderColor={isInverted ? 'border.white' : 'border.black'}
          fontFamily="inherit"
          fontSize={{ base: '12px', xl: '16px' }}
          fontWeight="300"
          lineHeight="1.25"
          color={isInverted ? 'text.primaryLight' : 'text.primaryDark'}
          isNumeric={cell.isNumeric}
          _first={{ pl: '0' }}
          _last={{ pr: '0' }}
        >
          {cell.content}
        </Td>
      ))}
    </Tr>
  );
};
