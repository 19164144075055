import { SimpleGridProps } from '@chakra-ui/react';

import { ColorGridItemComponent } from '../../components/ColorGridItem';
import { SimpleColorGridComponent } from '../../components/SimpleColorGrid';

export const AlertSignals = ({ ...props }: SimpleGridProps) => {
  return (
    <SimpleColorGridComponent {...props}>
      <ColorGridItemComponent
        color={'context.positive'}
        headline={'Positive'}
        hexCode={'#83C460'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.negative'}
        headline={'Negative'}
        hexCode={'#E84057'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.warning'}
        headline={'Warning'}
        hexCode={'#E8C64D'}
        hasBorder={false}
      />
    </SimpleColorGridComponent>
  );
};
