import * as React from 'react';

import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabsProps,
  TabProps,
} from '@chakra-ui/react';

// Tabs
type TabsComponentProps = {
  children: React.ReactNode;
} & TabsProps;

export const TabsComponent = ({ children, ...props }: TabsComponentProps) => {
  return <Tabs {...props}>{children}</Tabs>;
};

// TabList
type TabListProps = {
  children: React.ReactNode;
};

export const TabListComponent = ({ children, ...props }: TabListProps) => {
  return (
    <TabList
      mb={4}
      borderColor="transparent"
      sx={{ hyphens: 'auto' }}
      {...props}
    >
      {children}
    </TabList>
  );
};

// Tab Item
type TabComponentProps = {
  children: string;
} & TabProps;

export const TabComponent = ({ children, ...props }: TabComponentProps) => {
  return (
    <Tab
      borderBottomWidth="3px"
      px={{ base: 2, sm: 4 }}
      color="text.secondaryDark"
      _selected={{
        borderBottomColor: 'border.highlight',
        color: 'text.highlight',
      }}
      _focus={{
        boxShadow: 'none',
        color: 'text.highlight',
        bgColor: 'background.nearWhite',
      }}
      _hover={{ color: 'text.highlight' }}
      _active={{ bgColor: 'transparent' }}
      {...props}
    >
      {children}
    </Tab>
  );
};

// TabPanels
type TabPanelsComponentProps = {
  children: React.ReactNode;
};

export const TabPanelsComponent = ({
  children,
  ...props
}: TabPanelsComponentProps) => {
  return <TabPanels {...props}>{children}</TabPanels>;
};

// TabPanel Item
type TabPanelComponentProps = {
  children: React.ReactNode;
};

export const TabPanelComponent = ({
  children,
  ...props
}: TabPanelComponentProps) => {
  return (
    <TabPanel p="0" {...props}>
      {children}
    </TabPanel>
  );
};
