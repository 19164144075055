import { Box } from '@chakra-ui/react';

import { ContentSectionComponent } from '../../components/ContentSection';
import { LinkBar } from '../../components/LinkBar';
import { TextComponent } from '../../components/Text';
import { SECTIONS } from '../../constants/sections';
import { space } from '../../theme/constants';

export const DigitalBrand = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.DIGITAL_BRAND.anchor}
      title={SECTIONS.DIGITAL_BRAND.title}
      {...props}
    >
      <Box
        mb={space.small}
        mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/DigitalBrand.png'}
          alt="Example event materials"
        />
      </Box>

      <TextComponent mb={space.small}>
        The DWS Digital Design System provides guidelines, key elements and
        other parameters for applying the brand to the digital space, e. g. for
        building websites, web applications and advertising banners. The Digital
        Design System is based on atomic design principles and resides in Figma,
        which allows you to directly explore the brand elements in detail, to
        adopt color and typography settings, and to select components for your
        application.
      </TextComponent>

      <TextComponent mb={space.small}>
        Please note that the Digital Design System defines the target state, and
        we&apos;re currently progressing to this target state with our primary
        digital touch points in a fluid approach. The guide will grow over time
        and will be supported by a Storybook implementation, which will provide
        front-end code equivalents to the Figma elements. Every new development
        should be based on this guide going forward.
      </TextComponent>

      <LinkBar link={'https://www.dws.com/redirects/bp-dds/'} mb={space.large}>
        DWS digital quickguide
      </LinkBar>
    </ContentSectionComponent>
  );
};
