import { Box } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { TextComponent } from 'components/Text';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

export const StrategicFramework = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.STRATEGIC_FRAMEWORK.anchor}
      title={SECTIONS.STRATEGIC_FRAMEWORK.title}
      {...props}
    >
      <TextComponent>
        Our entire marketing strategy and action is aligned with our brand
        identity – subserving and following the corporate strategy, the growth
        of global brand value and the enhancement of consistent brand
        leadership.
      </TextComponent>

      <Box mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}>
        <img
          src={process.env.PUBLIC_URL + '/images/StrategicFramework.png'}
          alt="Four different visuals with text on it: All together creating a brand experience that’s … – … sustainable, pupose-driven, conscientious & self critical. – … human, thoughtful reliable & tanglible. – progressive, creative & engaging. – … open-minded, diverse, respectful & empowering."
        />
      </Box>
    </ContentSectionComponent>
  );
};
