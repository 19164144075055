import { Box } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { TextComponent } from 'components/Text';
import { SECTIONS } from 'constants/sections';
import { space, zIndex } from 'theme/constants';

export const CoreValues = () => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.CORE_VALUES.anchor}
      title={SECTIONS.CORE_VALUES.title}
    >
      <Box
        position="relative"
        zIndex={zIndex.default}
        mb={{ base: space.large, lg: space.xxLarge }}
      >
        <TextComponent>
          Our core values set the framework for our brand leadership to achieve
          and preserve consistency in everything we create across the world.
          More importantly, they are the source of growth for our brand value
          and are designed to cultivate a collective perception of DWS.
          Together, our values.
        </TextComponent>
      </Box>

      <Box
        py={space.xLarge}
        mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        backgroundColor={'black'}
      >
        <Box mt={{ base: '-150px', lg: '-250px' }}>
          <img
            src={process.env.PUBLIC_URL + '/images/CoreValues.png'}
            alt="Three circles – Client Commitment, Sustainable Action and Entrepreneurial Spirit – intersectioning each other"
          />
        </Box>
      </Box>
    </ContentSectionComponent>
  );
};
