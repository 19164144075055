import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';

type Props = {
  items: ReadonlyArray<ReactImageGalleryItem>;
};

export const ImageGalleryComponent = ({ items }: Props) => {
  return (
    <ImageGallery
      autoPlay={false}
      disableThumbnailScroll={false}
      infinite={false}
      items={items}
      showBullets={false}
      showFullscreenButton={false}
      showIndex={false}
      showNav={false}
      showPlayButton={false}
      slideDuration={300}
    />
  );
};
