import { Flex, BoxProps } from '@chakra-ui/react';

import DownloadCircleIcon from 'components/Icon/DownloadCircleIcon';
import { TextComponent } from 'components/Text';
import { space, transition } from 'theme/constants';

type Props = {
  file: string;
  fileType: string;
  fileSize: string;
  children: string;
} & BoxProps;

export const DownloadFileComponent = ({
  file,
  fileType,
  fileSize,
  children,
  ...props
}: Props) => {
  return (
    <Flex
      as="a"
      download
      href={file}
      align="center"
      justify="space-between"
      p={8}
      textDecoration="none"
      color="text.primaryDark"
      bgColor="background.nearWhite"
      transition={`background-color ${transition.default}`}
      sx={{
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
      }}
      _focus={{
        bgColor: 'background.neutral',
      }}
      _hover={{
        bgColor: 'background.neutral',
      }}
      _active={{
        transform: 'translateY(2px)',
      }}
      {...props}
    >
      <Flex direction="column" flex="1" mr={space.xSmall}>
        <TextComponent
          variant="tiny"
          as="span"
          mb={0.5}
          textTransform="uppercase"
          color="text.secondaryDark"
        >
          {fileType}, {fileSize}
        </TextComponent>
        <TextComponent variant="large" as="span">
          {children}
        </TextComponent>
      </Flex>

      <DownloadCircleIcon boxSize={16} />
    </Flex>
  );
};
