import { Box } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { DownloadFileComponent } from 'components/DownloadFile';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

export const Charts = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.CHARTS.anchor}
      title={SECTIONS.CHARTS.title}
      {...props}
      isInverted
    >
      <Box
        mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.small}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/InfographicsCharts.jpg'}
          alt="A double page showing different infographics and charts"
        />
      </Box>

      <DownloadFileComponent
        mb={space.large}
        file={
          'https://download.dws.com/download?elib-assetguid=a4b41b26b5294bb08642533005d7be79'
        }
        fileType="Zip"
        fileSize="5.1 MB"
      >
        Charts quickguide
      </DownloadFileComponent>
    </ContentSectionComponent>
  );
};
