import { SimpleGridProps } from '@chakra-ui/react';

import { ColorGridItemComponent } from '../../components/ColorGridItem';
import { SimpleColorGridComponent } from '../../components/SimpleColorGrid';

export const TintShades = ({ ...props }: SimpleGridProps) => {
  return (
    <SimpleColorGridComponent {...props}>
      <ColorGridItemComponent
        color={'context.darkPetrol'}
        headline={'Dark Petrol'}
        hexCode={'#49888A'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.lightPetrol'}
        headline={'Light Petrol'}
        hexCode={'#7AC0C0'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.darkGreen'}
        headline={'Dark Green'}
        hexCode={'#96BD59'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.lightGreen'}
        headline={'Light Green'}
        hexCode={'#B5D672'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.darkPurple'}
        headline={'Dark Purple'}
        hexCode={'#574194'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.lightPurple'}
        headline={'Light Purple'}
        hexCode={'#9070E5'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.darkGrayBlue'}
        headline={'Dark Gray Blue'}
        hexCode={'#44535A'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.lightGrayBlue'}
        headline={'Light Gray Blue'}
        hexCode={'#708894'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.darkEarthGreige'}
        headline={'Dark Earth Greige'}
        hexCode={'#92887C'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.lightEarthGreige'}
        headline={'Light Earth Greige'}
        hexCode={'#C0BBB5'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.darkGrassGreen'}
        headline={'Dark Grass Green'}
        hexCode={'#59995D'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.lightGrassGreen'}
        headline={'Light Grass Green'}
        hexCode={'#82C486'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.darkSkyBlue'}
        headline={'Dark Sky Blue'}
        hexCode={'#3DA4D4'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.lightSkyBlue'}
        headline={'Light Sky Blue'}
        hexCode={'#86C7E5'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.darkOceanBlue'}
        headline={'Dark Ocean Blue'}
        hexCode={'#253F6B'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.lightOceanBlue'}
        headline={'Light Ocean Blue'}
        hexCode={'#436FBA'}
        hasBorder={false}
      />
    </SimpleColorGridComponent>
  );
};
