import { Box } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { ListItemComponent, UnorderedListComponent } from 'components/List';
import {
  TabComponent,
  TabListComponent,
  TabPanelComponent,
  TabPanelsComponent,
  TabsComponent,
} from 'components/Tabs';
import { TextComponent } from 'components/Text';
import { SECTIONS } from 'constants/sections';
import { FIDUCARY_PETROL, PROGRESSIVE_PURPLE, SUSTAINED_ACTION } from 'theme';
import { space, zIndex } from 'theme/constants';

export const KeyMessages = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.KEY_MESSAGES.anchor}
      title={SECTIONS.KEY_MESSAGES.title}
      {...props}
    >
      <TabsComponent
        mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        // mb={space.small}
      >
        <TabListComponent>
          <TabComponent
            _selected={{
              borderBottomColor: 'context.clientCommitment',
              color: 'context.clientCommitment',
            }}
            _focus={{
              boxShadow: 'none',
              color: 'context.clientCommitment',
              bgColor: 'background.nearWhite',
            }}
            _hover={{ color: 'context.clientCommitment' }}
          >
            Client Commitment
          </TabComponent>

          <TabComponent
            _selected={{
              borderBottomColor: 'context.entrepreneurialSpirit',
              color: 'context.entrepreneurialSpirit',
            }}
            _focus={{
              boxShadow: 'none',
              color: 'context.entrepreneurialSpirit',
              bgColor: 'background.nearWhite',
            }}
            _hover={{ color: 'context.entrepreneurialSpirit' }}
          >
            Entrepreneurial Spirit
          </TabComponent>

          <TabComponent
            _selected={{
              borderBottomColor: 'context.sustainableAction',
              color: 'context.sustainableAction',
            }}
            _focus={{
              boxShadow: 'none',
              color: 'context.sustainableAction',
              bgColor: 'background.nearWhite',
            }}
            _hover={{ color: 'context.sustainableAction' }}
          >
            Sustainable Action
          </TabComponent>
        </TabListComponent>

        <TabPanelsComponent>
          <TabPanelComponent>
            <Box
              position="relative"
              _before={{
                content: "''",
                position: 'absolute',
                zIndex: zIndex.default,
                top: '0',
                bottom: '-40px',
                width: '12px',
                bgColor: 'context.clientCommitment',
              }}
            >
              <Box mb={space.large}>
                <img
                  src={
                    process.env.PUBLIC_URL + '/images/ClientCommitmentHero.jpg'
                  }
                  alt="At sunset a man is sitting on a rock above the clouds taking a look in the distance"
                />
              </Box>

              <Box mb={space.medium} px={space.large}>
                <TextComponent color={FIDUCARY_PETROL} variant={'large'}>
                  The trust from our clients will always be one of our greatest
                  assets. As investors, we are committed to acting on behalf of
                  our clients and investing with their best interests at heart
                  so that they can reach their financial goals, no matter what
                  the future holds. We work every day to deliver outstanding
                  investment results, in both good and challenging times. This
                  is what defines us.
                </TextComponent>
              </Box>

              <Box>
                <Box px={space.large}>
                  <UnorderedListComponent pb={'15px'}>
                    <ListItemComponent>
                      <TextComponent>
                        All of our investment decisions are made to ensure we
                        serve our clients’ best interests, taking into
                        consideration the knowledge and experience of our
                        investment professionals together with our access to
                        data and analytics
                      </TextComponent>
                    </ListItemComponent>

                    <ListItemComponent>
                      <TextComponent>
                        We’re committed to serving our clients with empathy and
                        integrity, working with them to build the best, most
                        sustainable foundation for their future plans
                      </TextComponent>
                    </ListItemComponent>

                    <ListItemComponent>
                      <TextComponent>
                        As a leading global asset manager, we have the ability
                        to tailor products and solutions to individual
                        requirements of our clients
                      </TextComponent>
                    </ListItemComponent>

                    <ListItemComponent>
                      <TextComponent>
                        The breadth of our product and solutions across our
                        globally unified investment platform means we deliver
                        our clients the right solutions whatever challenges the
                        market poses
                      </TextComponent>
                    </ListItemComponent>
                  </UnorderedListComponent>
                </Box>
              </Box>
            </Box>
          </TabPanelComponent>

          <TabPanelComponent>
            <Box
              position="relative"
              _before={{
                content: "''",
                position: 'absolute',
                zIndex: zIndex.default,
                top: '0',
                bottom: '-40px',
                width: '12px',
                bgColor: 'context.entrepreneurialSpirit',
              }}
            >
              <Box mb={space.large}>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    '/images/EntrepreneurialSpiritHero.jpg'
                  }
                  alt="The word Change written in voilet colored neon tubes"
                />
              </Box>

              <Box mb={space.medium} px={space.large}>
                <TextComponent color={PROGRESSIVE_PURPLE} variant={'large'}>
                  We continuously invest in our diverse mix of people,
                  empowering them to make change and fostering their creativity,
                  courage and long-term thinking. Our entrepreneurial,
                  collaborative spirit empowers them to share their perspective
                  and have their voice heard. We always question the status quo,
                  make swift – yet considered – decisions, and strive to
                  simplify and perfect how we do things – all for our common
                  goal of serving our clients.
                </TextComponent>
              </Box>

              <Box>
                <Box px={space.large}>
                  <UnorderedListComponent pb={'15px'}>
                    <ListItemComponent>
                      <TextComponent>
                        Our collective experience and long-term focus means we
                        are prepared for what&apos;s around the corner – and we
                        are ready to innovate to capture the opportunities of
                        tomorrow.
                      </TextComponent>
                    </ListItemComponent>

                    <ListItemComponent>
                      <TextComponent>
                        We are committed team players, working together wherever
                        we can, trusting and supporting one another to develop
                        our collective intelligence to ensure we deliver for our
                        clients
                      </TextComponent>
                    </ListItemComponent>

                    <ListItemComponent>
                      <TextComponent>
                        We harness the power of our people together with
                        technology to make better investment decisions, improve
                        our performance and efficiency and expand our
                        capabilities
                      </TextComponent>
                    </ListItemComponent>

                    <ListItemComponent>
                      <TextComponent>
                        With a tradition of German precision – combined with
                        talent around the globe – we develop and exchange our
                        best ideas globally to serve our clients locally. This
                        results in some of the most innovative investment
                        solutions in the market
                      </TextComponent>
                    </ListItemComponent>

                    <ListItemComponent>
                      <TextComponent>
                        As a European asset manager with global reach, we are
                        proud to use our different cultures, languages and
                        backgrounds to support our broad and diverse range of
                        investors, whether they are existing clients of DWS or
                        new to our firm
                      </TextComponent>
                    </ListItemComponent>

                    <ListItemComponent>
                      <TextComponent>
                        We are serious about diversity, equity and creating an
                        inclusive culture where our colleagues can be themselves
                        – this empowers us to think differently and creatively,
                        and also enables us to better align with the society in
                        which we operate
                      </TextComponent>
                    </ListItemComponent>
                  </UnorderedListComponent>
                </Box>
              </Box>
            </Box>
          </TabPanelComponent>

          <TabPanelComponent>
            <Box
              position="relative"
              _before={{
                content: "''",
                position: 'absolute',
                zIndex: zIndex.default,
                top: '0',
                bottom: '-40px',
                width: '12px',
                bgColor: 'context.sustainableAction',
              }}
            >
              <Box mb={space.large}>
                <img
                  src={
                    process.env.PUBLIC_URL + '/images/SustainableActionHero.jpg'
                  }
                  alt="A girl is sitting on a wet stoned underground in the middle of the woods looking into the sky with outspread arms"
                />
              </Box>

              <Box mb={space.medium} px={space.large}>
                <TextComponent color={SUSTAINED_ACTION} variant={'large'}>
                  Environmental, social and governance (ESG) themes are rapidly
                  transforming businesses, society, and our planet. And while
                  there are risks associated with any change, there are new
                  opportunities for investments, too. We understand that, both
                  as a corporate as well as a trusted advisor to our clients, we
                  have a crucial role in helping navigate the transition to a
                  more sustainable future.
                </TextComponent>
              </Box>

              <Box>
                <Box mb={space.medium} px={space.large}>
                  <UnorderedListComponent pb={'15px'}>
                    <ListItemComponent>
                      <TextComponent>
                        When making investment decisions, by default, we also
                        think long-term, ensuring economic, ecological and
                        social wealth for the next generations.
                      </TextComponent>
                    </ListItemComponent>

                    <ListItemComponent>
                      <TextComponent>
                        As our clients&apos; demand for sustainable investment
                        options grows, so too do our capabilities. Our deep
                        experience and understanding of the market - combined
                        with the latest ESG data - delivers important insight as
                        we develop new strategies. And we constantly test,
                        refine and optimize our approach to deliver the best
                        returns for our clients.
                      </TextComponent>
                    </ListItemComponent>

                    <ListItemComponent>
                      <TextComponent>
                        Beyond our own offerings - as part of our stewardship
                        responsibilities - we actively engage with the
                        corporations we invest in, to support the needed
                        sustainable transition. We continue to also play an
                        active role in developing new standards by working
                        collaboratively with regulators and policymakers.
                      </TextComponent>
                    </ListItemComponent>

                    <ListItemComponent>
                      <TextComponent>
                        From our Net Zero carbon commitment to our Corporate
                        Social Responsibility efforts, as a corporate citizen we
                        take accountability for the ways in which responsible
                        asset allocation is supporting the evolution of a more
                        sustainable future.
                      </TextComponent>
                    </ListItemComponent>
                  </UnorderedListComponent>
                </Box>
              </Box>
            </Box>
          </TabPanelComponent>
        </TabPanelsComponent>
      </TabsComponent>
    </ContentSectionComponent>
  );
};
