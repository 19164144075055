import * as React from 'react';

import { Box, VStack } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { DownloadFileComponent } from 'components/DownloadFile';
import { HeadingComponent } from 'components/Heading';
import { TextComponent } from 'components/Text';
import { SECTIONS } from 'constants/sections';
import { space, zIndex } from 'theme/constants';

import { ButtonComponent } from '../../components/Button';

export const Videography = ({ ...props }) => {
  const playAudio = () => {
    new Audio('sounds/Soundlogo.mp3').play();
  };
  return (
    <ContentSectionComponent
      anchor={SECTIONS.VIDEOGRAPHY.anchor}
      title={SECTIONS.VIDEOGRAPHY.title}
      {...props}
    >
      <Box
        mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={{ base: space.small, lg: space.large }}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/Moving.png'}
          alt="A woman in front of an incoming subway (black & white) with white text on on it that reads: Moving"
        />
      </Box>

      <HeadingComponent as="h3" variant="secondary" mb={space.small}>
        Film intro & outro
      </HeadingComponent>

      <Box
        mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.xLarge}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/FilmIntroOutro.png'}
          alt="Two video sequences showing how the DWS logo is animated on a black and white background"
        />
      </Box>

      <Box position="relative" mb={space.xLarge}>
        <Box
          position={{ md: 'absolute' }}
          zIndex={{ md: zIndex.above }}
          bottom={{ md: '72%' }}
          left={{ md: '38%' }}
        >
          <HeadingComponent
            as="h3"
            variant="secondary"
            mb={{ base: space.small, md: '0' }}
          >
            Nametags
          </HeadingComponent>
        </Box>
        <Box mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}>
          <img
            src={process.env.PUBLIC_URL + '/images/Nametags.png'}
            alt="How to use nametags"
          />
        </Box>
      </Box>

      <Box position="relative" mb={space.large}>
        <Box
          position={{ md: 'absolute' }}
          zIndex={{ md: zIndex.above }}
          bottom={{ md: '78%' }}
          left={{ md: '0' }}
          width={{ md: '45%' }}
        >
          <HeadingComponent
            as="h3"
            variant="secondary"
            mb={{ base: space.small, md: '0' }}
          >
            Exemplary application
          </HeadingComponent>
        </Box>

        <Box mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}>
          <img
            src={process.env.PUBLIC_URL + '/images/ExemplaryApplication.png'}
            alt="Examples of nametags placed on three different photos"
          />
        </Box>
      </Box>

      <HeadingComponent
        as="h3"
        variant="secondary"
        mb={{ base: space.small, md: '0' }}
      >
        Sound logo
      </HeadingComponent>

      <TextComponent limitedWidth mb={space.medium}>
        DWS has a distinctive sound logo, which should always be used for outros
        in film and audio media.
      </TextComponent>

      <Box mb={space.small}>
        <ButtonComponent
          variant="imageWrapper"
          onClick={playAudio}
          aria-label="Play sound"
        >
          <img src={process.env.PUBLIC_URL + '/images/Soundlogo.jpg'} alt="" />
        </ButtonComponent>
      </Box>

      <VStack align="stretch" spacing={2}>
        <DownloadFileComponent
          file="https://download.dws.com/download?elib-assetguid=a464ec0ff6fe49648e39e1a6bd68136a"
          fileType="Zip"
          fileSize="1.5 MB"
        >
          Intro / Outro
        </DownloadFileComponent>

        <DownloadFileComponent
          file="https://download.dws.com/download?elib-assetguid=8fd9e59d2af6490bb3300f1a741333ff"
          fileType="Zip"
          fileSize="1.5 MB"
        >
          Lower third
        </DownloadFileComponent>

        <DownloadFileComponent
          file={
            'https://download.dws.com/download?elib-assetguid=e3adb2b9a46b492c8fa36e1a82febbac'
          }
          fileType="Zip"
          fileSize="1.5 MB"
        >
          Sound logo
        </DownloadFileComponent>

        <DownloadFileComponent
          file={
            'https://download.dws.com/download?elib-assetguid=3785e3d9c7304ac392cb34b7341180b2'
          }
          fileType="Zip"
          fileSize="1.5 MB"
        >
          Videography quickguide
        </DownloadFileComponent>
      </VStack>
    </ContentSectionComponent>
  );
};
