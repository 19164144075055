import { Box } from '@chakra-ui/react';

import { AnchorNavigationLinkComponent } from 'components/AnchorNavigationLink';
import { PAGES } from 'constants/pages';
import { SECTIONS } from 'constants/sections';

type Props = {
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
};

export const BrandIdentity = ({ setOpen }: Props) => {
  return (
    <Box as="ul" listStyleType="none">
      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.OUR_BRAND_EXPERIENCE.anchor}
          href={`${PAGES.BRAND_IDENTITY.path}#${SECTIONS.OUR_BRAND_EXPERIENCE.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.OUR_BRAND_EXPERIENCE.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.CORE_VALUES.anchor}
          href={`${PAGES.BRAND_IDENTITY.path}#${SECTIONS.CORE_VALUES.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.CORE_VALUES.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.CHARACTERISTICS.anchor}
          href={`${PAGES.BRAND_IDENTITY.path}#${SECTIONS.CHARACTERISTICS.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.CHARACTERISTICS.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.KEY_MESSAGES.anchor}
          href={`${PAGES.BRAND_IDENTITY.path}#${SECTIONS.KEY_MESSAGES.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.KEY_MESSAGES.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.CLAIM.anchor}
          href={`${PAGES.BRAND_IDENTITY.path}#${SECTIONS.CLAIM.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.CLAIM.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.STRATEGIC_FRAMEWORK.anchor}
          href={`${PAGES.BRAND_IDENTITY.path}#${SECTIONS.STRATEGIC_FRAMEWORK.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.STRATEGIC_FRAMEWORK.title}
        </AnchorNavigationLinkComponent>
      </Box>
    </Box>
  );
};
