import React from 'react';

import { SimpleGrid, SimpleGridProps } from '@chakra-ui/react';

type SimpleColorGridProps = {
  children: React.ReactNode;
} & SimpleGridProps;

export const SimpleColorGridComponent = ({
  children,
  ...props
}: SimpleColorGridProps) => {
  return (
    <SimpleGrid columns={{ base: 2, sm: 3, lg: 6 }} spacing={3} {...props}>
      {children}
    </SimpleGrid>
  );
};
