import { Box } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

export const More = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.MORE.anchor}
      title={SECTIONS.MORE.title}
      {...props}
    >
      <Box mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}>
        <img
          src={process.env.PUBLIC_URL + '/images/Further.png'}
          alt="A composition of different brand assets (website, posters, banners etc.)"
        />
      </Box>
    </ContentSectionComponent>
  );
};
