import { Icon, IconProps } from '@chakra-ui/react';

const TrashIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 17 22" aria-hidden="true" fill="currentColor" {...props}>
      <path d="M.354 8.44658c-.18017.36062-.18017.72124-.18017 1.08186.18018.36061.36036.72126.72071.90156l.36036.1803c.18018 0 .18018.1803.36036 0h.18018l.18018-.1803 5.58554-9.1958c.18018-.18031 0-.54093-.18017-.72124l-.36036-.1803C6.48009-.02798 5.39902.15233 5.03866.87357l-.54054 1.08186v.18031l-.54053.36062-.18018-.18031c-.54054-.18031-1.08108 0-1.44143.36062L.89454 5.20101c-.36035.54093-.18017 1.08186.36036 1.44247l.18018.18031-.18018.36062L.354 8.44658zM5.5792 1.2342c.18018-.36062.72071-.54093 1.26125-.36062l.18018.18031-5.58555 9.01551-.18018-.18035c-.18018 0-.36036-.1803-.36036-.54092 0-.36062 0-.54093.18018-.72124l.72072-1.26217.36036-.72124 2.88286-4.50773.54054-.90155zM1.43508 6.10256c-.18018-.18031-.18018-.36062 0-.54093L3.05669 3.0373l.18018-.18031c.18018 0 .36036-.18031.36036 0l.18018.18031-1.98197 3.24557-.36036-.18031zM12.606 18.364c.1801 0 .3603-.1803.3603-.3606l.5406-6.6715c0-.3606 0-.3606-.1802-.5409-.1802 0-.3604.1803-.3604.3606l-.5405 6.8518c0 .1803 0 .3606.1802.3606zM7.74171 18.0034c0 .1803.18018.3606.36036.3606.18017 0 .36035-.1803.36035-.3606l-.72071-6.8518c0-.1803-.18018-.3606-.36036-.3606-.18018.1803-.18018.1803-.18018.3606l.54054 6.8518z" />
      <path d="M15.6691 9.70801c.3604 0 .5406-.18031.7208-.54092 0-.18031.1801-.36062.1801-.54093 0-.36062-.3603-.72124-.7207-.90155H4.85841c-.36036 0-.72071.36062-.72071.90155v.18031c0 .18031 0 .36062.18017.54092.18018.18031.36036.36062.72072.36062l.72072 9.19579c0 .3606.18017.5409.36035.7212v.9016c0 .5409.36036.9015.9009.9015h6.66664c.5405 0 .9009-.3606.9009-.9015v-.9016c.1802-.1803.3603-.3606.3603-.7212l.7207-9.19579zM4.85841 8.98678c0-.18031 0-.18031 0 0v-.36062c0-.18031.18018-.18031.18018-.36062H15.489c.1801 0 .1801.18031.1801.36062v.36062c0 .18031-.1801.18031-.1801.18031H5.03859c0-.18031-.18018-.18031-.18018-.18031zm.72072.72123h9.36927l-.7207 9.01549c0 .3606-.1802.5409-.5405.5409H7.02056c-.36036 0-.54054-.1803-.54054-.5409l-.90089-9.01549zM13.6872 20.7069H7.02056c-.18018 0-.36036-.1803-.36036-.3606v-.3606h7.2072v.5409c.1801.1803 0 .1803-.1802.1803z" />
      <path d="M10.2642 18.3646c.1801 0 .3603-.1803.3603-.3606v-7.0321c0-.1803-.1802-.3606-.3603-.3606-.1802 0-.1802.1803-.1802.3606v7.0321c0 .1803 0 .3606.1802.3606z" />
    </Icon>
  );
};

export default TrashIcon;
