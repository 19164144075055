import { ContentSectionComponent } from 'components/ContentSection';
import { NewsComponent } from 'components/News';
import { SECTIONS } from 'constants/sections';

export const LatestUpdates = () => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.LATEST_UPDATES.anchor}
      title={SECTIONS.LATEST_UPDATES.title}
    >
      <NewsComponent
        date="February 2022"
        dateTime="2022-02-01"
        imgAlt="A laughing woman close-up showing only her mouth, teeth, chin and some hairs with white text on top of it that reads: The new DWS brand experience."
        imgSrc={process.env.PUBLIC_URL + '/images/LatestUpdate.jpg'}
      >
        Following the transformation of our firm&apos;s business strategies, we
        are evolving our brand experience for today&apos;s challenges and those
        to come.
        <br />
        <br />
        Meaningful, open-minded, diverse and engaging. We are building a brand
        with fewer rules and greater opportunity for fun. This is a creative
        space with a simultaneous focus on increased brand character,
        distinctiveness, recognition and consistency. The major changes are
        summarized below.
      </NewsComponent>
    </ContentSectionComponent>
  );
};
