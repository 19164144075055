import * as React from 'react';

import { Box } from '@chakra-ui/react';

import { DividerComponent } from 'components/Divider';
import { HeadingComponent } from 'components/Heading';
import { PageFooterComponent } from 'components/PageFooter';
import { PAGES } from 'constants/pages';
import { DesignMasks } from 'sections/DesignMasks';
import { EmailSignature } from 'sections/EmailSignature';
import { EmailHeaderAndTemplates } from 'sections/EmailTemplates';
import { EventsWebinars } from 'sections/EventsWebinars';
import { More } from 'sections/More';
import { Presentations } from 'sections/Presentations';
import { ReportsProducts } from 'sections/ReportsProducts';
import { SocialMedia } from 'sections/SocialMedia';
import { Sponsoreship } from 'sections/Sponsoreship';
import { Stationery } from 'sections/Stationery';
import { WallpapersBackgrounds } from 'sections/WallpapersBackgrounds';
import { size, space } from 'theme/constants';

const BrandTemplatesAndFilesPage: React.FC = () => {
  document.title = `${PAGES.BRAND_TEMPLATES_AND_FILES.title} | DWS Brand Portal`;

  return (
    <>
      <Box
        as="main"
        maxWidth={size.contentMaxWidth}
        px={{ base: space.xSmall, lg: space.medium, xl: space.xLarge }}
      >
        <Box pt={{ base: space.small, lg: space.xLarge }}>
          <HeadingComponent as="h1" mb={1} variant="tertiary">
            {PAGES.BRAND_TEMPLATES_AND_FILES.title}
          </HeadingComponent>

          <More mb={space.large} />
          <DesignMasks />
          <DividerComponent />

          <Presentations />
          <DividerComponent />

          <SocialMedia />
          <DividerComponent />

          <Stationery />
          <DividerComponent />

          <ReportsProducts />
          <DividerComponent />

          <EventsWebinars />
          <DividerComponent />

          <EmailHeaderAndTemplates />
          <DividerComponent />

          <EmailSignature />
          <DividerComponent />

          <WallpapersBackgrounds />
          <DividerComponent />

          <Sponsoreship />
        </Box>
      </Box>

      <PageFooterComponent
        nextPageHref={PAGES.BRAND_NEW.path}
        nextPageName={PAGES.BRAND_NEW.title}
      />
    </>
  );
};

export default BrandTemplatesAndFilesPage;
