import * as React from 'react';

import Slider from 'react-slick';

import ArrowLeftIcon from 'components/Icon/ArrowLeftIcon';
import ArrowRightIcon from 'components/Icon/ArrowRightIcon';

type ArrowProps = {
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

function ArrowRight(props: ArrowProps) {
  const { className, onClick } = props;
  return (
    <button className={className} onClick={onClick} type="button">
      <ArrowRightIcon boxSize={6} aria-label="Next Logo" />
    </button>
  );
}

function ArrowLeft(props: ArrowProps) {
  const { className, onClick } = props;
  return (
    <button className={className} onClick={onClick} type="button">
      <ArrowLeftIcon boxSize={6} aria-label="Previous Logo" />
    </button>
  );
}

type CarouselProps = {
  children: React.ReactNode;
};

export const CarouselComponent = ({ children }: CarouselProps) => {
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 300,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
  };

  return <Slider {...settings}>{children}</Slider>;
};
