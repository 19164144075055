import { Box } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import VideoPlayerComponent from 'components/VideoPlayer';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

export const OurBrandExperiece = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.OUR_BRAND_EXPERIENCE.anchor}
      title={SECTIONS.OUR_BRAND_EXPERIENCE.title}
      {...props}
    >
      <Box mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}>
        {/* Notice: the image will be substituted by a video later */}
        <VideoPlayerComponent
          poster={process.env.PUBLIC_URL + '/images/DWSBrandExperience.jpg'}
          source={
            process.env.PUBLIC_URL + '/videos/DWSBrandExperienceVideo.mp4'
          }
        />
        {/*<video*/}
        {/*  width={'100%'}*/}
        {/*  height={'100%'}*/}
        {/*  id={'videoPlayer'}*/}
        {/*  poster={process.env.PUBLIC_URL + '/images/DWSBrandExperience.jpg'}*/}
        {/*  controls*/}
        {/*  src={process.env.PUBLIC_URL + '/videos/DWSBrandExperienceVideo.mp4'}*/}
        {/*>*/}
        {/*  <track*/}
        {/*    src={'Our brand experiece'}*/}
        {/*    kind={'captions'}*/}
        {/*    label={'OurBrandExperienceCaption'}*/}
        {/*  />*/}
        {/*  <p>Your browser can not </p>*/}
        {/*</video>*/}
      </Box>
    </ContentSectionComponent>
  );
};
