import { Box, Grid, GridItem } from '@chakra-ui/react';

import { CardComponent } from 'components/Card';
import { ContentSectionComponent } from 'components/ContentSection';
import { HeadingComponent } from 'components/Heading';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

export const DesignMasks = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.DESIGN_MASKS.anchor}
      title={SECTIONS.DESIGN_MASKS.title}
      {...props}
    >
      <Box
        mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.small}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/BaseDesigns.png'}
          alt="A composition of different brand assets (mainly posters, banners and magazine pages)"
        />
      </Box>

      <HeadingComponent as="h3" variant="secondary" mb={space.small}>
        Design mask templates
      </HeadingComponent>

      <Grid
        templateColumns={{ base: '1fr', md: '1fr 1fr' }}
        gap={space.xSmall}
        mb={space.medium}
      >
        <GridItem display="flex" flexDirection="column">
          <CardComponent
            file={
              'https://download.dws.com/download?elib-assetguid=1860e336066a4728ba6a312ac6397f14'
            }
            fileType="Zip"
            fileSize="43.5 MB"
            headingLevel="h4"
            imgAlt="Design mask package 01"
            imgSrc="BaseDesignTemplates01.png"
            title="Design mask package 01"
          />
        </GridItem>

        <GridItem display="flex" flexDirection="column">
          <CardComponent
            file={
              'https://download.dws.com/download?elib-assetguid=2078ab7f39aa4cc18417ed4eca27887d'
            }
            fileType="Zip"
            fileSize="142.5 MB"
            headingLevel="h4"
            imgAlt="Design mask package 02"
            imgSrc="BaseDesignTemplates02.png"
            title="Design mask package 02"
          />
        </GridItem>
      </Grid>
    </ContentSectionComponent>
  );
};
