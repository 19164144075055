import { createRef, useState } from 'react';

import { Flex, IconButton } from '@chakra-ui/react';
import { IoPlayOutline, IoPauseOutline } from 'react-icons/io5';

type VideoPlayerProps = {
  poster: string;
  source: string;
};

export default function VideoPlayerComponent({
  poster,
  source,
}: VideoPlayerProps) {
  const [isPlaying, setPlaying] = useState(false);
  const videoRef = createRef<HTMLVideoElement>();

  function onClickPlayPauseHandler() {
    if (isPlaying) {
      videoRef.current?.pause();
    } else {
      videoRef.current?.play();
    }
    setPlaying(!isPlaying);
  }

  return (
    <Flex
      width="100%"
      justifyContent={'center'}
      alignItems={'center'}
      onClick={onClickPlayPauseHandler}
      color={'transparent'}
      _hover={{ color: 'white' }}
    >
      <video
        ref={videoRef}
        playsInline
        poster={poster}
        style={{ width: '100%', height: '100%' }}
        onEnded={onClickPlayPauseHandler}
      >
        <source src={source} />
        <track
          src={'Our brand experiece'}
          kind={'captions'}
          label={'OurBrandExperienceCaption'}
        />
      </video>
      <IconButton
        _focus={{
          boxShadow: 'hidden',
        }}
        _hover={{
          backgroundColor: 'gray.500',
        }}
        background={'transparent'}
        height={'auto'}
        color={isPlaying ? 'inherit' : 'white'}
        position={'absolute'}
        aria-label="Play"
        id={'play_button'}
        onClick={onClickPlayPauseHandler}
        icon={
          !isPlaying ? (
            <IoPlayOutline size={56} />
          ) : (
            <IoPauseOutline size={56} />
          )
        }
      />
    </Flex>
  );
}
