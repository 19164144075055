import { Link, LinkProps } from '@chakra-ui/react';

type VariantType = 'primary' | 'secondary';

type Props = {
  children: string;
  variant?: VariantType;
} & Pick<LinkProps, 'href'>;

const usePrimary = (): LinkProps => {
  return {
    textDecoration: 'underline',
    color: 'text.highlight',

    _focus: {
      textDecoration: 'none',
    },

    _hover: {
      textDecoration: 'none',
    },

    _active: {
      textDecoration: 'underline',
    },
  };
};

const useSecondary = (): LinkProps => {
  return {
    _focus: {
      color: 'text.highlight',
    },

    _hover: {
      color: 'text.highlight',
      textDecoration: 'underline',
    },

    _active: {
      textDecoration: 'none',
    },
  };
};

const useLinkVariants = (): Record<VariantType, LinkProps> => {
  return {
    primary: usePrimary(),
    secondary: useSecondary(),
  };
};

export const LinkComponent = ({
  children,
  variant = 'primary',
  ...props
}: Props) => {
  const variantStyles = useLinkVariants()[variant];

  const styles = {
    ...variantStyles,
    ...props,
  };

  return <Link {...styles}>{children}</Link>;
};
