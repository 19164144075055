export const SECTIONS = {
  EMPLOYER_BRAND_CLIM: {
    anchor: 'employer-brand-claim',
    title: 'Claim',
  },
  AUDIENCE_SEGMENTATION: {
    anchor: 'audience-segmentation',
    title: 'Audience segmentation',
  },
  PROFESSIONAL_HIRES: {
    anchor: 'professional-hires',
    title: 'Professional hires',
  },
  EARLY_CAREERS: {
    anchor: 'early-careers',
    title: 'Early careers',
  },
  INTRODUCTION_EMPLOYER_BRAND: {
    anchor: 'introduction-employer-brand',
    title: 'Introduction to the Employer Brand',
  },
  TONE_OF_VOICE: {
    anchor: 'tone-of-voice',
    title: 'Tone of Voice',
  },
  COMPANY_PROFILES: {
    anchor: 'company-profiles',
    title: 'Company profiles',
  },
  EMPLOYER_INTRODUCTION: {
    anchor: 'employer-introduction',
    title: 'Introduction',
  },
  OVERVIEW: {
    anchor: 'overview',
    title: 'Overview',
  },
  THREE_CORE_PILLARS: {
    anchor: 'three-core-pillars',
    title: 'Three core pillars',
  },
  DIFFERENCE_EVP_EMPLOYER_BRAND: {
    anchor: 'difference-evp-employer-brand',
    title: 'What is the difference between our EVP and Employer Brand?',
  },
  DESIGN_MASKS: {
    anchor: 'design-masks',
    title: 'Design masks',
  },
  ICONOGRAPHY: {
    anchor: 'iconography',
    title: 'Iconography',
  },
  CHARTS: {
    anchor: 'charts',
    title: 'Charts',
  },
  BASIC_APPLICATION: {
    anchor: 'basic-application',
    title: 'Basic application',
  },
  BASIC_FRAMEWORK: {
    anchor: 'basic-framework',
    title: 'Basic framework',
  },
  CHARACTERISTICS: {
    anchor: 'characteristics',
    title: 'Characteristics',
  },
  CLAIM: {
    anchor: 'claim',
    title: 'Claim',
  },
  COLOR_SYSTEM: {
    anchor: 'color-system',
    title: 'Refined color system',
  },
  COLORS: {
    anchor: 'colors',
    title: 'Colors',
  },
  CORE_VALUES: {
    anchor: 'core-values',
    title: 'Core values',
  },
  DARK_BRIGHT_BALANCE: {
    anchor: 'dark-and-bright-balance',
    title: 'Rebalanced look & feel',
  },
  DESIGN_SYSTEM: {
    anchor: 'design-system',
    title: 'Design system',
  },
  DIGITAL_BRAND: {
    anchor: 'digital-brand',
    title: 'Digital brand',
  },
  DESIGN_SYSTEM_ELEMENTS: {
    anchor: 'design-system-and-elements',
    title: 'Evolving the design system',
  },
  OUR_BRAND_EXPERIENCE: {
    anchor: 'our-brand-experience',
    title: 'Our brand experience',
  },
  EMAIL_HEADERS_AND_TEMPLATES: {
    anchor: 'email-headers-and-templates',
    title: 'Email headers & templates (Outlook)',
  },
  EMAIL_SIGNATURE: {
    anchor: 'email-signature',
    title: 'Email signature',
  },
  EVENTS_WEBINARS: {
    anchor: 'events-and-webinars',
    title: 'Events & webinars',
  },
  MORE: {
    anchor: 'more',
    title: 'More',
  },
  IMAGERY: {
    anchor: 'imagery',
    title: 'Imagery',
  },
  IMAGERY_VISUAL_EXPERIENCE: {
    anchor: 'imagery-and-visual-experience',
    title: 'Reinventing our imagery',
  },
  INTRODUCTION: {
    anchor: 'introduction',
    title: 'The essentials of our brand',
  },
  KEY_MESSAGES: {
    anchor: 'key-messages',
    title: 'Key messages',
  },
  LATEST_UPDATES: {
    anchor: 'latest-updates',
    title: 'Latest updates',
  },
  LOGO_SIMPLIFICATION: {
    anchor: 'logo-simplification',
    title: 'Simplified logo',
  },
  SPONSORSHIP: {
    anchor: 'sponsorship',
    title: 'Sponsorship',
  },
  PRESENTATIONS: {
    anchor: 'presentations',
    title: 'Presentations',
  },
  REPORTS_PRODUCTS: {
    anchor: 'reports-and-products',
    title: 'Reports & products',
  },
  SHOWCASE_THE_POSSIBLE: {
    anchor: 'showcase-the-possibilities',
    title: 'Showcase the possibilities',
  },
  SOCIAL_MEDIA: {
    anchor: 'social-media',
    title: 'Social media',
  },
  SOUND_LOGO: {
    anchor: 'sound-logo',
    title: 'Sound logo',
  },
  STAFF_PHOTOGRAPHY_PORTRAITS: {
    anchor: 'staff-photography-portraits',
    title: 'Staff photography / portraits',
  },
  STATIONERY: {
    anchor: 'stationery-word',
    title: 'Stationery (Word)',
  },
  STRATEGIC_FRAMEWORK: {
    anchor: 'strategic-framework',
    title: 'Building a strategic brand frame',
  },
  THE_LOGO: {
    anchor: 'the-logo',
    title: 'The logo',
  },
  TYPOGRAPHY: {
    anchor: 'typography',
    title: 'Typography',
  },
  TYPOGRAPHY_SYSTEM: {
    anchor: 'typography-system',
    title: 'Adjusted typography application',
  },
  VIDEOGRAPHY: {
    anchor: 'videography',
    title: 'Videography',
  },
  WALLPAPERS_BACKGROUNDS: {
    anchor: 'virtual-and-desktop-backgrounds',
    title: 'Virtual & desktop backgrounds',
  },
  XTRACKERS_SPECIFICS: {
    anchor: 'xtrackers-specifics',
    title: 'Xtrackers specifics',
  },
};
