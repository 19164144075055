import * as React from 'react';

import { Box } from '@chakra-ui/react';

import { DividerComponent } from 'components/Divider';
import { HeadingComponent } from 'components/Heading';
import { PageFooterComponent } from 'components/PageFooter';
import { PAGES } from 'constants/pages';
import { ColorSystem } from 'sections/ColorSystem';
import { DarkBrightBalance } from 'sections/DarkBrightBalance';
import { DesignSystemElements } from 'sections/DesignSystemElements';
import { ImageryVisualExperience } from 'sections/ImageryVisualExperience';
import { LatestUpdates } from 'sections/LatestUpdates';
import { LogoSimplification } from 'sections/LogoSimplification';
import { Showcase } from 'sections/Showcase';
import { TypographySystem } from 'sections/TypographySystem';
import { size, space } from 'theme/constants';

const BrandNewPage: React.FC = () => {
  document.title = `${PAGES.BRAND_NEW.title} | DWS Brand Portal`;

  return (
    <>
      <Box
        as="main"
        maxWidth={size.contentMaxWidth}
        px={{ base: space.xSmall, lg: space.medium, xl: space.xLarge }}
      >
        <Box pt={{ base: space.small, lg: space.xLarge }}>
          <HeadingComponent as="h1" mb={1} variant="tertiary">
            {PAGES.BRAND_NEW.title}
          </HeadingComponent>

          <LatestUpdates />
          <DividerComponent />

          <LogoSimplification />
          <DividerComponent />

          <DarkBrightBalance />
          <DividerComponent />

          <ColorSystem />
          <DividerComponent />

          <TypographySystem />
          <DividerComponent />

          <DesignSystemElements />
          <DividerComponent />

          <ImageryVisualExperience />
          <DividerComponent />

          <Showcase />
        </Box>
      </Box>

      <PageFooterComponent
        nextPageHref={PAGES.BRAND_IDENTITY.path}
        nextPageName={PAGES.BRAND_IDENTITY.title}
      />
    </>
  );
};

export default BrandNewPage;
