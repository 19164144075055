import { Box, VStack } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { DownloadFileComponent } from 'components/DownloadFile';
import { HeadingComponent } from 'components/Heading';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

export const EventsWebinars = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.EVENTS_WEBINARS.anchor}
      title={SECTIONS.EVENTS_WEBINARS.title}
      {...props}
    >
      <HeadingComponent as="h3" variant="secondary" mb={space.xxSmall}>
        Event materials
      </HeadingComponent>

      <Box
        mb={space.small}
        mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/EventMaterials.jpg'}
          alt="Example event materials"
        />
      </Box>

      <VStack align="stretch" spacing={2} mb={space.large}>
        <DownloadFileComponent
          file="https://download.dws.com/download?elib-assetguid=73f6483f35f249b0a04e0d9a04dd0643"
          fileType="Zip"
          fileSize="10.5 MB"
        >
          Event templates (MS Office)
        </DownloadFileComponent>

        <DownloadFileComponent
          file="https://download.dws.com/download?elib-assetguid=941f7120797a42b49c9cdbd819513b18"
          fileType="Zip"
          fileSize="10.5 MB"
        >
          Event templates (InDesign)
        </DownloadFileComponent>
      </VStack>

      <HeadingComponent as="h3" variant="secondary" mb={space.small}>
        ESG Talks
      </HeadingComponent>

      <Box
        mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.small}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/ESGTalks.jpg'}
          alt="Example ESG Talks stationaries"
        />
      </Box>

      <DownloadFileComponent
        mb={space.large}
        file="https://download.dws.com/download?elib-assetguid=b6fe9bc87f2f4c0a9fad3244ccb8d078"
        fileType="Zip"
        fileSize="10.5 MB"
      >
        ESG Talks templates (PPT)
      </DownloadFileComponent>

      <HeadingComponent as="h3" variant="secondary" mb={space.small}>
        BrightTalk webinar
      </HeadingComponent>

      <Box
        mb={space.small}
        mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/BrightTalkWebinar.jpg'}
          alt="A laptop showing a webinar template"
        />
      </Box>

      <DownloadFileComponent
        mb={space.large}
        file={
          'https://download.dws.com/download?elib-assetguid=49e60eae53ef41ea980a2c0521eea9b1'
        }
        fileType="Zip"
        fileSize="2.1 MB"
      >
        BrightTalk webinar templates (PPT)
      </DownloadFileComponent>
    </ContentSectionComponent>
  );
};
