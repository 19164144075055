import React from 'react';

import { Flex, BoxProps } from '@chakra-ui/react';

import { TextComponent } from 'components/Text';
import { space, transition } from 'theme/constants';

import LinkIcon from '../Icon/LinkIcon';

type Props = {
  link: string;
  children: React.ReactNode;
} & BoxProps;

export const LinkBar = ({ link, children, ...props }: Props) => {
  return (
    <Flex
      as="a"
      href={link}
      align="center"
      justify="space-between"
      p={8}
      textDecoration="none"
      color="text.primaryDark"
      bgColor="background.nearWhite"
      transition={`background-color ${transition.default}`}
      sx={{
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
      }}
      _focus={{
        bgColor: 'background.neutral',
      }}
      _hover={{
        bgColor: 'background.neutral',
      }}
      _active={{
        transform: 'translateY(2px)',
      }}
      {...props}
    >
      <Flex direction="column" flex="1" mr={space.xSmall}>
        <TextComponent variant="large" as="span">
          {children}
        </TextComponent>
      </Flex>

      <LinkIcon boxSize={16} />
    </Flex>
  );
};
