import React from 'react';

import { Box } from '@chakra-ui/react';

import { HeadingComponent } from '../components/Heading';
import { PageFooterComponent } from '../components/PageFooter';
import { PAGES } from '../constants/pages';
import { DifferenceEVPAndEmployerBrand } from '../sections/DifferenceEVPAndEmployerBrand';
import { Overview } from '../sections/Overview';
import { ThreeCorePillars } from '../sections/ThreeCorePillars';
import { size, space } from '../theme/constants';

const EmployerValueProposition: React.FC = () => {
  document.title = `${PAGES.EMPLOYER_VALUE_PROPOSITION.title} | DWS Brand Portal`;

  return (
    <>
      <Box
        as={'main'}
        maxWidth={size.contentMaxWidth}
        px={{ base: space.xSmall, lg: space.medium, xl: space.xLarge }}
      >
        <Box pt={{ base: space.small, lg: space.xLarge }}>
          <HeadingComponent as="h1" mb={1} variant="tertiary">
            {PAGES.EMPLOYER_VALUE_PROPOSITION.title}
          </HeadingComponent>

          <Overview mb={space.xxLarge} />
          <ThreeCorePillars mb={space.xxLarge} />
          <DifferenceEVPAndEmployerBrand mb={space.xxLarge} />
        </Box>
      </Box>

      <PageFooterComponent
        nextPageHref={PAGES.EMPLOYER_BRAND.path}
        nextPageName={PAGES.EMPLOYER_BRAND.title}
      />
    </>
  );
};

export default EmployerValueProposition;
