import * as React from 'react';

import { HeadingProps, Heading } from '@chakra-ui/react';

type VariantType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'quaternary'
  | 'quinary'
  | 'hidden';

type Props = {
  children: React.ReactNode;
  variant?: VariantType;
} & Pick<HeadingProps, 'as' | 'color' | 'mb' | 'sx' | 'textAlign' | 'mx'>;

const baseStyle: HeadingProps = {
  fontWeight: '400',
};

const primary: HeadingProps = {
  fontFamily: "'DWS Slab', Arial, sans-serif",
  fontSize: '40px',
  lineHeight: '1.1',
  color: 'text.primaryDark',
};

const secondary: HeadingProps = {
  fontFamily: "'DWS Slab', Arial, sans-serif",
  fontSize: '27px',
  lineHeight: '1.33333333',
  color: 'text.primaryDark',
};

const tertiary: HeadingProps = {
  fontFamily: "'DWS Sans', Arial, sans-serif",
  fontSize: '20px',
  lineHeight: '1.3',
  color: 'text.secondaryDark',
};

const quaternary: HeadingProps = {
  fontFamily: "'DWS Sans', Arial, sans-serif",
  fontSize: '12px',
  lineHeight: '1.5',
  color: 'text.secondaryDark',
};

const quinary: HeadingProps = {
  fontFamily: "'DWS Sans', Arial, sans-serif",
  fontSize: '12px',
  fontWeight: '700',
  lineHeight: '1.5',
  textTransform: 'uppercase',
  color: 'text.primaryDark',
};

const hidden: HeadingProps = {
  overflow: 'hidden',
  position: 'absolute',
  width: '1px',
  height: '1px',
  border: '0',
  padding: '0',
  whiteSpace: 'nowrap',
  clipPath: 'inset(50%)',
};

const headingVariants: Record<VariantType, HeadingProps> = {
  primary,
  secondary,
  tertiary,
  quaternary,
  quinary,
  hidden,
};

export const HeadingComponent = ({
  children,
  variant = 'primary',
  ...props
}: Props) => {
  const variantStyles = headingVariants[variant];

  const styles = {
    ...baseStyle,
    ...variantStyles,
    ...props,
  };

  return <Heading {...styles}>{children}</Heading>;
};
