export const PAGES = {
  BRAND_NEW: {
    path: '/brand-new',
    title: 'Brand new',
  },
  BRAND_IDENTITY: {
    path: '/brand-identity',
    title: 'Brand identity',
  },
  BRAND_ESSENTIALS_AND_GUIDES: {
    path: '/brand-essentials-and-guides',
    title: 'Brand essentials & guides',
  },
  BRAND_TEMPLATES_AND_FILES: {
    path: '/brand-templates-and-files',
    title: 'Brand templates & files',
  },
  INTRODUCTION: {
    path: '/introduction',
    title: 'Introduction',
  },
  EMPLOYER_VALUE_PROPOSITION: {
    path: '/employer-value-proposition',
    title: 'Employer Value Proposition (EVP)',
  },
  EMPLOYER_BRAND: {
    path: '/employer-brand',
    title: 'Employer Brand',
  },
  AUDIENCE_SEGMENTATION: {
    path: '/audience-segmentation',
    title: 'Audience segmentation',
  },
};
