import { Box, VStack } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { DownloadFileComponent } from 'components/DownloadFile';
import { HeadingComponent } from 'components/Heading';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

export const ReportsProducts = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.REPORTS_PRODUCTS.anchor}
      title={SECTIONS.REPORTS_PRODUCTS.title}
      {...props}
    >
      <HeadingComponent as="h3" variant="secondary" mb={space.xxSmall}>
        Report templates
      </HeadingComponent>

      <Box
        mb={space.small}
        mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/ReportTemplates.jpg'}
          alt="Three example reports laying on top of each other"
        />
      </Box>

      <VStack align="stretch" spacing={2} mb={space.large}>
        <DownloadFileComponent
          file={
            'https://download.dws.com/download?elib-assetguid=4af80123decb4f18b67f523e6b01481c'
          }
          fileType="Zip"
          fileSize="1.35 GB"
        >
          Report A4 templates (Word & PPT)
        </DownloadFileComponent>

        <DownloadFileComponent
          file={
            'https://download.dws.com/download?elib-assetguid=5dbadc3209bf4cd38d6317d8daf3faf2'
          }
          fileType="Zip"
          fileSize="1.35 GB"
        >
          Report US Letter templates (Word & PPT)
        </DownloadFileComponent>
      </VStack>

      <HeadingComponent as="h3" variant="secondary" mb={space.small}>
        Product templates
      </HeadingComponent>

      <Box
        mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.small}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/ProductTemplates.jpg'}
          alt="Two pages of example product pages laying on top of each other"
        />
      </Box>

      <VStack align="stretch" spacing={2}>
        <DownloadFileComponent
          file="https://download.dws.com/download?elib-assetguid=2a6a9db5c61847bf9533c8fb7e5b0377"
          fileType="Zip"
          fileSize="10.5 MB"
        >
          Product A4 templates (PPT & InDesign)
        </DownloadFileComponent>

        <DownloadFileComponent
          file="https://download.dws.com/download?elib-assetguid=a5b07673ab0440cf943cd07980814a45"
          fileType="Zip"
          fileSize="10.5 MB"
        >
          Product US Letter templates (PPT & InDesign)
        </DownloadFileComponent>
      </VStack>
    </ContentSectionComponent>
  );
};
