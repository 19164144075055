import { Box, HStack, VStack } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { DownloadFileComponent } from 'components/DownloadFile';
import { TextComponent } from 'components/Text';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

export const XtrackersSpecifics = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.XTRACKERS_SPECIFICS.anchor}
      title={SECTIONS.XTRACKERS_SPECIFICS.title}
      {...props}
    >
      <TextComponent limitedWidth mb={space.small}>
        DWS Xtrackers has a distinctive look & feel based on the DWS brand.
      </TextComponent>

      <Box
        mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.small}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/DedicatedLogoPrimaryColor.png'}
          alt="Dedicated Logo & Primary Color Usage"
        />
      </Box>

      <Box
        mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.small}
      >
        <HStack>
          <TextComponent>
            All design templates come with a bright and dark version. Further
            instructions are included in the design files.
          </TextComponent>
          <img
            width={'65%'}
            src={process.env.PUBLIC_URL + '/images/XtrackersInstructions.png'}
            alt="Various formats examples"
          />
        </HStack>
      </Box>

      <Box
        mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.small}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/XtrackerGrid1.png'}
          alt="Dedicated Logo & Primary Color Usage"
        />
      </Box>

      <Box
        mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.small}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/XtrackerGrid2.png'}
          alt="Dedicated Logo & Primary Color Usage"
        />
      </Box>

      <VStack align="stretch" spacing={2} mb={space.large}>
        <DownloadFileComponent
          file={
            'https://download.dws.com/download?elib-assetguid=c6aa194bb2644245821336de254cf5d5'
          }
          fileType="Zip"
          fileSize="181.1 MB"
        >
          Xtrackers by DWS templates (InDesign)
        </DownloadFileComponent>

        <DownloadFileComponent
          file={
            'https://download.dws.com/download?elib-assetguid=61a7479350084509b3e84f53eef1dd30'
          }
          fileType="Zip"
          fileSize="181.1 MB"
        >
          Xtrackers by DWS templates (MS Office)
        </DownloadFileComponent>

        <DownloadFileComponent
          file={
            'https://download.dws.com/download?elib-assetguid=b04e846eb76c460fa9cc543b205478ac'
          }
          fileType="Zip"
          fileSize="181.1 MB"
        >
          Xtrackers by DWS quickguide
        </DownloadFileComponent>
      </VStack>
    </ContentSectionComponent>
  );
};
