import { Icon, Flex } from '@chakra-ui/react';

import { DARK_PETROL } from 'theme';

export const SupportButton = () => {
  return (
    <Flex
      as={'a'}
      width={'100%'}
      justifyContent={'center'}
      alignItems={'center'}
      height={'100%'}
      color={'white'}
      backgroundColor={'transparent'}
      _hover={{ color: 'text.highlight' }}
      _focus={{ color: 'text.highlight' }}
      _active={{ color: DARK_PETROL }}
      href="mailto:sammi.ho@dws.com; marusa.arian@dws.com"
    >
      <Icon viewBox="0 0 400 400" height={'auto'} width={'2rem'}>
        <svg
          fill="inherit"
          version="1.1"
          id="Ebene_1"
          x="0px"
          y="0px"
          viewBox="0 0 263.6 211.1"
        >
          <g id="Ebene_2_00000044889355067469964740000017420088117761611957_">
            <g id="Ebene_1-2">
              <path
                fill="currentColor"
                d="M185.2,105.8c-6.9,0-12.5-5.6-12.5-12.4s5.6-12.5,12.4-12.5c6.9,0,12.5,5.6,12.5,12.4c0,0,0,0,0,0
			C197.6,100.3,192,105.8,185.2,105.8 M131.8,105.8c-6.9,0-12.5-5.6-12.5-12.4s5.6-12.5,12.4-12.5c6.9,0,12.5,5.6,12.5,12.4
			c0,0,0,0,0,0C144.2,100.3,138.7,105.8,131.8,105.8 M78.4,105.8c-6.9,0-12.5-5.6-12.5-12.4s5.6-12.5,12.4-12.5
			c6.9,0,12.5,5.6,12.5,12.4c0,0,0,0,0,0C90.8,100.3,85.3,105.8,78.4,105.8 M131.8,0C59,0,0,42.3,0,94.6c0,24.6,13.1,47.1,34.7,63.9
			l-24.1,52.6l67.2-30.2c17.5,5.6,35.7,8.4,54,8.3c72.8,0,131.8-42.3,131.8-94.6S204.6,0,131.8,0"
              />
            </g>
          </g>
        </svg>
      </Icon>
    </Flex>
  );
};
