import { Box } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { TextComponent } from 'components/Text';
import { PAGES } from 'constants/pages';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

export const DarkBrightBalance = () => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.DARK_BRIGHT_BALANCE.anchor}
      moreDetailsUrl={`${PAGES.BRAND_IDENTITY.path}#${SECTIONS.OUR_BRAND_EXPERIENCE.anchor}`}
      title={SECTIONS.DARK_BRIGHT_BALANCE.title}
    >
      <Box
        mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.xSmall}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/DarkBrightBalance.jpg'}
          alt="Hard edged gradient from black to white with text on it, that reads: Less Dark More Bright"
        />
      </Box>

      <TextComponent>
        We rebalanced the dark-to-light ratio to maintain our brand recognition
        and allow flexibility for future requirements. This change is our first
        step towards a less exclusive — and more friendly, open-minded and
        humble brand.
      </TextComponent>
    </ContentSectionComponent>
  );
};
