import { Box, VStack } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { DownloadFileComponent } from 'components/DownloadFile';
import { TextComponent } from 'components/Text';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

import { LinkBar } from '../../components/LinkBar';

export const Iconography = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.ICONOGRAPHY.anchor}
      title={SECTIONS.ICONOGRAPHY.title}
      isInverted
      {...props}
    >
      <TextComponent limitedWidth mb={space.small} color={'text.primaryLight'}>
        The complete collection of assorted, ready-for-use icons
      </TextComponent>

      <Box
        mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.small}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/Iconography.jpg'}
          alt="A tablet showing the icon styleguide"
        />
      </Box>

      <VStack align={'stretch'} spacing={2} mb={space.large}>
        <DownloadFileComponent
          file={
            'https://download.dws.com/download?elib-assetguid=0db93e609e554f9abb4f8c561ef11e6c'
          }
          fileType="Zip"
          fileSize="34.9 MB"
        >
          Iconography collection (PPT)
        </DownloadFileComponent>

        <LinkBar link={'https://mediamanager.dws.com'}>
          Iconography library
        </LinkBar>

        <DownloadFileComponent
          file={
            'https://download.dws.com/download?elib-assetguid=af9fe2ff9e914dcb8cdc49ee23c708cb'
          }
          fileType="Zip"
          fileSize="34.9 MB"
        >
          Iconography quickguide
        </DownloadFileComponent>
      </VStack>
    </ContentSectionComponent>
  );
};
