import React from 'react';

import { Box } from '@chakra-ui/react';

import { HeadingComponent } from '../components/Heading';
import { PageFooterComponent } from '../components/PageFooter';
import { PAGES } from '../constants/pages';
import { CompanyProfiles } from '../sections/CompanyProfiles';
import { EmployerBrandClaim } from '../sections/EmployerBrandClaim';
import { IntroductionEmployerBrandSection } from '../sections/IntroductionEmployerBrand';
import { ToneOfVoice } from '../sections/ToneOfVoice';
import { size, space } from '../theme/constants';

const EmployerBrand: React.FC = () => {
  document.title = `${PAGES.EMPLOYER_BRAND.title} | DWS Brand Portal`;

  return (
    <>
      <Box
        as={'main'}
        maxWidth={size.contentMaxWidth}
        px={{ base: space.xSmall, lg: space.medium, xl: space.xLarge }}
      >
        <Box pt={{ base: space.small, lg: space.xLarge }}>
          <HeadingComponent as="h1" mb={1} variant="tertiary">
            {PAGES.EMPLOYER_BRAND.title}
          </HeadingComponent>

          <IntroductionEmployerBrandSection mb={space.xxLarge} />
          <ToneOfVoice mb={space.xxLarge} />
          <EmployerBrandClaim pb={space.small} mb={space.xLarge} />
          <CompanyProfiles mb={space.xxLarge} />
        </Box>
      </Box>

      <PageFooterComponent
        nextPageHref={PAGES.AUDIENCE_SEGMENTATION.path}
        nextPageName={PAGES.AUDIENCE_SEGMENTATION.title}
      />
    </>
  );
};

export default EmployerBrand;
