import { Box, Flex } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

import { TextComponent } from '../../components/Text';

interface EmployerIntroductionProps {
  date: string;
  dateTime: string;
}
export const EmployerIntroduction = ({
  date,
  dateTime,
  ...props
}: EmployerIntroductionProps) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.EMPLOYER_INTRODUCTION.anchor}
      title={SECTIONS.EMPLOYER_INTRODUCTION.title}
      {...props}
    >
      <Flex
        align="center"
        mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.small}
        _after={{
          content: "''",
          flex: '1',
          width: '100%',
          height: '1px',
          bgColor: 'background.highlight',
        }}
      >
        <Box
          as="time"
          dateTime={dateTime}
          px={4}
          py={2}
          color="text.primaryLight"
          bgColor="background.highlight"
        >
          <TextComponent as="span" variant="tiny" display="block">
            {date}
          </TextComponent>
        </Box>
      </Flex>
      <Box
        mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.xSmall}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/employerbrand_main-min.jpg'}
          alt="A woman locking up with a text"
        />
      </Box>

      <TextComponent variant={'medium'} mb={space.large}>
        Our Employer Brand is designed to help you to communicate with DWS
        employees and potential candidates. It will help you to promote us as an
        employer of choice, highlights what you can expect from working at DWS
        and underlines why it’s a great place to work.
        <br />
        <br />
        It builds on our Employer Value Proposition (EVP) which was developed to
        help us attract and retain talent by articulating the benefits of
        working at DWS. It differentiates us from our competitors, supports us
        in finding the right people at the right time, and helps them to make
        informed decisions about building their careers with us.
        <br />
        <br />
        Throughout this portal we explain what our EVP is, and how to execute
        our Employer Brand effectively for different audiences when creating
        print, digital and social media assets. We’ve also included information
        about our messaging, our look and feel, audience insights and everything
        else you need to create brand-compliant assets to engage and inspire our
        people and potential candidates.
        <br />
        <br />
        Following these guidelines, together with your local recruitment and
        marketing knowledge, will help you to attract the best possible
        candidates, convert them to applicants, make outstanding hires, and
        retain top talent. It will also save you time and energy while
        delivering a consistent standard of communication across every role, in
        every region.
        <br />
        <br />
        If you have any questions about anything relating to our Employer Brand
        or EVP, please contact{' '}
        <a href="mailto:dws.talentacquisition@db.com">
          dws.talentacquisition@db.com
        </a>
      </TextComponent>
    </ContentSectionComponent>
  );
};
