import {
  AspectRatio,
  Box,
  BoxProps,
  GridItem,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';

import { TextComponent, VariantType } from 'components/Text';

type ColorGridItemProps = {
  color: string;
  headline: string;
  hexCode: string;
  hasBorder: boolean;
} & BoxProps;

export const ColorGridItemComponent = ({
  color,
  headline,
  hexCode,
  hasBorder,
  ...props
}: ColorGridItemProps) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const textSize: VariantType = isDesktop ? 'small' : 'tiny';

  return (
    <GridItem width={'100%'} {...props}>
      <AspectRatio width={'100%'} ratio={1}>
        <Box
          bgColor={color}
          border={hasBorder ? '2px solid black' : undefined}
        />
      </AspectRatio>
      <VStack spacing={1} w={'full'}>
        <TextComponent variant={textSize} textAlign={'left'} w={'90%'}>
          {headline}
        </TextComponent>
        <TextComponent variant={textSize} textAlign={'left'} w={'90%'}>
          {hexCode}
        </TextComponent>
      </VStack>
    </GridItem>
  );
};
