import React from 'react';

import { Box } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { SECTIONS } from 'constants/sections';

import {
  TabComponent,
  TabListComponent,
  TabPanelComponent,
  TabPanelsComponent,
  TabsComponent,
} from '../../components/Tabs';
import { TextComponent } from '../../components/Text';
import { space, zIndex } from '../../theme/constants';

export const CompanyProfiles = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.COMPANY_PROFILES.anchor}
      title={SECTIONS.COMPANY_PROFILES.title}
      {...props}
    >
      <Box
        mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.xSmall}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/company.jpg'}
          alt="A meeting room with an overview over the whole city"
          height={'auto'}
        />
      </Box>

      <TextComponent variant={'medium'} mb={space.large}>
        Whenever you’re writing about DWS, who we are and what we do, you can
        use a company profile. You can select from long, medium, and short,
        depending on which is most appropriate.
      </TextComponent>

      <TabsComponent
        mt={space.medium}
        mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
      >
        <TabListComponent>
          <TabComponent
            _selected={{
              borderBottomColor: 'context.clientCommitment',
              color: 'context.clientCommitment',
            }}
            _focus={{
              boxShadow: 'none',
              color: 'context.clientCommitment',
              bgColor: 'background.nearWhite',
            }}
            _hover={{ color: 'context.clientCommitment' }}
          >
            Long (200 words)
          </TabComponent>

          <TabComponent
            _selected={{
              borderBottomColor: 'context.clientCommitment',
              color: 'context.clientCommitment',
            }}
            _focus={{
              boxShadow: 'none',
              color: 'context.clientCommitment',
              bgColor: 'background.nearWhite',
            }}
            _hover={{ color: 'context.clientCommitment' }}
          >
            Medium (150 words)
          </TabComponent>

          <TabComponent
            _selected={{
              borderBottomColor: 'context.clientCommitment',
              color: 'context.clientCommitment',
            }}
            _focus={{
              boxShadow: 'none',
              color: 'context.clientCommitment',
              bgColor: 'background.nearWhite',
            }}
            _hover={{ color: 'context.clientCommitment' }}
          >
            Short (250 characters)
          </TabComponent>
        </TabListComponent>

        <TabPanelsComponent>
          <TabPanelComponent>
            <Box
              position="relative"
              _before={{
                content: "''",
                position: 'absolute',
                zIndex: zIndex.default,
                top: '0',
                bottom: '-40px',
                width: '12px',
                bgColor: 'context.clientCommitment',
              }}
            >
              <Box mb={space.medium} px={space.large}>
                <TextComponent variant={'medium'}>
                  At DWS, we’re capturing the opportunities of tomorrow. That’s
                  why we are ‘Investors for a new now’. You can be part of a
                  leading, client-committed, global Asset Manager, making an
                  impact on individuals, communities, and the world.
                  <br />
                  <br />
                  In an ever-changing landscape, markets face a new set of
                  pressures – but here, we see the opportunity in challenges.
                  Opportunity to invest responsibly; to create change. Whether
                  it’s collaborating to discover innovative solutions or
                  exploring your curiosity to develop new skills – at DWS, we
                  support you to achieve your goals.
                  <br />
                  <br />
                  As the world continues to evolve, we do too. Join us on our
                  journey, and you can shape our transformation. You can work
                  side by side with industry thought-leaders, gaining new and
                  diverse perspectives. You can share ideas and be yourself. You
                  can drive innovative and sustainable solutions that influence
                  markets and behaviours for the better.
                  <br />
                  <br />
                  Every day brings the opportunity to discover a new now, and at
                  DWS, you’ll be supported as you overcome challenges and reach
                  your ambitions. As part of our team, you can shape the future
                  of investing.
                  <br />
                  <br />
                  This is your chance to lead an extraordinary career. This is
                  your chance to invest in your future.
                </TextComponent>
              </Box>
            </Box>
          </TabPanelComponent>

          <TabPanelComponent>
            <Box
              position="relative"
              _before={{
                content: "''",
                position: 'absolute',
                zIndex: zIndex.default,
                top: '0',
                bottom: '-40px',
                width: '12px',
                bgColor: 'context.clientCommitment',
              }}
            >
              <Box mb={space.medium} px={space.large}>
                <TextComponent variant={'medium'}>
                  At DWS, we’re capturing the opportunities of tomorrow. You can
                  be part of a leading, client-committed, global Asset Manager,
                  making an impact on individuals, communities, and the world.
                  <br />
                  <br />
                  In an ever-changing landscape, markets face a new set of
                  pressures – but here, we see the opportunity in challenges.
                  Opportunity to invest responsibly; to create change. Whether
                  it’s collaborating to discover innovative solutions or
                  exploring your curiosity to develop new skills – at DWS, we
                  support you to achieve your goals.
                  <br />
                  <br />
                  As the world continues to evolve, we do too. Join us, and you
                  can shape our transformation. You can collaborate with
                  industry thought-leaders, gaining new and diverse
                  perspectives. You can share ideas and be yourself. And you can
                  drive innovative and sustainable solutions that influence
                  markets and behaviours for the better.
                  <br />
                  <br />
                  This is your chance to lead an extraordinary career. This is
                  your chance to invest in your future.
                </TextComponent>
              </Box>
            </Box>
          </TabPanelComponent>

          <TabPanelComponent>
            <Box position="relative">
              <Box
                mb={space.medium}
                _before={{
                  content: "''",
                  position: 'absolute',
                  zIndex: zIndex.default,
                  top: '0',
                  bottom: '-40px',
                  width: '12px',
                  bgColor: 'context.clientCommitment',
                }}
              >
                <Box mb={space.medium} px={space.large}>
                  <TextComponent variant={'medium'} mb={space.medium}>
                    At DWS, we’re capturing the opportunities of tomorrow. You
                    can be part of a leading global Asset Manager, making an
                    impact on individuals, communities, and the world. Join us,
                    and you can shape our transformation whilst leading an
                    extraordinary career.
                    <br />
                    <br />
                    Invest in your future.
                  </TextComponent>
                </Box>
              </Box>
            </Box>
          </TabPanelComponent>
        </TabPanelsComponent>
      </TabsComponent>
    </ContentSectionComponent>
  );
};
