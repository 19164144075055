import { Box } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import {
  AccordionComponent,
  AccordionItemComponent,
} from 'components/Accordion';
import { HeadingComponent } from 'components/Heading';
import { PAGES } from 'constants/pages';
import { space } from 'theme/constants';

import { NavigationLink } from '../NavigationLink';
import { AudienceSegmentation } from './AudienceSegmentation';
import { BrandEssentialsAndBasics } from './BrandEssentialsAndBasics';
import { BrandIdentity } from './BrandIdentity';
import { BrandNew } from './BrandNew';
import { BrandTemplatesAndFiles } from './BrandTemplatesAndFiles';
import { EmployerValueProposition } from './EmloyerValueProposition';
import { EmployerBrand } from './EmployerBrand';

type Props = {
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
};

export const NavigationComponent = ({ setOpen }: Props) => {
  const location = useLocation();

  let defaultIndex;

  switch (location.pathname) {
    case PAGES.BRAND_NEW.path:
      defaultIndex = [0];
      break;
    case PAGES.BRAND_IDENTITY.path:
      defaultIndex = [1];
      break;
    case PAGES.BRAND_ESSENTIALS_AND_GUIDES.path:
      defaultIndex = [2];
      break;
    case PAGES.BRAND_TEMPLATES_AND_FILES.path:
      defaultIndex = [3];
      break;
    case PAGES.EMPLOYER_VALUE_PROPOSITION.path:
      defaultIndex = [4];
      break;
    case PAGES.EMPLOYER_BRAND.path:
      defaultIndex = [5];
      break;
    case PAGES.AUDIENCE_SEGMENTATION.path:
      defaultIndex = [6];
      break;
    default:
      defaultIndex = undefined;
      break;
  }

  return (
    <Box
      flex="1"
      overflowX="hidden"
      overflowY="auto"
      color="text.primaryLight"
      sx={{ WebkitOverflowScrolling: 'touch' }}
    >
      <Box as="nav" mb={space.small}>
        <HeadingComponent as="h2" variant="hidden">
          Site Navigation
        </HeadingComponent>

        <AccordionComponent allowMultiple defaultIndex={defaultIndex}>
          <HeadingComponent as="h3" variant="hidden">
            Brand updates
          </HeadingComponent>

          <AccordionItemComponent
            headingLevel="h4"
            isPadded
            panelBgColor="background.nearBlack"
            title={PAGES.BRAND_NEW.title}
          >
            <BrandNew setOpen={setOpen} />
          </AccordionItemComponent>

          <Box mt={8} px={{ base: space.xSmall, lg: space.small }}>
            <HeadingComponent as="h3" variant="quaternary">
              Brand fundamentals
            </HeadingComponent>
          </Box>

          <AccordionItemComponent
            headingLevel="h4"
            isPadded
            panelBgColor="background.nearBlack"
            title={PAGES.BRAND_IDENTITY.title}
          >
            <BrandIdentity setOpen={setOpen} />
          </AccordionItemComponent>

          <AccordionItemComponent
            headingLevel="h4"
            isPadded
            panelBgColor="background.nearBlack"
            title={PAGES.BRAND_ESSENTIALS_AND_GUIDES.title}
          >
            <BrandEssentialsAndBasics setOpen={setOpen} />
          </AccordionItemComponent>

          <AccordionItemComponent
            headingLevel="h4"
            isPadded
            panelBgColor="background.nearBlack"
            title={PAGES.BRAND_TEMPLATES_AND_FILES.title}
          >
            <BrandTemplatesAndFiles setOpen={setOpen} />
          </AccordionItemComponent>

          <Box mt={8} px={{ base: space.xSmall, lg: space.small }}>
            <HeadingComponent as="h3" variant="quaternary">
              Employer Brand and Employer Value Proposition
            </HeadingComponent>
          </Box>

          <NavigationLink
            title={PAGES.INTRODUCTION.title}
            path={PAGES.INTRODUCTION.path}
          />

          <AccordionItemComponent
            headingLevel="h4"
            isPadded
            panelBgColor="background.nearBlack"
            title={PAGES.EMPLOYER_VALUE_PROPOSITION.title}
          >
            <EmployerValueProposition setOpen={setOpen} />
          </AccordionItemComponent>

          <AccordionItemComponent
            headingLevel="h4"
            isPadded
            panelBgColor="background.nearBlack"
            title={PAGES.EMPLOYER_BRAND.title}
          >
            <EmployerBrand setOpen={setOpen} />
          </AccordionItemComponent>

          <AccordionItemComponent
            headingLevel="h4"
            isPadded
            panelBgColor="background.nearBlack"
            title={PAGES.AUDIENCE_SEGMENTATION.title}
          >
            <AudienceSegmentation setOpen={setOpen} />
          </AccordionItemComponent>
        </AccordionComponent>
      </Box>
    </Box>
  );
};
