import { SimpleGridProps } from '@chakra-ui/react';

import { ColorGridItemComponent } from '../../components/ColorGridItem';
import { SimpleColorGridComponent } from '../../components/SimpleColorGrid';

export const Extensions = ({ ...props }: SimpleGridProps) => {
  return (
    <SimpleColorGridComponent {...props}>
      <ColorGridItemComponent
        color={'context.darkPetrol02'}
        headline={'Dark Petrol 02'}
        hexCode={'#346E70'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.darkGreige02'}
        headline={'Dark Greige 02'}
        hexCode={'#786E64'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.autumnGreen'}
        headline={'Autumn Green'}
        hexCode={'#7DA442'}
        hasBorder={false}
      />
      <ColorGridItemComponent
        color={'context.darkGrassGreen02'}
        headline={'Dark Grass Green 02'}
        hexCode={'#38763B'}
        hasBorder={false}
      />
    </SimpleColorGridComponent>
  );
};
