import { Box } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { SECTIONS } from 'constants/sections';

import { TextComponent } from '../../components/Text';
import { space } from '../../theme/constants';

export const Overview = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.OVERVIEW.anchor}
      title={SECTIONS.OVERVIEW.title}
      {...props}
    >
      <Box
        mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.xSmall}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/evp.jpg'}
          alt="A meeting room with an overview over the whole city"
          height={'auto'}
        />
      </Box>

      <TextComponent variant={'medium'}>
        Our EVP consists of three pillars that summarise what makes DWS an
        exciting place to work and what it is that attracts the exceptional
        people we hire and retain. It underpins how we speak about DWS as an
        employer and what it means to work here. Think of it as a promise to our
        people, whether they are currently working here or are considering it.
        <br />
        <br />
        Three pillars have been created following a series of interviews with
        DWS employees to understand what makes DWS the right place for
        exceptional people to build their careers.
        <br />
        <br />
        By embedding the EVP throughout your communications at every stage of
        the attraction, recruitment, and employment experience, you will create
        consistency in how you talk about DWS as an employer. Internally, you
        can apply this messaging to increase engagement and retention; and
        externally, you can use it to increase awareness of DWS as an employer
        of choice to the people you want to hire.
        <br />
        <br />
        Ultimately, the messaging is here to help you answer one of the most
        important and frequent questions prospective candidates and current DWS
        employees ask:
        <br />
        <br />
        <b>
          Why should I want to work at DWS? And why should I do this role at DWS
          rather than anywhere else?
        </b>
      </TextComponent>
    </ContentSectionComponent>
  );
};
