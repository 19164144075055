import { Box } from '@chakra-ui/react';

import { AnchorNavigationLinkComponent } from 'components/AnchorNavigationLink';
import { PAGES } from 'constants/pages';
import { SECTIONS } from 'constants/sections';

type Props = {
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
};

export const BrandTemplatesAndFiles = ({ setOpen }: Props) => {
  return (
    <Box as="ul" listStyleType="none">
      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.MORE.anchor}
          href={`${PAGES.BRAND_TEMPLATES_AND_FILES.path}#${SECTIONS.MORE.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.MORE.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.DESIGN_MASKS.anchor}
          href={`${PAGES.BRAND_TEMPLATES_AND_FILES.path}#${SECTIONS.DESIGN_MASKS.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.DESIGN_MASKS.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.PRESENTATIONS.anchor}
          href={`${PAGES.BRAND_TEMPLATES_AND_FILES.path}#${SECTIONS.PRESENTATIONS.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.PRESENTATIONS.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.SOCIAL_MEDIA.anchor}
          href={`${PAGES.BRAND_TEMPLATES_AND_FILES.path}#${SECTIONS.SOCIAL_MEDIA.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.SOCIAL_MEDIA.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.STATIONERY.anchor}
          href={`${PAGES.BRAND_TEMPLATES_AND_FILES.path}#${SECTIONS.STATIONERY.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.STATIONERY.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.REPORTS_PRODUCTS.anchor}
          href={`${PAGES.BRAND_TEMPLATES_AND_FILES.path}#${SECTIONS.REPORTS_PRODUCTS.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.REPORTS_PRODUCTS.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.EVENTS_WEBINARS.anchor}
          href={`${PAGES.BRAND_TEMPLATES_AND_FILES.path}#${SECTIONS.EVENTS_WEBINARS.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.EVENTS_WEBINARS.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.EMAIL_HEADERS_AND_TEMPLATES.anchor}
          href={`${PAGES.BRAND_TEMPLATES_AND_FILES.path}#${SECTIONS.EMAIL_HEADERS_AND_TEMPLATES.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.EMAIL_HEADERS_AND_TEMPLATES.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.EMAIL_SIGNATURE.anchor}
          href={`${PAGES.BRAND_TEMPLATES_AND_FILES.path}#${SECTIONS.EMAIL_SIGNATURE.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.EMAIL_SIGNATURE.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.WALLPAPERS_BACKGROUNDS.anchor}
          href={`${PAGES.BRAND_TEMPLATES_AND_FILES.path}#${SECTIONS.WALLPAPERS_BACKGROUNDS.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.WALLPAPERS_BACKGROUNDS.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.SPONSORSHIP.anchor}
          href={`${PAGES.BRAND_TEMPLATES_AND_FILES.path}#${SECTIONS.SPONSORSHIP.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.SPONSORSHIP.title}
        </AnchorNavigationLinkComponent>
      </Box>
    </Box>
  );
};
