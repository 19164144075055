import * as React from 'react';

import { Box } from '@chakra-ui/react';

import { DividerComponent } from 'components/Divider';
import { HeadingComponent } from 'components/Heading';
import { PageFooterComponent } from 'components/PageFooter';
import { PAGES } from 'constants/pages';
import { Colors } from 'sections/Colors';
import { DesignSystem } from 'sections/DesignSystem';
import { Imagery } from 'sections/Imagery';
import { Introduction } from 'sections/Introduction';
import { StaffPhotographyPortraits } from 'sections/StaffPhotographyPortraits';
import { TheLogo } from 'sections/TheLogo';
import { Typography } from 'sections/Typography';
import { Videography } from 'sections/Videography';
import { XtrackersSpecifics } from 'sections/XtrackersSpecifics';
import { size, space } from 'theme/constants';

import { Charts } from '../sections/Charts';
import { DigitalBrand } from '../sections/DigitalBrand';
import { Iconography } from '../sections/Iconography';

const BrandEssentialsAndGuidesPage: React.FC = () => {
  document.title = `${PAGES.BRAND_ESSENTIALS_AND_GUIDES.title} | DWS Brand Portal`;

  return (
    <>
      <Box
        as="main"
        maxWidth={size.contentMaxWidth}
        px={{ base: space.xSmall, lg: space.medium, xl: space.xLarge }}
      >
        <Box pt={{ base: space.small, lg: space.xLarge }}>
          <HeadingComponent as="h1" mb={1} variant="tertiary">
            {PAGES.BRAND_ESSENTIALS_AND_GUIDES.title}
          </HeadingComponent>

          <Introduction mb={space.large} />
          <TheLogo mb={space.large} />
          <DividerComponent />

          <DesignSystem />
          <DividerComponent />

          <Colors />

          <Box
            mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
            pt={{ base: space.small, lg: space.xxLarge }}
            px={{ lg: space.medium, xl: space.xLarge }}
            bgColor="background.black"
          >
            <Box
              mb={{ base: space.small, lg: space.xxLarge }}
              pr={{ base: space.xSmall, lg: '0' }}
              pl={{ base: space.xSmall, lg: '0' }}
            >
              <img
                src={process.env.PUBLIC_URL + '/images/DWSTypography.png'}
                alt="white text on a black background in big letters that reads: Write a stor| – The DWS typography"
              />
            </Box>

            <Typography />

            <Iconography />
            <DividerComponent />

            <Charts />

            <Box
              pr={{ base: space.xSmall, lg: '0' }}
              pl={{ base: space.xSmall, lg: '0' }}
            >
              <DividerComponent />
            </Box>

            <Imagery />
          </Box>

          <DividerComponent />

          <StaffPhotographyPortraits />
          <DividerComponent />

          <DigitalBrand />
          <DividerComponent />

          <Videography mb={space.xLarge} />
        </Box>
        <XtrackersSpecifics />
      </Box>

      <PageFooterComponent
        nextPageHref={PAGES.BRAND_TEMPLATES_AND_FILES.path}
        nextPageName={PAGES.BRAND_TEMPLATES_AND_FILES.title}
      />
    </>
  );
};

export default BrandEssentialsAndGuidesPage;
