import { Box } from '@chakra-ui/react';

import { AnchorNavigationLinkComponent } from 'components/AnchorNavigationLink';
import { PAGES } from 'constants/pages';
import { SECTIONS } from 'constants/sections';

type Props = {
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
};

export const AudienceSegmentation = ({ setOpen }: Props) => {
  return (
    <Box as="ul" listStyleType="none">
      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.PROFESSIONAL_HIRES.anchor}
          href={`${PAGES.AUDIENCE_SEGMENTATION.path}#${SECTIONS.PROFESSIONAL_HIRES.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.PROFESSIONAL_HIRES.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.EARLY_CAREERS.anchor}
          href={`${PAGES.AUDIENCE_SEGMENTATION.path}#${SECTIONS.EARLY_CAREERS.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.EARLY_CAREERS.title}
        </AnchorNavigationLinkComponent>
      </Box>
    </Box>
  );
};
