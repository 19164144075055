export const EarlyCareersImages = [
  {
    original:
      'images/ImageGallery/EarlyCareers/85406129_dws_grading_2680x1510px.jpg',
    originalAlt: 'Man from behind talking to another man',
    thumbnail:
      'images/ImageGallery/EarlyCareers/85406129_dws_grading_400x400px.jpg',
    thumbnailAlt: 'Man from behind talking to another man',
  },
  {
    original:
      'images/ImageGallery/EarlyCareers/103332508_dws_grading_2680x1510px.jpg',
    originalAlt:
      'A woman holding a pen who smiling. Sitting in front of two men',
    thumbnail:
      'images/ImageGallery/EarlyCareers/103332508_dws_grading_400x400px.jpg',
    thumbnailAlt:
      'A woman holding a pen who smiling. Sitting in front of two men',
  },
  {
    original:
      'images/ImageGallery/EarlyCareers/143072169_dws_grading_2680x1510px.jpg',
    originalAlt: 'A man shown from behind talking to two woman',
    thumbnail:
      'images/ImageGallery/EarlyCareers/143072169_dws_grading_400x400px.jpg',
    thumbnailAlt: 'A man shown from behind talking to two woman',
  },
  {
    original:
      'images/ImageGallery/EarlyCareers/160923624_dws_grading_2680x1510px.jpg',
    originalAlt: 'A road at night',
    thumbnail:
      'images/ImageGallery/EarlyCareers/160923624_dws_grading_400x400px.jpg',
    thumbnailAlt: 'A road at night',
  },
  {
    original:
      'images/ImageGallery/EarlyCareers/160992902_dws_grading_2680x1510px.jpg',
    originalAlt:
      'A deserted way going straight to the horizon (black & white image)',
    thumbnail:
      'images/ImageGallery/EarlyCareers/160992902_dws_grading_400x400px.jpg',
    thumbnailAlt:
      'A deserted way going straight to the horizon (black & white image)',
  },
  {
    original:
      'images/ImageGallery/EarlyCareers/GettyImages-930209358_dws_grading_2680x1510px.jpg',
    originalAlt: 'A man standing in front of a lot of colorful lights',
    thumbnail:
      'images/ImageGallery/EarlyCareers/GettyImages-930209358_dws_grading_400x400px.jpg',
    thumbnailAlt: 'A man standing in front of a lot of colorful lights',
  },
  {
    original:
      'images/ImageGallery/EarlyCareers/GettyImages-932096974_dws_grading_2680x1510px.jpg',
    originalAlt: 'A woman looking up',
    thumbnail:
      'images/ImageGallery/EarlyCareers/GettyImages-932096974_dws_grading_400x400px.jpg',
    thumbnailAlt: 'A woman looking up',
  },
  {
    original:
      'images/ImageGallery/EarlyCareers/GettyImages-987383258_dws_grading_2680x1510px.jpg',
    originalAlt: 'A highway at night',
    thumbnail:
      'images/ImageGallery/EarlyCareers/GettyImages-987383258_dws_grading_400x400px.jpg',
    thumbnailAlt: 'A highway at night',
  },
  {
    original:
      'images/ImageGallery/EarlyCareers/GettyImages-1162925450_dws_grading_2680x1510px.jpg',
    originalAlt: 'A city at night from above',
    thumbnail:
      'images/ImageGallery/EarlyCareers/GettyImages-1162925450_dws_grading_400x400px.jpg',
    thumbnailAlt: 'A city at night from above',
  },
  {
    original:
      'images/ImageGallery/EarlyCareers/GettyImages-1163775797_dws_grading_2680x1510px.jpg',
    originalAlt: 'A woman shown from behind standing in front of a skyline',
    thumbnail:
      'images/ImageGallery/EarlyCareers/GettyImages-1163775797_dws_grading_400x400px.jpg',
    thumbnailAlt: 'A woman shown from behind standing in front of a skyline',
  },
  {
    original:
      'images/ImageGallery/EarlyCareers/iStock-944464632_dws_grading_2680x1510px.jpg',
    originalAlt: 'A man shown from behind holding a laptop',
    thumbnail:
      'images/ImageGallery/EarlyCareers/iStock-944464632_dws_grading_400x400px.jpg',
    thumbnailAlt: 'A man shown from behind holding a laptop',
  },
  {
    original:
      'images/ImageGallery/EarlyCareers/unsplash_linkedisasasn-sales-navigator-hrhjn6ZTgrM_dws_grading_2680x1510px.jpg',
    originalAlt: 'A smiling man sitting in a lounge listening to another man',
    thumbnail:
      'images/ImageGallery/EarlyCareers/unsplash_linkedisasasn-sales-navigator-hrhjn6ZTgrM_dws_grading_400x400px.jpg',
    thumbnailAlt: 'A smiling man sitting in a lounge listening to another man',
  },
];
