import { Box, VStack } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { DownloadFileComponent } from 'components/DownloadFile';
import { TextComponent } from 'components/Text';
import { PAGES } from 'constants/pages';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

export const TypographySystem = () => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.TYPOGRAPHY_SYSTEM.anchor}
      moreDetailsUrl={`${PAGES.BRAND_ESSENTIALS_AND_GUIDES.path}#${SECTIONS.TYPOGRAPHY.anchor}`}
      title={SECTIONS.TYPOGRAPHY_SYSTEM.title}
    >
      <Box mb={space.xSmall}>
        <img
          src={process.env.PUBLIC_URL + '/images/TypographySystem.png'}
          alt="Alphabet in uppercase letters plus numbers and interpunction written in white on black background"
        />
      </Box>

      <TextComponent limitedWidth mb={space.large}>
        We slightly modified the application of our font style by inversing the
        DWS Slab and Sans usage in several cases. For example: DWS Slab Regular
        as the headline style instead of DWS Sans Bold.
      </TextComponent>

      <Box
        mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.small}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/FontUsage.jpg'}
          alt="How to use all the corporate fonts: a) Intended use next to used font setting, b) a poster shows the different fonts in action, c) a double page in a magazine shows the corporate font usage"
        />
      </Box>

      <VStack align="stretch" spacing={2}>
        <DownloadFileComponent
          file="https://download.dws.com/download?elib-assetguid=92e30d536f024fbbae2e5557d24c7328"
          fileType="Zip"
          fileSize="1.5 MB"
        >
          DWS fonts
        </DownloadFileComponent>
      </VStack>
    </ContentSectionComponent>
  );
};
