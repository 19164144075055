import { Box } from '@chakra-ui/react';

import { AnchorNavigationLinkComponent } from 'components/AnchorNavigationLink';
import { PAGES } from 'constants/pages';
import { SECTIONS } from 'constants/sections';

type Props = {
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
};

export const BrandNew = ({ setOpen }: Props) => {
  return (
    <Box as="ul" listStyleType="none">
      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.LATEST_UPDATES.anchor}
          href={`${PAGES.BRAND_NEW.path}#${SECTIONS.LATEST_UPDATES.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.LATEST_UPDATES.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.LOGO_SIMPLIFICATION.anchor}
          href={`${PAGES.BRAND_NEW.path}#${SECTIONS.LOGO_SIMPLIFICATION.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.LOGO_SIMPLIFICATION.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.DARK_BRIGHT_BALANCE.anchor}
          href={`${PAGES.BRAND_NEW.path}#${SECTIONS.DARK_BRIGHT_BALANCE.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.DARK_BRIGHT_BALANCE.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.COLOR_SYSTEM.anchor}
          href={`${PAGES.BRAND_NEW.path}#${SECTIONS.COLOR_SYSTEM.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.COLOR_SYSTEM.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.TYPOGRAPHY_SYSTEM.anchor}
          href={`${PAGES.BRAND_NEW.path}#${SECTIONS.TYPOGRAPHY_SYSTEM.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.TYPOGRAPHY_SYSTEM.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.DESIGN_SYSTEM_ELEMENTS.anchor}
          href={`${PAGES.BRAND_NEW.path}#${SECTIONS.DESIGN_SYSTEM_ELEMENTS.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.DESIGN_SYSTEM_ELEMENTS.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.IMAGERY_VISUAL_EXPERIENCE.anchor}
          href={`${PAGES.BRAND_NEW.path}#${SECTIONS.IMAGERY_VISUAL_EXPERIENCE.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.IMAGERY_VISUAL_EXPERIENCE.title}
        </AnchorNavigationLinkComponent>
      </Box>

      <Box as="li">
        <AnchorNavigationLinkComponent
          anchor={SECTIONS.SHOWCASE_THE_POSSIBLE.anchor}
          href={`${PAGES.BRAND_NEW.path}#${SECTIONS.SHOWCASE_THE_POSSIBLE.anchor}`}
          setOpen={setOpen}
        >
          {SECTIONS.SHOWCASE_THE_POSSIBLE.title}
        </AnchorNavigationLinkComponent>
      </Box>
    </Box>
  );
};
