import { Icon, IconProps } from '@chakra-ui/react';

const ArrowRightCircleIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 64 64" aria-hidden="true" fill="none" {...props}>
      <path stroke="#000" strokeWidth="2" d="M29.4 37.5994l6.4-5.6-6.4-5.6" />
      <circle
        cx="32"
        cy="32"
        r="31"
        stroke="#000"
        strokeWidth="2"
        transform="rotate(-90 32 32)"
      />
    </Icon>
  );
};

export default ArrowRightCircleIcon;
