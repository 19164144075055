import { Box, VStack } from '@chakra-ui/react';

import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

import { ContentSectionComponent } from '../../components/ContentSection';
import { DownloadFileComponent } from '../../components/DownloadFile';
import { LinkBar } from '../../components/LinkBar';
import { TextComponent } from '../../components/Text';

export const EmailSignature = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.EMAIL_SIGNATURE.anchor}
      title={SECTIONS.EMAIL_SIGNATURE.title}
      {...props}
    >
      <Box
        mb={space.small}
        mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/EmailSignature.jpg'}
          alt="A tablet showing three different email headers"
        />
      </Box>

      <TextComponent limitedWidth mb={space.xSmall}>
        Your email signature functions as an online business card. Using a
        standard template provides a uniform visual identity for DWS and your
        role. Create your email signature by following these instructions to
        populate it with your personal information. Then follow the downloadable
        guidelines to begin using it in Outlook.
      </TextComponent>

      <VStack align={'stretch'} spacing={2} mb={space.large}>
        <LinkBar link={'https://www.dws.com/redirects/bp-ems/'}>
          DWS email signature assistant
        </LinkBar>

        <DownloadFileComponent
          file={
            'https://download.dws.com/download?elib-assetguid=5a8fc4781f10473da4aebdfbb791e6b8'
          }
          fileType={'Zip'}
          fileSize={'3.3 MB'}
        >
          DWS email signature quickguide (For Outlook)
        </DownloadFileComponent>
      </VStack>
    </ContentSectionComponent>
  );
};
