import { ChakraProvider } from '@chakra-ui/react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Cookies from 'universal-cookie';

import { PAGES } from 'constants/pages';
import { LoginLayout } from 'layouts/login';
import LoginPage from 'pages';
import BrandEssentialsAndGuidesPage from 'pages/brand-essentials-and-guides-page';
import BrandIdentityPage from 'pages/brand-identity-page';
import BrandNewPage from 'pages/brand-new-page';
import BrandTemplatesAndFilesPage from 'pages/brand-templates-and-files-page';
import { ProtectedRoute } from 'routes/ProtectedRoute';
import theme from 'theme';
import { Fonts } from 'theme/Fonts';

import './carousel.css';
import './image-gallery.css';
import AudienceSegmentation from './pages/audience-segmentation';
import EmployerBrand from './pages/employer-brand';
import EmployerValueProposition from './pages/employer-value-proposition';
import Introduction from './pages/introduction';

function App() {
  const cookies = new Cookies();
  const authCookie = cookies.get('passwordAuth');

  return (
    <ChakraProvider theme={theme} resetCSS={false} portalZIndex={200}>
      <Fonts />
      <Router>
        <Switch>
          <Route path={PAGES.BRAND_NEW.path}>
            <ProtectedRoute>
              <BrandNewPage />
            </ProtectedRoute>
          </Route>

          <Route path={PAGES.BRAND_IDENTITY.path}>
            <ProtectedRoute>
              <BrandIdentityPage />
            </ProtectedRoute>
          </Route>

          <Route path={PAGES.BRAND_ESSENTIALS_AND_GUIDES.path}>
            <ProtectedRoute>
              <BrandEssentialsAndGuidesPage />
            </ProtectedRoute>
          </Route>

          <Route path={PAGES.BRAND_TEMPLATES_AND_FILES.path}>
            <ProtectedRoute>
              <BrandTemplatesAndFilesPage />
            </ProtectedRoute>
          </Route>

          <Route path={PAGES.INTRODUCTION.path}>
            <ProtectedRoute>
              <Introduction />
            </ProtectedRoute>
          </Route>

          <Route path={PAGES.EMPLOYER_VALUE_PROPOSITION.path}>
            <ProtectedRoute>
              <EmployerValueProposition />
            </ProtectedRoute>
          </Route>

          <Route path={PAGES.EMPLOYER_BRAND.path}>
            <ProtectedRoute>
              <EmployerBrand />
            </ProtectedRoute>
          </Route>

          <Route path={PAGES.AUDIENCE_SEGMENTATION.path}>
            <ProtectedRoute>
              <AudienceSegmentation />
            </ProtectedRoute>
          </Route>

          <Route path="/" exact={true}>
            {authCookie ? (
              <Redirect to={PAGES.BRAND_NEW.path} />
            ) : (
              <LoginLayout>
                <LoginPage />
              </LoginLayout>
            )}
          </Route>
        </Switch>
      </Router>
    </ChakraProvider>
  );
}

export default App;
