import React, { useEffect, useState } from 'react';

import { Grid, GridItem } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { MobileHeaderComponent } from 'components/MobileHeader';
import { SidebarComponent } from 'components/Sidebar';
import { elementId, size } from 'theme/constants';

type Props = {
  children: React.ReactNode;
};

export const DefaultLayout = ({ children }: Props) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const sidebarId = elementId.sidebar;

  useEffect(() => {
    const elementId = location.hash.replace('#', '');

    if (elementId !== '') {
      const element = document.getElementById(elementId);

      setTimeout(() => {
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }, 0);
    }
  }, [location]);

  return (
    <Grid
      templateColumns={{
        base: '1fr',
        lg: `${size.sidebarWidthMedium} auto`,
        xl: `${size.sidebarWidthLarge} auto`,
      }}
      templateAreas={{
        base: "'sidebar' 'main'",
        lg: "'sidebar main'",
      }}
      gap="0"
      height="100vh"
      sx={{
        '@supports (-webkit-touch-callout: none)': {
          height: '-webkit-fill-available',
        },
      }}
    >
      <GridItem
        gridArea="main"
        overflowY="auto"
        color="text.primaryDark"
        bgColor="background.white"
        sx={{
          scrollBehavior: 'smooth',
          WebkitOverflowScrolling: 'touch',
        }}
      >
        {children}
      </GridItem>

      <GridItem gridArea="sidebar" overflow="hidden">
        <MobileHeaderComponent
          ariaControlsId={sidebarId}
          open={open}
          setOpen={setOpen}
        />
        <SidebarComponent id={sidebarId} open={open} setOpen={setOpen} />
      </GridItem>
    </Grid>
  );
};
