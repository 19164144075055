import { Box, VStack } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { DownloadFileComponent } from 'components/DownloadFile';
import { HeadingComponent } from 'components/Heading';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

export const WallpapersBackgrounds = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.WALLPAPERS_BACKGROUNDS.anchor}
      title={SECTIONS.WALLPAPERS_BACKGROUNDS.title}
      {...props}
    >
      <HeadingComponent as="h3" variant="secondary" mb={space.xxSmall}>
        Virtual backgrounds
      </HeadingComponent>

      <Box
        mb={space.small}
        mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/VCBackgrounds.jpg'}
          alt="An iMac showing a DWS wallpaper"
        />
      </Box>

      <DownloadFileComponent
        mb={space.large}
        file="https://download.dws.com/download?elib-assetguid=fe411b62a8d447a193d93c1306a4ede9"
        fileType="Zip"
        fileSize="10.5 MB"
      >
        Virtual backgrounds
      </DownloadFileComponent>

      <HeadingComponent as="h3" variant="secondary" mb={space.small}>
        Desktop backgrounds
      </HeadingComponent>

      <Box
        mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.small}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/DesktopBackgrounds.jpg'}
          alt="A laptop showing a DWS desktop background"
        />
      </Box>

      <VStack align="stretch" spacing={2} mb={space.large}>
        <DownloadFileComponent
          file={
            'https://download.dws.com/download?elib-assetguid=ee63e8a7472b4cd6a7cff53a96a2da52'
          }
          fileType="Zip"
          fileSize="98.9 MB"
        >
          Desktop backgrounds (1024 x 768px)
        </DownloadFileComponent>

        <DownloadFileComponent
          file={
            'https://download.dws.com/download?elib-assetguid=6c699362e4474f07a916e441732716b8'
          }
          fileType="Zip"
          fileSize="98.9 MB"
        >
          Desktop backgrounds (1366 x 768px)
        </DownloadFileComponent>

        <DownloadFileComponent
          file={
            'https://download.dws.com/download?elib-assetguid=310bbfec1bb94923abc44a247199fdd5'
          }
          fileType="Zip"
          fileSize="98.9 MB"
        >
          Desktop backgrounds (2560 x 1600px)
        </DownloadFileComponent>

        <DownloadFileComponent
          file={
            'https://download.dws.com/download?elib-assetguid=e6c324e1cc994d57bb44f6a768dfebe3'
          }
          fileType="Zip"
          fileSize="98.9 MB"
        >
          Desktop backgrounds (3840 x 2160px)
        </DownloadFileComponent>
      </VStack>
    </ContentSectionComponent>
  );
};
