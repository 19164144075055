import { Box, Center, Flex, Grid, GridItem, VStack } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { TextComponent } from 'components/Text';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

import { DividerComponent } from '../../components/Divider';
import { DownloadFileComponent } from '../../components/DownloadFile';
import { HeadingComponent } from '../../components/Heading';
import CheckmarkBoxIcon from '../../components/Icon/CheckmarkBoxIcon';
import CrossBoxIcon from '../../components/Icon/CrossBoxIcon';
import TrashIcon from '../../components/Icon/TrashIcon';
import { LinkBar } from '../../components/LinkBar';
import {
  ListItemComponent,
  UnorderedListComponent,
} from '../../components/List';

export const Imagery = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.IMAGERY.anchor}
      isIndented
      isInverted
      title={SECTIONS.IMAGERY.title}
      {...props}
    >
      <Box
        mx={{ base: space.xSmall, lg: space.medium, xl: space.xLarge }}
        sx={{
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
        }}
      >
        <TextComponent mb={space.small} color={'text.primaryLight'}>
          Our new approach to imagery has updated the color palette to fit brand
          values, foster creativity and ensure viewability on black and white
          colored backgrounds.
        </TextComponent>

        <Box
          mt="-19.4%"
          mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
          transform="translateY(38.8%)"
        >
          <img
            src={process.env.PUBLIC_URL + '/images/ImageryVisual.png'}
            alt="ifferent images are shown like a mosaic"
          />
        </Box>

        <Box
          background={'white'}
          paddingTop={'25%'}
          px={{ lg: space.medium, xl: space.xLarge }}
          mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        >
          <HeadingComponent variant={'secondary'} mb={space.small}>
            Basic framework
          </HeadingComponent>

          <TextComponent limitedWidth mb={space.small}>
            Our visual experience has to be aligned with our brand values and
            characteristics (Client Commitment, Entrepreneurial Spirit and
            Sustainable Action).
          </TextComponent>

          <TextComponent mb={space.xSmall}>
            Our brand values and characteristics set the framework of our visual
            experience. Major considerations:
          </TextComponent>

          <UnorderedListComponent mb={space.small}>
            <ListItemComponent>
              Does the visual match to at least one of our values?
            </ListItemComponent>
            <ListItemComponent>
              Does the visual express our characteristics?
            </ListItemComponent>
          </UnorderedListComponent>

          <HeadingComponent as="h3" variant="quinary" mb={2}>
            Examples
          </HeadingComponent>

          <Box
            mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
            mb={space.small}
          >
            <img
              src={
                process.env.PUBLIC_URL + '/images/BasicFrameworkExamples.png'
              }
              alt="Example how to choose the right brand images"
            />
          </Box>

          <HeadingComponent
            as="h3"
            variant="quinary"
            mb={2}
            color="context.clientCommitment"
          >
            Client Commitment
          </HeadingComponent>

          <Box
            mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
            mb={space.small}
          >
            <img
              src={
                process.env.PUBLIC_URL +
                '/images/BasicFrameworkClientCommitment.jpg'
              }
              alt="Six example images that fit Entrepreneurial Spirit"
            />
          </Box>

          <HeadingComponent
            as="h3"
            variant="quinary"
            mb={2}
            color="context.entrepreneurialSpirit"
          >
            Entrepreneurial Spirit
          </HeadingComponent>

          <Box
            mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
            mb={space.small}
          >
            <img
              src={
                process.env.PUBLIC_URL +
                '/images/BasicFrameworkEntrepreneurialSpirit.jpg'
              }
              alt="Six example images that fit Entrepreneurial Spirit"
            />
          </Box>

          <HeadingComponent
            as="h3"
            variant="quinary"
            mb={2}
            color="context.sustainableAction"
          >
            Sustainable Action
          </HeadingComponent>

          <Box mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}>
            <img
              src={
                process.env.PUBLIC_URL +
                '/images/BasicFrameworkSustainableAction.jpg'
              }
              alt="Six example images that fit Sustainable Action"
            />
          </Box>

          <DividerComponent />

          <HeadingComponent variant={'secondary'} mb={space.small}>
            Basic application
          </HeadingComponent>

          <Grid
            columnGap={space.xSmall}
            mb={space.small}
            rowGap={space.small}
            templateColumns={{ base: '1fr', md: '1fr 1fr' }}
          >
            <GridItem>
              <Flex>
                <Center w="32px" h="32px" bgColor="background.black">
                  <TextComponent
                    as="span"
                    fontWeight="700"
                    color="text.primaryLight"
                  >
                    01
                  </TextComponent>
                </Center>

                <Box flex="1">
                  <Box mb={space.xxSmall}>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/BasicApplication01.jpg'
                      }
                      alt="A rainbow colored wall with white text on it taht reads: We all live here"
                    />
                  </Box>
                  <TextComponent>
                    Images have to either enhance relevancy, meaning and/or the
                    message of our content or transport a story on its own.
                  </TextComponent>
                </Box>
              </Flex>
            </GridItem>

            <GridItem>
              <Flex>
                <Center w="32px" h="32px" bgColor="background.black">
                  <TextComponent
                    as="span"
                    fontWeight="700"
                    color="text.primaryLight"
                  >
                    02
                  </TextComponent>
                </Center>

                <Box flex="1">
                  <Box mb={space.xxSmall}>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/BasicApplication02.jpg'
                      }
                      alt="A laughing woman close-up showing only her mouth, teeth, chin and some hairs"
                    />
                  </Box>
                  <TextComponent>
                    Images have to be congruent with at least one of our brand
                    values (Client Commitment, Entrepreneurial Spirit and
                    Sustainable Action)
                  </TextComponent>
                </Box>
              </Flex>
            </GridItem>

            <GridItem>
              <Flex>
                <Center w="32px" h="32px" bgColor="background.black">
                  <TextComponent
                    as="span"
                    fontWeight="700"
                    color="text.primaryLight"
                  >
                    03
                  </TextComponent>
                </Center>

                <Box flex="1">
                  <Box mb={space.xxSmall}>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/BasicApplication03.jpg'
                      }
                      alt="'Two persons in the backlight walking through a meadow of tall grass"
                    />
                  </Box>
                  <TextComponent>
                    Comply with the new distinctive DWS look & feel (e.g.
                    consistent color grading, no generic stock footage)
                  </TextComponent>
                </Box>
              </Flex>
            </GridItem>

            <GridItem>
              <Flex>
                <Center w="32px" h="32px" bgColor="background.black">
                  <TextComponent as="span" color="text.primaryLight">
                    <TrashIcon
                      display="block"
                      w="17px"
                      h="22px"
                      aria-label="Don’t do this"
                    />
                  </TextComponent>
                </Center>

                <Box flex="1">
                  <Box mb={space.xxSmall}>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/BasicApplication04.jpg'
                      }
                      alt="A stock photography showing a man behind a glass wall with some statistic graph data on it"
                    />
                  </Box>

                  <Box maxWidth="70%">
                    <TextComponent>
                      If 01–03 does not apply, we simply don‘t use images and
                      focus on content only.
                    </TextComponent>
                  </Box>

                  <Box position="relative" mt="-45%">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/images/BasicApplication05.png'
                      }
                      alt="Content on a graphic background that uses the corporate slash"
                    />
                  </Box>
                </Box>
              </Flex>
            </GridItem>
          </Grid>

          <Box
            position="relative"
            mt={{ md: '-82px' }}
            mb={space.large}
            _before={{
              content: "''",
              position: 'absolute',
              top: '0',
              left: `-${space.xSmall}`,
              bottom: '0',
              width: '6px',
              bgColor: 'context.positive',
            }}
          >
            <HeadingComponent as="h3">
              <CheckmarkBoxIcon
                fill="context.positive"
                w="60px"
                h="46px"
                mb={space.small}
              />
              <Box
                as="span"
                overflow="hidden"
                position="absolute"
                width="1px"
                height="1px"
                border="0"
                padding="0"
                whiteSpace="nowrap"
                clipPath="inset(50%)"
              >
                Application Dos
              </Box>
            </HeadingComponent>
            <Grid
              columnGap="10px"
              rowGap={space.small}
              templateColumns={{ base: '1fr', md: '1fr 1fr 1fr' }}
            >
              <GridItem>
                <Box mb={space.xxSmall}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/images/BasicApplicationDos01.jpg'
                    }
                    alt="A leaf in close-up showing its veins"
                  />
                </Box>
                <TextComponent>
                  Meaningful and congruent to our brand values.
                </TextComponent>
              </GridItem>

              <GridItem>
                <Box mb={space.xxSmall}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/images/BasicApplicationDos02.jpg'
                    }
                    alt="Two persons sitting on a grafitti stone wall showing only the legs and feet"
                  />
                </Box>
                <TextComponent>
                  Consistent visual appearance (distinctive color grading).
                </TextComponent>
              </GridItem>

              <GridItem>
                <Box mb={space.xxSmall}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/images/BasicApplicationDos03.jpg'
                    }
                    alt="A young boy with red hair laying on the grass floor holding his left knee"
                  />
                </Box>
                <TextComponent>Authentic and interesting.</TextComponent>
              </GridItem>

              <GridItem>
                <Box mb={space.xxSmall}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/images/BasicApplicationDos04.jpg'
                    }
                    alt="A smiling happy young boy with arms wide open (black & white)"
                  />
                </Box>
                <TextComponent>
                  Meaningful and congruent to our brand values.
                </TextComponent>
              </GridItem>

              <GridItem>
                <Box mb={space.xxSmall}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/images/BasicApplicationDos05.jpg'
                    }
                    alt="The word Change written in voilet colored neon tubes"
                  />
                </Box>
                <TextComponent>
                  Consistent visual appearance (distinctive color grading).
                </TextComponent>
              </GridItem>
            </Grid>
          </Box>

          <Box
            position="relative"
            mb={space.large}
            _before={{
              content: "''",
              position: 'absolute',
              top: '0',
              left: `-${space.xSmall}`,
              bottom: '0',
              width: '6px',
              bgColor: 'context.negative',
            }}
          >
            <HeadingComponent as="h3">
              <CrossBoxIcon
                fill="context.negative"
                w="60px"
                h="46px"
                mb={space.small}
              />
              <Box
                as="span"
                overflow="hidden"
                position="absolute"
                width="1px"
                height="1px"
                border="0"
                padding="0"
                whiteSpace="nowrap"
                clipPath="inset(50%)"
              >
                Application Don’ts
              </Box>
            </HeadingComponent>
            <Grid
              columnGap="10px"
              rowGap={space.small}
              templateColumns={{ base: '1fr', md: '1fr 1fr 1fr' }}
            >
              <GridItem>
                <Box mb={space.xxSmall}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/images/BasicApplicationDonts01.jpg'
                    }
                    alt="A man in a blue pullover in front of a blue wall clenching fists"
                  />
                </Box>
                <TextComponent>
                  Staged, unreal and exaggerated posing.
                </TextComponent>
              </GridItem>

              <GridItem>
                <Box mb={space.xxSmall}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/images/BasicApplicationDonts02.jpg'
                    }
                    alt="A typical stock photography where a man drawa a graphic line behind a translucent bar chart"
                  />
                </Box>
                <TextComponent>
                  Generic, meaningless and constructed.
                </TextComponent>
              </GridItem>

              <GridItem>
                <Box mb={space.xxSmall}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/images/BasicApplicationDonts03.png'
                    }
                    alt="Different colors splashing together"
                  />
                </Box>
                <TextComponent>
                  Artificial objects, composings and overdone contrasts /
                  colors.
                </TextComponent>
              </GridItem>

              <GridItem>
                <Box mb={space.xxSmall}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/images/BasicApplicationDonts04.jpg'
                    }
                    alt="A surreal fantasy drawing"
                  />
                </Box>
                <TextComponent>
                  Unnatural 3D objects and composings.
                </TextComponent>
              </GridItem>
            </Grid>
          </Box>

          <HeadingComponent as="h3" variant="secondary" mb={space.small}>
            Color grading application
          </HeadingComponent>

          <Grid
            columnGap={space.xSmall}
            mb={space.large}
            rowGap={space.small}
            templateColumns={{ base: '1fr', md: '50.58823529% 1fr' }}
          >
            <GridItem>
              <Box pt="42px">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    '/images/ColorGradingApplication01.png'
                  }
                  alt="A woman in profile standing in front of a pink and blue color gradient background"
                />
              </Box>
            </GridItem>

            <GridItem pr="33.33333333%">
              <Center w="32px" h="32px" mb="10px" bgColor="background.black">
                <TextComponent
                  as="span"
                  fontWeight="700"
                  color="text.primaryLight"
                >
                  01
                </TextComponent>
              </Center>

              <Box mb={space.xxSmall}>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    '/images/ColorGradingApplication02.png'
                  }
                  alt="Photoshop color correction settings window"
                />
              </Box>

              <TextComponent>
                Reducing black color tones (depending on the source visual from
                –5 to –25)
              </TextComponent>
            </GridItem>

            <GridItem>
              <Box pt="42px">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    '/images/ColorGradingApplication03.png'
                  }
                  alt="A woman in profile standing in front of a reduced saturated pink and blue color gradient background"
                />
              </Box>
            </GridItem>

            <GridItem pr="33.33333333%">
              <Center w="32px" h="32px" mb="10px" bgColor="background.black">
                <TextComponent
                  as="span"
                  fontWeight="700"
                  color="text.primaryLight"
                >
                  02
                </TextComponent>
              </Center>

              <Box mb={space.xxSmall}>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    '/images/ColorGradingApplication04.png'
                  }
                  alt="Photoshop saturation settings window"
                />
              </Box>

              <TextComponent>
                Enhancing the overall brightness (depending on the source
                visual) from 0–20
              </TextComponent>
            </GridItem>
          </Grid>

          <VStack align={'stretch'} spacing={2} mb={space.large}>
            <LinkBar link={'https://mediamanager.dws.com'}>
              DWS Media Manager (Library)
            </LinkBar>

            <DownloadFileComponent
              file={
                'https://download.dws.com/download?elib-assetguid=e862481ea7f941e88b5fa2c970e40efa'
              }
              fileType={'zip'}
              fileSize={'10.5 MB'}
            >
              Imagery quickguide
            </DownloadFileComponent>
          </VStack>
        </Box>
      </Box>
    </ContentSectionComponent>
  );
};
