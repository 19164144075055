import * as React from 'react';

import { Box, Grid, GridItem, SimpleGrid } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { SECTIONS } from 'constants/sections';

import { CardComponent } from '../../components/Card';
import { HeadingComponent } from '../../components/Heading';
import { ImageGalleryComponent } from '../../components/ImageGallery';
import { TextComponent } from '../../components/Text';
import { FIDUCARY_PETROL } from '../../theme';
import { space } from '../../theme/constants';
import { EarlyCareersImages } from './ImageGalleryItems';

export const EarlyCareers = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.EARLY_CAREERS.anchor}
      title={SECTIONS.EARLY_CAREERS.title}
      {...props}
    >
      <Box
        px={{ base: space.xSmall, lg: space.medium, xl: space.xLarge }}
        mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
        mb={space.large}
      >
        <Box>
          <TextComponent variant={'medium'} mb={space.medium}>
            This audience consists of any young person still in education, or
            newly post-graduated. This includes those searching for internships,
            graduate careers or those who haven’t yet considered a career in
            investment and asset management. They engage with messaging focussed
            on potential, exploration, and impact.
          </TextComponent>

          <Box
            mb={space.medium}
            mr={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
          >
            <ImageGalleryComponent items={EarlyCareersImages} />
          </Box>

          <TextComponent variant={'medium'} mb={space.medium}>
            For our early careers audience, use full bleed photography that
            feels futuristic and powerful. Use the outline slash to distrupt the
            headline and switch up the colour to create a dual effect design
            style.
            <br />
            <br />
            In contrast to the professional hires audience, use black as the
            background colour when placing imagery in the slash. These design
            systems will ensure our communications stand out to our early
            careers talent, particularly on social.
          </TextComponent>

          <SimpleGrid
            columns={[1, 2]}
            pl={{ lg: space.medium, xl: space.xLarge }}
            pr={{ lg: space.medium, xl: 0 }}
            mb={space.medium}
            spacing={0}
            marginRight={{ sm: '20%', xl: '40%' }}
          >
            <Box textAlign={['center', 'right']} pr={[0, space.small]}>
              <HeadingComponent variant={'primary'} color={FIDUCARY_PETROL}>
                Headlines
              </HeadingComponent>
            </Box>

            <Box minW={'300px'}>
              <TextComponent variant={'medium'}>
                – See what you’re capable of.
                <br />
                <br />
                – Uncover new ground, discover new skills.
                <br />
                <br />
                – Explore your creativity, explore your ambition.
                <br />
                <br />
                – Make the opportunities of tomorrow.
                <br />
                <br />
                – Make an impact on your world.
                <br />
                <br />– Create a brighter future.
              </TextComponent>
            </Box>
          </SimpleGrid>

          <Grid
            templateColumns={{ base: '1fr', md: '1fr 1fr' }}
            gap={space.xSmall}
            mb={space.medium}
            mr={{ lg: `${space.medium}`, xl: `${space.xLarge}` }}
          >
            <GridItem display="flex" flexDirection="column">
              <HeadingComponent variant={'secondary'} mb={space.xSmall}>
                Offline assets
              </HeadingComponent>
              <CardComponent
                file="https://download.dws.com/download?elib-assetguid=d944937b606044ec88a392fc4d98f686"
                fileType="Zip"
                fileSize="10.5 MB"
                headingLevel="h4"
                imgAlt="DWS fonts version"
                imgSrc="earlycareers_offline_mockup_2.jpg"
                isCompact
                title="Download offline assets"
              />
            </GridItem>

            <GridItem display="flex" flexDirection="column">
              <HeadingComponent variant={'secondary'} mb={space.xSmall}>
                Digital assets
              </HeadingComponent>
              <CardComponent
                file="https://download.dws.com/download?elib-assetguid=9cd3bfecb6d74b06b953d74901c35b0e"
                fileType="Zip"
                fileSize="10.5 MB"
                headingLevel="h4"
                imgAlt="MS Office system fonts version"
                imgSrc="earlycareers_digital_mockup_2.jpg"
                isCompact
                title="Download digital assets"
              />
            </GridItem>
          </Grid>
        </Box>
      </Box>
    </ContentSectionComponent>
  );
};
