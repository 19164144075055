import { Box } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { TextComponent } from 'components/Text';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

export const Claim = ({ ...props }) => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.CLAIM.anchor}
      isIndented
      isInverted
      title={SECTIONS.CLAIM.title}
      {...props}
    >
      <Box
        mx={{ base: space.xSmall, lg: space.medium, xl: space.xLarge }}
        color="text.primaryLight"
        sx={{
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
        }}
      >
        <TextComponent mb={space.xSmall}>
          As the world changes, so do markets. We have seen it time and again.
          The gold standard fell; the oil crisis hit; Reaganomics shaped an era;
          the Berlin Wall fell and with it a bipolar world; Europe came together
          and created a new fiat currency; we witnessed unspeakable acts of
          terror and conflict; markets rose to incredible highs on the promise
          of tech and real estate; markets crashed to unthinkable lows after
          broken promises of tech and real estate; countries buckled under the
          pressure of sovereign debt.
        </TextComponent>
        <TextComponent mb={space.xSmall}>
          Today, markets still face a whole new set of pressures – but also a
          whole lot of opportunity too. Opportunity to innovate differently.
          Opportunity to invest responsibly. And opportunity to make change.
          This is needed more than ever as we currently experience low interest
          rates, a once-in-a-lifetime health crisis, a shift in global
          demographics – and politics – as well as advances in digitalization
          and economies moving towards a more sustainable future.
        </TextComponent>
        <TextComponent mb={space.xSmall}>
          While there is always uncertainty in markets, one thing remains clear:
          there is always a new situation that requires the right investment
          answers. We call it the new now.
        </TextComponent>
        <TextComponent mb={space.xSmall}>
          As investors on behalf of our clients, it is our role to find these
          investment solutions. Ensuring the best possible foundation for our
          clients’ financial future.
        </TextComponent>
        <TextComponent mb={space.medium}>
          That’s why we are ‘Investors for a new now’.
        </TextComponent>
        <TextComponent variant="claim" color="text.primaryLight">
          Investors for a new now
        </TextComponent>
      </Box>
    </ContentSectionComponent>
  );
};
