import { Box } from '@chakra-ui/react';

import { space } from 'theme/constants';

export const LogoComponent = () => {
  return (
    <Box mb={space.small} px={{ base: space.xSmall, lg: space.small }}>
      <a href={'brand-identity#our-brand-experience'}>
        <img
          src={process.env.PUBLIC_URL + '/images/DWSLogoOnly.svg'}
          width={160}
          height={80}
          alt="DWS Brand Portal Logo"
        />
      </a>
    </Box>
  );
};
