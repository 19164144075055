import { Box } from '@chakra-ui/react';

import { ContentSectionComponent } from 'components/ContentSection';
import { SECTIONS } from 'constants/sections';
import { space } from 'theme/constants';

export const Showcase = () => {
  return (
    <ContentSectionComponent
      anchor={SECTIONS.SHOWCASE_THE_POSSIBLE.anchor}
      title={SECTIONS.SHOWCASE_THE_POSSIBLE.title}
    >
      <Box
        mt={`-${space.xLarge}`}
        mx={{ lg: `-${space.medium}`, xl: `-${space.xLarge}` }}
      >
        <img
          src={process.env.PUBLIC_URL + '/images/Showcase.png'}
          alt="Brand usage in different places: magazine, poster, cloth bag, filofax, ring binder"
        />
      </Box>
    </ContentSectionComponent>
  );
};
